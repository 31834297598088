<template>

    <div class="slider-container">
        <Slider class="slider" v-model="minimuDurationThreshold" :min=0 :max=120 :steps=1 />
        <span style="padding-left: 5px;padding-top: 15px; font-size: small;"><i>Threshold Minimum Duration: <span style="color: #B2C149"><b>{{ minimuDurationThreshold }} seconds</b></span></i></span>
    </div>

</template>

<script>
import { ref, watch, onBeforeMount } from 'vue'

import Slider from '@vueform/slider'
import '@vueform/slider/themes/default.css'

export default {
    name: 'ThresholdSlider',

    components: {
        Slider
    },

    setup(props, { emit }) {

        const minimuDurationThreshold = ref(0)

        onBeforeMount(() => {
            emit('update:threshold', minimuDurationThreshold.value)
        })


        // Watcher for minimuDurationThreshold to emit changes
        watch(minimuDurationThreshold, (newThreshold) => {
            console.log('THS W', newThreshold)
            emit('update:threshold', newThreshold)
        })

        return {
            minimuDurationThreshold
        }
    }

}

</script>


<style scoped>

.slider-container {
    width: 100%; /* Make sure it takes full width */
}

.slider {
    margin-top: 50px;
    padding-left: 10px;
    --slider-connect-bg: #B2C149;
    --slider-tooltip-bg: #B2C149;
    --slider-height: 2px;
    --slider-handle-width: 12px;
    --slider-handle-height: 12px;
    --slider-tooltip-line-height: 1.0rem;
    --slider-tooltip-font-size: 0.7rem;
    width: 40%
    
}

</style>