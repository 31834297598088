<template>
    <div v-if="showNotification" class="notification">
        Successfully Registered. You will be redirected to the login page.
    </div>

    <div class="d-flex justify-content-center align-items-center vh-50">
    <div class="card p-4 w-50">
        <h2 class="text-center mb-4">Registration Form</h2>
        <form @submit.prevent="register">
        <div class="mb-3">
            <label for="userName" class="form-label">Name <span class="text-danger">*</span></label>
            <input type="text" class="form-control" id="userName" v-model="formData.userName" required>
        </div>

        <div class="mb-3">
            <label for="userSurname" class="form-label">Surname <span class="text-danger">*</span></label>
            <input type="text" class="form-control" id="userSurname" v-model="formData.userSurname" required>
        </div>

        <div class="mb-3">
            <label for="userCompany" class="form-label">Company <span class="text-danger">*</span></label>
            <input type="text" class="form-control" id="userCompany" v-model="formData.userCompany" required>
        </div>

        <h5 class="mt-5 mb-2 text-secondary">Login Details</h5>
        <hr class="mb-3 left-aligned-hr">

        <div class="mb-3">
            <label for="userEmail" class="form-label">Email address <span class="text-danger">*</span></label>
            <input type="email" class="form-control" id="userEmail" v-model="formData.userEmail" @blur="emailTouched = true" :class="{ 'is-invalid': emailTouched && !isEmailValid }" required>
            <div v-if="emailTouched && !isEmailValid" class="invalid-feedback">
                Please enter a valid email address.
            </div>
        </div>

        <div class="mb-3">
            <label for="userPassword" class="form-label">Password <span class="text-danger">*</span></label>
            <div class="input-group">
                <input :type="passwordFieldType" class="form-control" id="userPassword" v-model="formData.userPassword" @blur="passwordTouched = true" :class="{ 'is-invalid': passwordTouched && !isPasswordValid }" required>
                <button class="btn btn-outline-secondary btn-password-toggle" :class="{ 'btn-password-toggle-red': passwordTouched && !isPasswordValid }" type="button" @click="togglePasswordVisibility">
                    <i :class="passwordIcon"></i>
                </button>
            </div>
            <div v-if="passwordTouched && !isPasswordValid" class="invalid-feedback">
                Password must be at least 8 characters long.
            </div>
            <small v-else class="form-text text-muted">Password must be at least 8 characters long.</small> <!-- Small hint -->
        </div>

        <div v-if="loginError" class="error-message">{{ loginError }}</div>

        <button type="submit" class="btn btn-primary" :disabled="isButtonDisabled">Register</button>
        <div v-if="isLoading" class="popup">
            <div class="loader"></div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { ref, computed } from 'vue'
import { useRouter } from 'vue-router'

import image from "./icons/logo_storalytic.png"

import { useUserStore } from '@/stores/UserStore'

export default {
    name: 'Registration',

    setup() {
        const router = useRouter()
        const userStore = useUserStore()

        const passwordVisible = ref(false)
        const emailTouched = ref(false)
        const passwordTouched = ref(false)
        const loginError = ref(null)

        const showNotification = ref(false)

        const formData = ref({
            userName: '',
            userSurname: '',
            userCompany: '',
            userEmail: '',
            userPassword: ''
        })

        const isLoading = ref(null)
        let loadingInterval = null

        // Computed property for email validation
        const isEmailValid = computed(() => {
            return /\S+@\S+\.\S+/.test(formData.value.userEmail)
        })

        // Computed property for password validation
        const isPasswordValid = computed(() => {
            return formData.value.userPassword.length >= 8
        })

        // Computed property to determine if the button should be disabled
        const isButtonDisabled = computed(() => {
            return !formData.value.userName || !formData.value.userSurname || 
                   !formData.value.userCompany || !isEmailValid.value || 
                   !isPasswordValid.value;
        })

        // Reactive properties for password visibility
        const passwordFieldType = computed(() => passwordVisible.value ? 'text' : 'password')
        const passwordIcon = computed(() => passwordVisible.value ? 'bi bi-eye-fill' : 'bi bi-eye-slash-fill')

        // Method to toggle password visibility
        const togglePasswordVisibility = () => {
            passwordVisible.value = !passwordVisible.value
        }

        const register = async () => {
            try {
                handleLoadingState(true)

                await userStore.registerUser(formData.value)
                console.log("Registration successful")

                handleLoadingState(false)

                await displayNotification()

                // Reset form data after successful registration
                formData.value = {
                    userName: '',
                    userSurname: '',
                    userCompany: '',
                    userEmail: '',
                    userPassword: ''
                }

                router.push('/login')
            } catch (error) {
                handleLoadingState(false)
                console.error("Registration failed: ", error.message)
                loginError.value = "Registration failed: " + error.message
            }
        }


        const handleLoadingState = (value) => {
            if (value) {
                isLoading.value = value 
            } else {
                isLoading.value = value
            }
        }

        const displayNotification = () => {
            return new Promise((resolve) => {
                showNotification.value = true
                setTimeout(() => {
                    showNotification.value = false
                    resolve() // Resolve the promise after the timeout
                }, 3000) // Adjust the timeout duration as needed
            });
        }

        return {
            logo_applicaite: image,
            formData,
            register,
            isEmailValid,
            isPasswordValid,
            passwordFieldType,
            togglePasswordVisibility,
            passwordIcon,
            emailTouched,
            passwordTouched,
            loginError,
            isButtonDisabled,
            isLoading,
            animatedDots,
            handleLoadingState,
            showNotification
        }
    }
}
</script>

<style scoped>

.btn {
  background-color: #4F7EB3;
  border: none;
  padding: 5px 10px; /* Adds padding inside the button */
  white-space: nowrap; /* Prevents text from wrapping inside the button */
}

.btn:hover {
  background-color: #B2C149;
}

.btn:active {
  background-color: #B2C149 !important;
}

.btn-password-toggle {
    background-color: white;
    border: 1px solid;
    border-color: #CED4DA;
}

.btn-password-toggle-red {
    border-color: #C21500; /* Set the border color to red */
}

.card {
  max-width: 600px;
}

.form-control {
  max-width: 500px;
}
.card h2, .card h5 {
  color: #333;
}

.left-aligned-hr {
  border-top: 1px solid #ccc;
  width: 100%;
  margin-left: 0;
}

.invalid-feedback {
    display: block; /* Override Bootstrap's default which hides the message 'Password must be at least 8 characters.' */
}
.form-text.text-muted {
    font-size: 0.8rem; /* Adjust the font size as needed */
}
.popup {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.4); /* Black with opacity */

    display: flex;
    align-items: center; /* Align vertically */
    justify-content: center; /* Align horizontally */
    z-index: 1000; /* Ensure it's on top */
}

.loader {
  width: 80px;
  padding: 8px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: #B2C149;
  --_m: 
    conic-gradient(#0000 10%,#000),
    linear-gradient(#000 0 0) content-box;
  -webkit-mask: var(--_m);
          mask: var(--_m);
  -webkit-mask-composite: source-out;
          mask-composite: subtract;
  animation: spinner 1s infinite linear;
}
@keyframes spinner {to{transform: rotate(1turn)}}
.error-message{
    margin-bottom: 15px;
    color: #C21500;
}

.notification {
  position: fixed;
  top: 5%;
  left: 60%;
  transform: translateX(-50%);
  padding: 10px 20px;
  background-color: #d4edda;
  color: #155724;
  border: 1px solid #c3e6cb;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  text-align: center;
}
</style>