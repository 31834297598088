<template>
    <div>
        <div class="container">
            <div class="chart-wrapper">
                <div class="chart-container">
                    <BarChart :chartData="chartDataCountsInOut" :options="chartOptionsCounts"/>
                    <BarChart :chartData="chartDataAverageInteractionTime" :options="chartOptionsAverageTime" />
                    <div v-if="isLoading" class="loading-overlay">
                        <div class="loader" style="margin-top: 50px;"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, onMounted, onUnmounted, watch, toRefs } from 'vue'

import { detection_zone_colors_by_name_solid } from '@/constants.js'

import { BarChart } from 'vue-chart-3'
import { Chart, BarController, BarElement, CategoryScale, LinearScale, Title, Legend, Tooltip } from 'chart.js'

// Register the components going to use
Chart.register(BarController, BarElement, CategoryScale, LinearScale, Title, Legend, Tooltip)

import {getCountersListByDayByDuration, getcountersListByDayByDateRange, getCountersListByHourByDuration, getcountersListByHourByDateRange } from "@/api/eventsAPI.js"

export default {
    name: 'Chart',

    components: {
        BarChart,
    },

    props: {
        flowId: Number,
        startDate: Object,
        endDate: Object,
        dataGranularity: String,
        duration: Number,
        dateRangeMode: String,
        minimumDurationThreshold: Number
    },

    setup(props, { emit }) {
        const chartData = ref({})
        const chartDataCountsInOut = ref({})
        const chartDataAverageInteractionTime = ref({})

        const isLoading = ref(false)

        let fetchIntervalChartData = null
        const isMounted = ref(true)
        let currentFetchController = null
        let latestRequestId = 0

        const { flowId, duration, dataGranularity, startDate, endDate, dateRangeMode, minimumDurationThreshold } = toRefs(props)

        const today = ref(new Date())
        
        const stopInterval = () => {
            if (fetchIntervalChartData) {
                clearInterval(fetchIntervalChartData)
                fetchIntervalChartData = null
            }
        }

        const startInterval = () => {
            stopInterval()
            fetchIntervalChartData = setInterval(refreshData, 5000)  // Refresh every 5 seconds
        }

        const refreshData = () => {
            if (!isMounted.value) return;

            stopInterval();  // Stop the interval before making a new API call

            if (dateRangeMode.value === 'duration') {
                fetchEventDataByDuration()
            } else if (dateRangeMode.value === 'dateRange') {
                fetchEventDataByDateRange()
            }
        }

        const debounce = (func, wait) => {
            let timeOut
            return (...args) => {
                clearTimeout(timeOut)
                timeOut = setTimeout(() => func.apply(this, args), wait)
            }
        }

        onMounted(() => {
            isMounted.value = true
            
            isLoading.value = true

            refreshData()  // Fetch data when component is mounted
            startInterval() // Set up an interval to keep fetching data
        })

        onUnmounted(() => {
            isMounted.value = false  // Mark component as unmounted

            stopInterval(); // Stop the interval
            if (currentFetchController) {
                currentFetchController.abort() // Abort any ongoing fetch request
            }
        })

        const emitData = () => {
            if (isMounted.value) {
                emit('update-data', chartData.value)  // Emit an event with the chart data
            }
        }

        watch([duration, dateRangeMode], debounce((newValue) => {
            if (newValue) {
                isLoading.value = true
                stopInterval() // Stop the interval before making a new API call
                refreshData()
            }
        }, 300))

        watch(() => props.minimumDurationThreshold, debounce((newValue) => {
            if (newValue || newValue === 0) {
                isLoading.value = true
                stopInterval() // Stop the interval before making a new API call
                refreshData()
            }
        }, 300));

        watch(() => dataGranularity.value, debounce((newValue) => {
            if (newValue) {
                isLoading.value = true
                stopInterval() // Stop the interval before making a new API call
                refreshData()
            }
        }, 300));

        watch(([startDate, endDate]), debounce ((newValues, prevValues) => {
            if (newValues.every(value => value !== null)) { // Both dates are selected
                isLoading.value = true
                stopInterval() // Stop the interval before making a new API call
                refreshData()
            }
        }, 300))

        const fetchEventDataByDuration = async () => {
            if (currentFetchController) {
                currentFetchController.abort(); // Abort the previous request
            }

            const controller = new AbortController()
            currentFetchController = controller // Update the current controller
            const requestId = ++latestRequestId

            if (flowId.value) {
                try {
                    if (controller.signal.aborted || !isMounted.value) {
                        return  // Abort early if the signal is already aborted
                    }
                    
                    let result
                    let counters
                    let counters_combined_ee
                    
                    const now = new Date()
                    const startDate = new Date(now)
                    startDate.setDate(now.getDate() - duration.value + 1)

                    if (dataGranularity.value === 'hourly') {
                        result = await getCountersListByHourByDuration(props.flowId, minimumDurationThreshold.value, startDate, { signal: controller.signal })
                        counters = result.zone_counters_by_hour
                        counters_combined_ee = result.combined_ee_store_counters_by_hour
                    } else {
                        result = await getCountersListByDayByDuration(props.flowId, minimumDurationThreshold.value, startDate, { signal: controller.signal })
                        counters = result.zone_counters_by_day
                        counters_combined_ee= result.combined_ee_store_counters_by_day
                    }
                    if (isMounted.value && requestId === latestRequestId) {  // Check for the latest request
                        createCountsChart(counters, counters_combined_ee)
                        createAverageInteractionChart(counters)
                        isLoading.value = false
                        startInterval() // Restart the interval after the API call is complete
                    }
                } catch (error) {
                    isLoading.value = false
                    if (error.name === 'AbortError') {
                        console.log('Fetch aborted');
                    } else {
                        console.error('Error fetching event data:', error)
                    }
                    startInterval() // Restart the interval even if there is an error
                }
            }
        }

        // New method to fetch data based on the date range
        const fetchEventDataByDateRange = async () => {
            if (currentFetchController) {
                currentFetchController.abort(); // Abort the previous request
            }

            const controller = new AbortController()
            currentFetchController = controller // Update the current controller
            const requestId = ++latestRequestId

            //Check if both dates are selected and flowId is available
            if (startDate.value && endDate.value && flowId.value) {
                try {
                    if (controller.signal.aborted || !isMounted.value) {
                        return  // Abort early if the signal is already aborted
                    }

                    let result
                    let counters
                    let counters_combined_ee

                    if (dataGranularity.value === 'hourly') {
                        result = await getcountersListByHourByDateRange(flowId.value, startDate.value, endDate.value, minimumDurationThreshold.value, { signal: controller.signal })
                        counters = result.zone_counters_by_hour
                        counters_combined_ee = result.combined_ee_store_counters_by_hour
                    } else {
                        result = await getcountersListByDayByDateRange(flowId.value, startDate.value, endDate.value, minimumDurationThreshold.value, { signal: controller.signal })
                        counters = result.zone_counters_by_day
                        counters_combined_ee= result.combined_ee_store_counters_by_day
                    }
                    if (isMounted.value && requestId === latestRequestId) {
                        createCountsChart(counters, counters_combined_ee)
                        createAverageInteractionChart(counters)
                        isLoading.value = false
                        startInterval() // Restart the interval after the API call is complete
                    }
                } catch (error) {
                    isLoading.value = false
                    if (error.name === 'AbortError') {
                        console.log('Fetch aborted');
                    } else {
                        console.error('Error fetching event data:', error)
                    }
                    startInterval() // Restart the interval after the API call is complete
                }
            }
        }

        const createCountsChart = async (counts, counts_combined_ee) => {
            if (!isMounted.value) return

            // Extract dates as labels
            const labels = Object.keys(counts)

            // Extract detection zone names and types
            const detectionZones = new Set()
            const zoneTypes = {}
            labels.forEach(date => {
                Object.keys(counts[date]).forEach(zone => {
                    // Skip combined entrance-exit zones for this part
                    if (counts[date][zone]['zone_type'] !== 'Combined Entrance Exit Zone') {
                        detectionZones.add(zone);
                        zoneTypes[zone] = counts[date][zone]['zone_type'];
                    }
                })
            })

            // Add separate Store IN and Store OUT for Combined Entrance Exit Zones
            if (counts_combined_ee) {
                Object.keys(counts_combined_ee).forEach(date => {
                    Object.keys(counts_combined_ee[date]).forEach(zone => {
                        if (counts_combined_ee[date][zone]['zone_type'] === 'Combined Entrance Exit Zone') {
                            detectionZones.add(`${zone} Store IN`);
                            detectionZones.add(`${zone} Store OUT`);
                            zoneTypes[`${zone} Store IN`] = 'Combined Entrance Exit Zone';
                            zoneTypes[`${zone} Store OUT`] = 'Combined Entrance Exit Zone';
                        }
                    })
                })
            }

            const detectionZoneArray = Array.from(detectionZones).sort()

            // Prepare datasets
            const datasets = detectionZoneArray.map(zone => {
                const zoneType = zoneTypes[zone]
                const data = labels.map(date => {
                    if (zone.endsWith('Store IN')) {
                        const originalZone = zone.replace(' Store IN', '')
                        return counts_combined_ee[date]?.[originalZone]?.zone_in_count || 0
                    } else if (zone.endsWith('Store OUT')) {
                        const originalZone = zone.replace(' Store OUT', '')
                        return counts_combined_ee[date]?.[originalZone]?.zone_out_count || 0
                    } else {
                        return counts[date]?.[zone]?.zone_in_count || 0
                    }
                })
                return {
                    label: zone,
                    data: data,
                    backgroundColor: detection_zone_colors_by_name_solid[zoneType] || '#000000'
                }
            })
            // Chart data for detection zones
            chartDataCountsInOut.value = {
                labels: labels,
                datasets: datasets
            }
        }

        const createAverageInteractionChart = async (averageDuration) => {
            if (!isMounted.value) return

            // Extract dates as labels
            const labels = Object.keys(averageDuration)

            // Extract detection zone names and types
            const detectionZones = new Set()
            const zoneTypes = {}
            labels.forEach(date => {
                Object.keys(averageDuration[date]).forEach(zone => {
                    detectionZones.add(zone)
                    zoneTypes[zone] = averageDuration[date][zone]['zone_type']
                })
            })
            const detectionZoneArray = Array.from(detectionZones).sort()
            
            // Prepare datasets
            const datasets = detectionZoneArray.map(zone => {
                const zoneType = zoneTypes[zone]
                const data = labels.map(date => averageDuration[date][zone]['average_duration'] || 0)
                return {
                    label: zone,
                    data: data,
                    backgroundColor: detection_zone_colors_by_name_solid[zoneType] || '#000000'
                }
            })

            // Chart data for detection zones
            chartDataAverageInteractionTime.value = {
                labels: labels,
                datasets: datasets
            }

            //isLoading.value = false
        }

        const chartOptionsCounts = {
            responsive: true,  // Makes the chart responsive
            maintainAspectRatio: false,  // Control the aspect ratio
            scales: {
                y: {
                    beginAtZero: true  // Start the Y axis at 0
                },
                x: {
                    stacked: false // Group bars in case of multi-class. For stacked bar chart, set it to true.
                }
            },
            plugins: {
                legend: {
                    display: true,
                    position: 'top',  // Position of the legend
                },
                title: {
                    display: true,
                    text: 'Total Zone In (#)',
                },
                tooltip: {
                    enable: true
                },
            }
        }

        const chartOptionsAverageTime = {
            responsive: true,  // Makes the chart responsive
            maintainAspectRatio: false,  // Control the aspect ratio
            scales: {
                y: {
                    beginAtZero: true,  // Start the Y axis at 0
                },
                x: {
                    stacked: false // Group bars in case of multi-class. For stacked bar chart, set it to true.
                }
            },
            plugins: {
                legend: {
                    display: true,
                    position: 'top',  // Position of the legend
                },
                title: {
                    display: true,
                    text: 'Average Time In Zone (sec)',
                },
                tooltip: {
                    enable: true
                },
            }
        }


        return {
            chartData,
            chartDataCountsInOut,
            chartDataAverageInteractionTime,
            chartOptionsCounts,
            chartOptionsAverageTime,
            flowId,
            startDate,
            endDate,
            today,
            dataGranularity,
            duration,
            dateRangeMode,
            isLoading
        }

    }
}
</script>

<style scoped>

.btn {
  background-color: #4F7EB3;
  border: none;
  padding: 5px 10px; /* Adds padding inside the button */
  white-space: nowrap; /* Prevents text from wrapping inside the button */
}

.btn:hover {
  background-color: #B2C149;
}

.button-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 20px;
    margin-bottom: 20px;
    justify-content: flex-start;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.chart-wrapper {
  position: relative;
  width: 100%;
}

.chart-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 10; /* Ensure it is above other content */
}

.loader {
  width: 80px;
  padding: 8px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: #B2C149;
  --_m: 
    conic-gradient(#0000 10%,#000),
    linear-gradient(#000 0 0) content-box;
  -webkit-mask: var(--_m);
          mask: var(--_m);
  -webkit-mask-composite: source-out;
          mask-composite: subtract;
  animation: spinner 1s infinite linear;
}
@keyframes spinner {to{transform: rotate(1turn)}}

</style>