<template>
    <div class="chart-widget">
        <canvas ref="barChartCanvas"></canvas>
    </div>
</template>

<script>
import { ref, watch, onMounted, onBeforeUnmount, nextTick } from 'vue';
import { Chart, BarController, BarElement, CategoryScale, LinearScale, Title, Legend, Tooltip } from 'chart.js';

Chart.register(BarController, BarElement, CategoryScale, LinearScale, Title, Legend, Tooltip);

export default {
    name: 'ChartsDashboard',
    props: {
        chartData: {
            type: Object,
            required: true,
            default: () => ({ labels: [], datasets: [] }),
        },
        chartOptions: {
            type: Object,
            required: true,
        },
    },
    setup(props) {
        const barChartCanvas = ref(null);
        let barChartInstance = null;

        const initializeChart = () => {
            if (barChartCanvas.value) {
                barChartInstance = new Chart(barChartCanvas.value, {
                    type: 'bar',
                    data: props.chartData,
                    options: {
                        ...props.chartOptions,
                        responsive: true,
                        maintainAspectRatio: false,
                    },
                });
            }
        };

        const resizeChart = () => {
            if (barChartInstance && barChartCanvas.value) {
                const parent = barChartCanvas.value.parentNode;
                barChartCanvas.value.width = parent.clientWidth;
                barChartCanvas.value.height = parent.clientHeight;
                barChartInstance.resize();
            }
        };

        const updateChartData = (newData) => {
            if (barChartInstance) {
                let needsUpdate = false;

                // Check if datasets need updating
                barChartInstance.data.datasets.forEach((dataset, index) => {
                    const newDataSet = newData.datasets[index];
                    if (newDataSet && dataset.data.join(',') !== newDataSet.data.join(',')) {
                        dataset.data = [...newDataSet.data];
                        needsUpdate = true;
                    }
                });

                // Check if labels need updating
                if (barChartInstance.data.labels.join(',') !== newData.labels.join(',')) {
                    barChartInstance.data.labels = [...newData.labels];
                    needsUpdate = true;
                }

                // Update the chart without animating or redrawing the bars
                if (needsUpdate) {
                    barChartInstance.update('none');  // Use 'none' to prevent animation and minimize redraw
                }
            }
        }

        // Watch for changes in chartData and update the chart accordingly
        watch(
            () => props.chartData,
            (newData) => {
                updateChartData(newData);
            },
            { deep: true }
        )

        onMounted(() => {
            nextTick(() => {
                initializeChart();
                window.addEventListener('resize', resizeChart);
            });
        });

        onBeforeUnmount(() => {
            if (barChartInstance) {
                barChartInstance.destroy();
                barChartInstance = null;
            }
            window.removeEventListener('resize', resizeChart);
        });

        return {
            barChartCanvas,
            resizeChart
        };
    },
};
</script>

<style scoped>
.chart-widget {
    position: relative;
    width: 100%;
    height: 80%;
}

.chart-widget canvas {
    display: block;
  width: 100%;
  height: 100%;
}
</style>