<template>
    <div>
        <div class="container">
            <div class="container">

                <div class="card">

                    <div class="card-header">
                        <div class="container text-center">
                            <h3 v-if='FlowName' class="card-title">{{ FlowName }}</h3>
                            <h3 v-else class="card-title">Name</h3>
                        </div>
                    </div>

                    <div class="card-body">
                        <div class="col-6 offset-2">
                            
                            <div class="mb-2">
                                <label class="form-label card-text" for="name"><b>Name:</b></label>
                                <input type="text" class="form-control" id="name" v-model="FlowName">
                            </div>

                            <!-- Can be convenient later when multiple inputtypes can be chosen. E.g. a sensorstream -->
                            <!-- <div class="mb-2">
                                <label class="form-label card-text"><b>Choose the Input Type:</b></label>
                                <dropdown-input-type @input_inputtype="on_inputtype_input"></dropdown-input-type>
                            </div> -->

                            <div class="mb-2" v-if="InputTypeId==='Camera Stream'">
                                <camera-stream-settings v-if="InputTypeId==='Camera Stream'" @streamlink="on_streamlink" @capturedframe="on_capturedframe"></camera-stream-settings>
                            </div>

                        </div>

                        <div class="col-8 offset-2">
                            <detection-zone v-if="InputTypeId==='Camera Stream' && StreamLink && capturedFrame" :stream_link="StreamLink" :captured_frame="capturedFrame" @detectionzones="on_detectionzone_logic"></detection-zone>
                        </div>
                    </div>

                    <div class="card-footer">
                        <div v-if="isSaving" style="margin-top: 5px; margin-bottom: 5px; margin-left: 7px;">
                            <div class="saver"></div>
                        </div>
                        <button type="button" class="btn btn-primary" :class="{ 'disabled-link': isSaveButtonDisabled }" style="margin-right: 5px" @click="createFlow"><span class="btn-label"><i class="bi bi-save"></i></span></button>
                        <button type="button" class="btn btn-primary" style="margin-right: 5px" @click="cancelNewFlow"><span class="btn-label"><i class="bi bi-x-square"></i></span></button>
                    </div>

                </div>

            </div>
        </div>
    </div>
</template>

<script>
import { ref, computed } from 'vue'
import { useRouter } from 'vue-router'

import { useToast } from 'vue-toastification'

import { useFlowStore } from '@/stores/FlowStore'
import { useCameraStreamStore } from '@/stores/CameraStreamStore'
import { useDetectionZoneStore } from '@/stores/DetectionZone'

import DropdownInputType from './DropdownInputType.vue'

import CameraStreamSettings from './CameraStreamSettings.vue'

import DetectionZone from './DetectionZone.vue'

export default {
    components: {
        DropdownInputType,
        CameraStreamSettings,
        DetectionZone,
    },

    setup(){
        const toast = useToast()

        const flowStore = useFlowStore()

        const camerastreams =   useCameraStreamStore()
        const detectionzones = useDetectionZoneStore()

        const router = useRouter()

        //Set variables
        const FlowName = ref('')
        const FlowId = ref(0)
        const InputTypeId = ref('Camera Stream') //Now fixed value. Potantial in the furture add other input streams (e.g. sensordata)
        const UserId = ref('')

        const StreamLink = ref('')
        const capturedFrame = ref(null)
        const DetectionZone = ref({})

        const isSaving = ref(null)


        //Update values when values in child are changed
        const on_inputtype_input = (value) => {
            InputTypeId.value = value
        }

        //Update values when values in child are changed
        const on_streamlink = (value) => {
            StreamLink.value = value
        }

        //Update values when values in child are changed
        const on_capturedframe = (value) => {
            capturedFrame.value = value
        }

        //Set values to push to child
        const on_detectionzone_logic = (value) => {
            DetectionZone.value = value
        }

        const resetValues = () => {
            FlowId.value = 0
            FlowName.value = ''
            InputTypeId.value = ''
            StreamLink.value = ''
            DetectionZone.value = []
        }
        
        const isSaveButtonDisabled = computed (() => {
            return (
                !FlowName.value ||
                !InputTypeId.value === 0 ||
                !StreamLink.value
            )
        })

        const createFlow = async () => {
            try{    
                isSaving.value= true
                if (InputTypeId.value === 'Camera Stream' && StreamLink.value != '') {
                    try {
                        FlowId.value = await flowStore.createFlow(FlowName.value, InputTypeId.value)

                        if (InputTypeId.value === 'Camera Stream') {
                            try {
                                await camerastreams.addInputCameraStreamToFlow(FlowId.value, StreamLink.value)
                            } catch (error) {
                                console.error("Error updating CameraStreams table:", error)
                                toast.error("An unexpected error occurred while adding the camerastream to the database. Please try again or contact support if the problem persists.:", error)
                            }  
                        }

                    } catch (error) {
                        console.error("Error updating Flow table:", error)
                        toast.error("An unexpected error occurred while adding the flow to the database. Please try again or contact support if the problem persists.:", error)
                    }  
                    
                    if (Object.keys(DetectionZone.value).length > 0) {
                        try {
                            await detectionzones.addDetectionZoneToFlow(FlowId.value, DetectionZone.value)
                        } catch (error) {
                            console.error("Error updating DetectionZone table:", error)
                            toast.error("An unexpected error occurred while adding the detection zone(s) to the database. Please try again or contact support if the problem persists.:", error)
                        }  
                    }
                    
                    resetValues()
                    // Clear or update local storage to ensure fresh data
                    localStorage.removeItem('listOfFlows')
                    localStorage.removeItem('FixedWidgetsData')
                    localStorage.removeItem('detectionZoneData')
                    
                    await flowStore.fetchFlows()
                    toast.success('Camera view added successfully')
                    isSaving.value = false
                    router.push('/camera_overview')
                } else if (InputTypeId.value === 'Camera Stream' && StreamLink === '') {
                    console.error("An unexpected error occurred while passing the camera stream link to the system.:", error)
                    toast.error("An unexpected error occurred while passing the camera stream link to the system. Please try again or contact support if the problem persists.:", error)
                    isSaving.value = false
                } else {
                    console.error("An unexpected error occurred while passing data.:", error)
                    toast.error("An unexpected error occurred while passing data. Please try again or contact support if the problem persists.:", error)
                    isSaving.value = false
                }
            } catch (error) {
                console.error("An unexpected error occurred while writing data to the database.:", error)
                toast.error("An unexpected error occurred while writing data to the database. Please try again or contact support if the problem persists.:", error)
                isSaving.value = false
            }
        }

        const cancelNewFlow = () => {
            if (confirm("Do you want to cancel and go back to the home page?")) {
                isSaving.value = true
                resetValues()
                setTimeout(() => {
                    isSaving.value = false
                    router.push('/camera_overview')
                }, 100) // Add a slight delay before navigation
            }
        }

        const onCancel = () => {
                console.log('User cancelled the loader.')
        }

        return {
            FlowName,
            InputTypeId,
            UserId,
            StreamLink,
            flowStore,
            createFlow,
            on_inputtype_input,
            on_streamlink,
            on_capturedframe,
            capturedFrame,
            on_detectionzone_logic,
            cancelNewFlow,
            isSaveButtonDisabled,
            isSaving,
            onCancel
        }
    }
}
</script>

<style scoped>

.card-title {
  color: #4F7EB3  !important;

}.btn {
  background-color: #4F7EB3;
  border: none;
}

.btn:hover {
  background-color: #B2C149;
}

.card-text {
  padding-top: 15px;
  color: #425461
}

.disabled-link {
  opacity: 0.5;
  pointer-events: none;
}

.popup {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.4); /* Black with opacity */

    display: flex;
    align-items: center; /* Align vertically */
    justify-content: center; /* Align horizontally */
    z-index: 1000; /* Ensure it's on top */
}

.saver {
  width: 25px;
  padding: 2px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: #B2C149;
  --_m: 
    conic-gradient(#0000 10%,#000),
    linear-gradient(#000 0 0) content-box;
  -webkit-mask: var(--_m);
          mask: var(--_m);
  -webkit-mask-composite: source-out;
          mask-composite: subtract;
  animation: spinner 1s infinite linear;
}
@keyframes spinner {to{transform: rotate(1turn)}}

</style>