<template>
  <div>

    <!-- <h2 class="text-center" style="margin-bottom: 40px;">Dashboard Camera Overview</h2> -->
    <div class="container">
      <div class="container">
        <div class="row row-cols-auto" style="gap: 25px; justify-content: center;">
          
          <div v-if="isLoading">
            <div class="loader" style="margin-top: 400px;"></div>
          </div>
          
          <!-- Render flows if flowstore is available and loading is complete -->
          <template v-if="!isLoading">
          
            <!-- Render flows if available -->
            <div class="col" v-for="flow in flowStore.flows" v-bind:key="flow.FlowId">

              <div class="card">

                <div class="card-body" >
                  <div class="d-flex justify-content-between align-items-center">
                    <h5 class="card-title">{{ flow.FlowName }}</h5>
                    <!-- <div class="actions">
                      <i class="bi bi-arrow-clockwise refresh-icon" :class="{ 'disabled': !socketConnected }" @click="refreshSnapshot(flow.FlowId)"></i>
                    </div> -->
                  </div>
                  
                  <!-- Can be convenient later when multiple inputtypes can be chosen. E.g. a sensorstream -->
                  <!-- <p class="card-text"><b class="partitle">Input: </b> {{ flow.InputTypeId }}</p> -->

                  <div class="d-flex flex-wrap" style="margin-bottom: 10px;">

                    <div v-if="flowStatusDictionary[flow.FlowId]" style="margin-right: 15px;">
                      <h5 class="card-text" style="font-size: small; color: #425461">Detections on/off: <span class="card-text status-indicator status-running"></span></h5>
                    </div>

                    <div v-if="!flowStatusDictionary[flow.FlowId]" style="margin-right: 15px;">
                      <h5 class="card-text" style="font-size: small; color: #425461">Detections on/off: <span class="card-text status-indicator status-stopped"></span></h5>
                    </div>

                    <div v-if="!flowStatusDictionary[flow.FlowId]" style="margin-right: 15px;">
                      <h5 class="card-text" style="font-size: small; color: #b4b4b4">Status Detection Stream: <span class="card-text status-indicator status-grayed-out"></span></h5>
                    </div>

                    <div v-else>
                      <div v-if="cameraHeartBeat[flow.FlowId]" style="margin-right: 15px;">
                        <h5 class="card-text" style="font-size: small; color: #425461">Status Detection Stream: <span class="card-text status-indicator status-running"></span></h5>
                      </div>

                      <div v-if="!cameraHeartBeat[flow.FlowId]" style="margin-right: 15px;">
                        <h5 class="card-text" style="font-size: small; color: #425461">Status Detection Stream: <span class="card-text status-indicator status-stopped"></span></h5>
                      </div>
                    </div>

                  </div>

                  <!-- <div class="image-container">
                    <img v-if="snapshots[flow.FlowId]" :src="snapshots[flow.FlowId]" alt="Camera Snapshot" class="snapshot-image" loading="lazy"/>
                    
                    <div v-else-if="snapshotErrors[flow.FlowId]" class="error-text">
                      Failed to retrieve snapshot. Please check the camera connection.
                    </div>
                    
                    <div v-else class="placeholder-animation">
                      <div class="loading-placeholder"></div>
                    </div>
                  </div> -->
                  
                  <div class="cards-container">
                    <div class="card counter-card" :class="{ 'greyed-out': !flowStatusDictionary[flow.FlowId] }">
                      <div class="card-header counter-card-header">
                        <h6 class="card-title counter-card-title" style="font-size: small; text-align: left;">Current in</h6>
                      </div>
                      <div class="card-body count">
                        <div class="zone-table">
                          <div v-for="(count, zoneName) in flowZoneCounters[flow.FlowId]" :key="zoneName" class="zone-row">
                            <div class="zone-name" :style="{ color: getColorForZone(zoneName, flow.FlowId) }">{{ zoneName }}</div>
                            <div class="zone-count" :style="{ color: getColorForZone(zoneName, flow.FlowId) }"><b>{{ flowStatusDictionary[flow.FlowId] ? count : 'N/A' }}</b></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  

                </div>

                <div class="card-footer">
                  <router-link :to="`flow/${flow.FlowId}`" type="button" class="btn btn-primary" style="margin-right: 5px"><span class="btn-label"><i class="bi bi-display"></i></span></router-link>
                  <router-link v-if="!isSmallScreen && userStore.user.userRole !== 'User'" :to="`flow_settings/${flow.FlowId}`" type="button" class="btn btn-primary"><span class="btn-label"><i class="bi bi-gear"></i></span></router-link>
                </div>

              </div>

            </div>
              
            <!-- Display dummy card if no flows are available as an Admin and Key User-->
            <div v-if="!flowStore.flows.length && !isLoading && userStore.user.UserRole !== 'User'" class="col">
              <div class="card dummy-card">
                <div class="card-body text-center">
                  <h4 class="card-title">Welcome</h4>
                  <p class="card-text"><b>You haven't connected any streams yet</b></p>
                  <div v-if="!isSmallScreen">
                    <router-link to="/create_flow" class="btn btn-primary mt-3"><span class="btn-label"><i class="bi bi-plus-lg"></i></span> Create Your First Flow</router-link>
                  </div>
                  <div v-if="isSmallScreen">
                    <p> Please use a larger screen size (laptop/desktop) to add extra streams</p>
                  </div>
                </div>
                <div class="card-footer">
                  <span class="btn-label" style="margin-right: 5px"><i class="bi bi-display"></i></span>
                  <span class="btn-label"><i class="bi bi-gear"></i></span>
                </div>
              </div>
            </div>

            <!-- Display dummy card if no flows are available as a User-->
            <div v-if="!flowStore.flows.length && !isLoading && userStore.user.UserRole === 'User'" class="col">
              <div class="card dummy-card">
                <div class="card-body text-center">
                  <h4 class="card-title">Welcome</h4>
                  <p class="card-text">No camera streams are connected yet.<br>
                  Contact a Key User or an Admin to add your first stream.</p>
                </div>
                <div class="card-footer">
                  <span class="btn-label" style="margin-right: 5px"><i class="bi bi-display"></i></span>
                  <span class="btn-label"><i class="bi bi-gear"></i></span>
                </div>
              </div>
            </div>

          </template>

        </div>
      </div>
    </div>

  </div>
</template>

<script>
import axios from 'axios'

import { onBeforeMount, onBeforeUnmount, computed, ref } from 'vue'
import { RouterLink } from 'vue-router'

import { useToast } from 'vue-toastification'

import { detection_zone_colors_by_name_solid } from '@/constants.js'
import { useFlowStore } from '@/stores/FlowStore'
import { useCameraStreamStore } from '@/stores/CameraStreamStore'
import { useUserStore } from '@/stores/UserStore'
import { useCompanyStore } from '@/stores/CompanyStore'

import io from 'socket.io-client'

export default {
  components: {
    RouterLink,
  },


  setup() {
    const socket = ref(null)
    const isConnectingSocket = ref(false)
    const socketConnected = ref(true)

    const toast = useToast()
    
    const flowStore = useFlowStore()
    const CameraStreamStore = useCameraStreamStore()
    const userStore = useUserStore()
    const companyStore = useCompanyStore()

    const StreamLink = ref('')
    const snapshots = ref({})
    const snapshotErrors = ref({})

    const flowStatusDictionary = ref({})

    const cameraHeartBeat = ref({})
    const heartbeatTimeouts = ref({})
    
    const detectionZoneNames = ref({})
    const flowZoneCounters = ref({})
    const streamToFlowMap = ref({})

    const isSmallScreen = ref(window.innerWidth <= 768)

    const isLoading = ref(false)
    const isLoadingSnap = ref({})

    const socketioURL = computed(() => {
        return {
            URLPublic: companyStore.company.SocketioURLPublic
        }
    })

    const detectNetworkContext = async (publicURL) => {
        try {
            const controller = new AbortController()
            const signal = controller.signal
            const timeout = 5000 // 5 seconds timeout for checking public IP

            console.log('Trying public URL:', publicURL)
            const fetchPromise = fetch(publicURL, { signal })
            setTimeout(() => controller.abort(), timeout)

            const response = await fetchPromise
            if (response.ok) {
                console.log('Public URL is accessible')
                return publicURL
            }
        } catch (error) {
            console.error('Public Socket URL is not accessible')
            toast.error('Local device is not accessible.')
            throw new Error('Public Socket URL is not accessible')
        }
        throw new Error('Public Socket URL is not accessible')
    }

    const establishSocketConnection = async (publicURL) => {
        if (socket.value !== null) {
            console.log("Returning existing socket")
            return socket.value
        }

        if (isConnectingSocket.value) {
            console.log('Already connecting, waiting...')
            while (isConnectingSocket.value) {
                await new Promise(r => setTimeout(r, 500))
            }
            return socket.value
        }

        console.log("Connecting socket ...")
        isConnectingSocket.value = true

        try {
            const url = await detectNetworkContext(publicURL)
            console.log('Network context detected, URL:', url)

            socket.value = io(url, {
                transports: ['websocket'],
                reconnection: true,
                reconnectionAttempts: 5,
                reconnectionDelay: 1000,
                debug: true,
                perMessageDeflate: false,
                timeout: 120000,
                maxPayload: 50 * 1024 * 1024,
            })

            setupSocketEvents(socket.value)

            return socket.value

        } catch (error) {
            console.error('Error establishing socket connection:', error)
            throw error
        } finally {
            console.log('Resetting serverConnection flag')
            isConnectingSocket.value = false
        }
    }

    const setupSocketEvents = (socketInstance) => {
      socketInstance.on('heartbeat', (data) => {
          const { streamId, heartbeat } = data

          // Ensure we are working with the raw data from the proxy
          const cameraStreams = CameraStreamStore.inputcamerastreams.slice()

          // Perform explicit type conversion for comparison
          const cameraStream = cameraStreams.find(item => item.CameraStreamId === Number(streamId))
          
          if (cameraStream) {
            const flowId = cameraStream.FlowId
            
            if (heartbeat && flowId) {
              
              cameraHeartBeat.value[flowId] = true
              clearTimeout(heartbeatTimeouts.value[flowId])
              heartbeatTimeouts.value[flowId] = setTimeout(() => {
                cameraHeartBeat.value[flowId] = false
              }, 5000)
            }
          }
        })

        socketInstance.on('zone_counter', (data) => { 
          const streamId = data.stream_id
          const flowId = streamToFlowMap.value[streamId]

          if (flowId) {
              if (!flowZoneCounters.value[flowId]) {
                  // Ensure a default structure if the flowId entry is missing
                  flowZoneCounters.value[flowId] = {}
              }

              // Update with data or leave defaults as 0
              flowZoneCounters.value[flowId] = {
                  ...flowZoneCounters.value[flowId], // Keep existing structure
                  ...data.zone_counter // Overwrite with new data
              }
          }
        })

        socketInstance.on('disconnection_message', (data) => {
                console.log('message on disconnection: ', data)
            })

        socketInstance.on('error', (error) => {
          console.error('Error received from server:', error)     
          toast.error(`Error: ${error.message}`)
        })
      }

    
    const initializeSocketConnection = async () => {
        if (socketioURL.value.URLPublic) {
          try {
            socket.value = await establishSocketConnection(socketioURL.value.URLPublic)
            console.log('Socket connection Home established')
          } catch (error) {
            console.error('Failed to establish Home socket connection:', error)
            socketConnected.value = false
          }
        } else {
          console.error('socketioURL is not defined')
          socketConnected.value = false
        }
    }

    const handleResize = () => {
      isSmallScreen.value = window.innerWidth <= 768
    }


    onBeforeMount(async () => {
        isLoading.value = true
        // console.log('Starting data fetch...')
        
        try {
          await userStore.fetchUserDetails()
          // console.log('User details fetched')
          await flowStore.fetchFlows()
          // console.log('Flows fetched')
          await CameraStreamStore.fetchInputCameraStreams()
          // console.log('Input camera streams fetched')
          await fetchAllFlowDetails()
          // console.log('All flow details fetched')
          
          await initializeSocketConnection()

          initializeFlowData()

          window.addEventListener('resize', handleResize)
          handleResize() // Set initial state based on current window size
        
        } catch (error) {
          console.error('Error during onBeforeMount:', error)
        
        } finally {
          isLoading.value = false
        }
    })

    const fetchAllFlowDetails = async () => {
        const fetchFlowDetailsPromises = flowStore.flows.map(flow => flowStore.fetchCurrentFlow(flow.FlowId))
        const flowDetailsArray = await Promise.all(fetchFlowDetailsPromises)
        flowDetailsArray.forEach((flowDetails, index) => {
          const flowId = flowStore.flows[index].FlowId
          if (flowDetails && flowDetails.DetectionZone) {
            detectionZoneNames.value[flowId] = flowDetails.DetectionZone
            flowZoneCounters.value[flowId] = Object.keys(flowDetails.DetectionZone).reduce((acc, zone) => {
              acc[zone] = 0 // Initialize with 0 or any default value
              return acc
            }, {})
          } else {
            console.warn(`Flow ${flowId} does not have DetectionZone data`)
            detectionZoneNames.value[flowId] = {}
            flowZoneCounters.value[flowId] = {}
          }
        })
    }

    const initializeFlowData = () => {
        flowStatusDictionary.value = CameraStreamStore.inputcamerastreams.reduce((acc, item) => {
          acc[item.FlowId] = item.StreamStatus
          return acc
        }, {})

        StreamLink.value = CameraStreamStore.inputcamerastreams.reduce((acc, item) => {
          acc[item.FlowId] = item.CameraStreamLink
          return acc
        }, {})

        cameraHeartBeat.value = CameraStreamStore.inputcamerastreams.reduce((acc, item) => {
          acc[item.FlowId] = false // Initialize as false
          return acc
        }, {})

        streamToFlowMap.value = CameraStreamStore.inputcamerastreams.reduce((acc, item) => {
          acc[item.CameraStreamId] = item.FlowId
          return acc
        }, {})

        // fetchSnapshots()
    }


    const fetchSnapshots = () => {
      flowStore.flows.forEach((flow) => {
        const cameraStreamLink = StreamLink.value[flow.FlowId]
        if (cameraStreamLink) {
          isLoadingSnap.value[flow.FlowId] = true
          getSnapshot(flow.FlowId, cameraStreamLink)
        }
      })
    }
  

    const getSnapshot = async (flowId, cameraStreamLink) => {
        try {
          const response = await axios.get(`${URLPublic}/snapshot`, {
            params: { flowId, cameraStreamLink },
            responseType: 'blob'
          })
          const imageUrl = URL.createObjectURL(response.data)
          snapshots.value[flowId] = imageUrl
          snapshotErrors.value[flowId] = false
        } catch (error) {
          console.error('Snapshot retrieval failed:', error)
          toast.error(`Failed to retrieve snapshot for flow ${flowId}`)
          snapshotErrors.value[flowId] = true
        } finally {
          isLoadingSnap.value[flowId] = false
        }
    }

    const refreshSnapshot = (flowId) => {
        isLoadingSnap.value[flowId] = true
        const cameraStreamLink = StreamLink.value[flowId]
        if (cameraStreamLink) {
          getSnapshot(flowId, cameraStreamLink)
        } else {
          console.warn(`CameraStreamLink for FlowId ${flowId} is undefined`)
        }
    }

    const getColorForZone = (zoneName, flowId) => {
        const zoneType = detectionZoneNames.value[flowId]?.[zoneName]?.type
        return detection_zone_colors_by_name_solid[zoneType] || 'black'
    }

    const onCancel = () => {
        console.log('User cancelled the loader.')
    }

    onBeforeUnmount(() => {
        if (socket.value) {
          socket.value.off('zone_counter')
          socket.value.off('heartbeat')
          socket.value.off('error')
          socket.value.off('connect')
          socket.value.disconnect()
        }
        Object.values(heartbeatTimeouts.value).forEach(clearTimeout)
        // Remove the resize event listener when component is unmounted
        window.removeEventListener('resize', handleResize)
    })

    return { 
        socketConnected,
        getSnapshot,
        snapshotErrors,
        refreshSnapshot,
        flowStore,
        userStore,
        snapshots,
        flowStatusDictionary,
        cameraHeartBeat,
        flowZoneCounters,
        detectionZoneColorsSolid: detection_zone_colors_by_name_solid,
        getColorForZone,
        isLoading,
        isLoadingSnap,
        isSmallScreen,
        onCancel
    }
  }
}
</script>

<style scoped>

.partitle {
  color: #4F7EB3;
}

.card-title {
  color: #4F7EB3;
}

.card {
  width: 27rem;
  margin-bottom: 20px;
}

.image-container {
  min-height: 300px; /* Set a minimum height for consistency */
  max-height: 480px; /* Set a maximum height for consistency */
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
}

.snapshot-image {
  max-width: 100%;
  height: auto;
}

.actions .refresh-icon {
  font-size: 1.5em;
  cursor: pointer;
  color: #9FC8E3;
  transition: color 0.3s ease;
}

.actions .refresh-icon:hover {
  color: #B2C149;
}

.actions .refresh-icon.disabled {
  color: #999999;
  cursor: default;
}

.btn {
  background-color: #4F7EB3;
  border: none;
}

.btn:hover {
  background-color: #B2C149;
}

.disabled-link {
  opacity: 0.5;
  pointer-events: none;
}

.dummy-card {
  border-color: #ddd; /* Light gray border */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional: Add shadow for raised card effect */
  color: #666; /* Gray text color */
}

.btn-secondary {
  background-color: #bbb; /* Gray button */
  border-color: #aaa; /* Button border color */
}

.status-indicator {
    display: inline-block;
    width: 20px;
    height: 10px;
    border-radius: 2px;
}

.status-grayed-out {
    background-color: #b4b4b4;
}

.status-running {
    background-color: #6C9046;
}

.status-stopped {
    background-color: #C21500;
}

.cards-container {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    flex: 1;
    box-sizing: border-box; /* Ensure padding/margin do not affect the size */
}

.counter-card {
    flex: 1 1 100%; /* Fixed width for each counter card */
    box-sizing: border-box;
    min-width: 150px;
}

.counter-card.greyed-out {
    background-color: #f0f0f0; /* Light grey background */
    color: #a0a0a0; /* Grey text color */
}

.counter-card-header {
    padding: 10px;
    text-align: left;
}

.counter-card-title {
    margin: 0; /* Remove default margin */
    font-size: small;
    color: #4F7EB3 !important
}

.count {
    display: block;
    padding: 10px;
}

.zone-table {
  display: flex;
  flex-direction: column;
}

.zone-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}

.zone-name {
  flex: 1;
  font-size: small;
}

.zone-count {
  flex: 0 0 50px; /* Fixed width to align counts */
  text-align: right;
  font-size: small;
}

.error-text {
  color: red;
  font-size: small;
  text-align: center;
  margin-top: 10px;
}

@media (max-width: 768px) {
    .cards-container {
        gap: 5px; /* Reduce gap on smaller screens */
        justify-content: flex-start; /* Align items to start on smaller screens */
    }

    .counter-card {
        flex: 0 0 calc(100% - 10px); /* Ensure cards fit within the container on smaller screens */
        margin-bottom: 10px; /* Add spacing between cards */
        min-width: 0; /* Allow cards to shrink to fit smaller screens */
    }
}


.loader {
  width: 80px;
  padding: 8px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: #B2C149;
  --_m: 
    conic-gradient(#0000 10%,#000),
    linear-gradient(#000 0 0) content-box;
  -webkit-mask: var(--_m);
          mask: var(--_m);
  -webkit-mask-composite: source-out;
          mask-composite: subtract;
  animation: spinner 1s infinite linear;
}
@keyframes spinner {to{transform: rotate(1turn)}}

.placeholder-animation {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-placeholder {
  width: 100%;
  height: 100%;
  background: linear-gradient(to left, transparent 0%, #E0E0E0 50%, transparent 100%);
  background-size: 200% 100%;
  animation: loadingplaceholder 1.5s ease-in-out infinite;
}

@keyframes loadingplaceholder {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}


</style>