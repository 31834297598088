<template>
    
    <select class="form-select" id="toggleInputType" v-model="input_type" @change="$emit('input_inputtype', input_type)">
        <option v-for="inputtype in inputtypes.inputtypes" :key="inputtype.InputTypeId">{{ inputtype.InputTypeName }}</option>
    </select>
    
</template>

<script>
import { onBeforeMount } from 'vue'
import { useInputTypeStore } from '@/stores/InputTypeStore'

export default{
    name: 'DropdownInputType',
    setup () {
        const inputtypes = useInputTypeStore()

        onBeforeMount(() => {
            inputtypes.fetchInputTypes()
        })

        const input_type = ''
        
        return {
            input_type,
            inputtypes
        }
    }
}

</script>

<style scoped>

</style>