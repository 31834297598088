<template>

  <!-- Sidebar (formerly NavSidebar) -->
  <nav-sidebar
    :userId="userStore.user.UserId"
    :userName="userStore.user.UserName"
    :userSurname="userStore.user.UserSurname"
    :userRole="userStore.user.UserRole"
    :companyId="companyStore.company.CompanyId"
    :companyName="companyStore.company.CompanyName"
    :subscriptionType="companyStore.company.SubscriptionType">
  </nav-sidebar>

  <!-- Page content -->
  <div id="app" class="content">
    <RouterView :key="$route.params.id"/>
  </div>

</template>

<script>
import { RouterView } from 'vue-router'

import { useUserStore } from '@/stores/UserStore'
import { useCompanyStore } from '@/stores/CompanyStore'
import NavSidebar from './components/NavSidebar.vue'

export default {
  components: {
    NavSidebar,
    RouterView,
  },
  
  setup() {
    const userStore = useUserStore()
    const companyStore = useCompanyStore()

    return {
      userStore,
      companyStore,
    }
  }
}
</script>

<style scoped>
/* Page content adjustments */
.content {
  margin-left: 250px; /* Adjust based on sidebar width */
  padding: 20px;
  width: calc(100% - 250px);
  margin-top: 50px;
}

@media (max-width: 768px) {
  .content {
    margin-left: 0px;
    width: 100%;
  }
}
</style>