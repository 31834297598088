<template>

<div class="value-display">
        <span v-if="zoneType === 'Combined Entrance Exit Zone'">
            <!-- Combined Entrance Exit Zone -->
            <span v-if="displayText !== 'Average Duration in'">
                <span style="font-size: small; color: gray;">In Store: </span>
                <span :style="{ color: getColorForZone(zoneType), fontSize: 'large' }"><b>{{ formattedInValue }}</b></span>
                <br>
                <span style="font-size: small; color: gray;">Out Store: </span>
                <span :style="{ color: getColorForZone(zoneType), fontSize: 'large' }"><b>{{ formattedOutValue }}</b></span>
            </span>
            <span v-else>
                <span :style="{ color: getColorForZone(zoneType), fontSize: 'xx-large' }"><b>N/A</b></span>
            </span>
        </span>
        <span v-else>
            <!-- Other Zone Types -->
            <span :style="{ color: getColorForZone(zoneType), fontSize: 'xx-large' }"><b>{{ formattedValue }}</b></span>
        </span>
        <span style="font-size: xx-small;">{{ durationText }}</span>
        <span style="font-size: xx-small;">{{ displayText }} <span :style="{ color: getColorForZone(zoneType) }">{{ zone }}</span></span>
    </div>

</template>
  
  <script>
    import { detection_zone_colors_by_name_solid } from '@/constants.js'

    export default {
        name: 'ValueDisplay',
        props: {
            zone: {
                type: String,
                required: true
            },
            value: {
                type: Number,
                required: false
            },
            inValue: {
                type: Number,
                required: false
            },
            outValue: {
                type: Number,
                required: false
            },
            zoneType: {
                type: String,
                required: true
            },
            type: {
                type: String,
                required: true
            },
            durationText: {
                type: String,
                required: true
            },
        },
        computed: {
            displayText() {
            return this.type === 'zone_in' ? 'Total in' : 'Average Duration in';
            },
            getColorForZone() {
            return (zoneType) => detection_zone_colors_by_name_solid[zoneType] || 'black';
            },
            formattedValue() {
                return this.type === 'zone_in' ? this.value : this.formattedAverageDurationTime(this.value);
            },
            formattedInValue() {
                return this.inValue || 0;
            },
            formattedOutValue() {
                return this.outValue || 0;
            }
        },
        setup() {
            const formattedAverageDurationTime = (seconds) => {
                if (seconds < 60) {
                    return `${seconds.toFixed(0)} sec`
                } else if (seconds < 3600) {
                    const minutes = Math.floor(seconds / 60)
                    const remainingSeconds = seconds % 60
                    return `${minutes} min ${remainingSeconds.toFixed(0)} sec`
                } else {
                    const hours = Math.floor(seconds / 3600)
                    const remainingMinutes = Math.floor((seconds % 3600) / 60)
                    const remainingSeconds = seconds % 60
                    return `${hours} h ${remainingMinutes} min ${remainingSeconds.toFixed(0)} sec`
                }
            }

            return {
                formattedAverageDurationTime
            }
        }
    }
  </script>
  

<style scoped>

.value-display {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5px;
}

</style>
