<template>
    <div class="live-stream-container">
        
        <div class="stream-container">

            <div class="stream-item">

                <div class="frame-container">
                    <div class="frame-content">
                        <div v-if="!capturedFrame || isLoadingStream">
                            <div class="loader"></div>
                        </div>
                        <img v-if="capturedFrame" :src="capturedFrame" alt="Live Stream" ref="imageRef" @load="onInitialImageLoad"/>
                        <!-- Canvas that renders the polygons n top of the image -->
                        <canvas id="zoneCanvas" style="position: absolute; top: 0; left: 0; pointer-events: none;"></canvas>
                    </div>
                </div>

            </div>
        
        </div>
    </div>

</template>

<script>
import { ref, onMounted, onBeforeUnmount, watch } from 'vue'

import { useToast } from 'vue-toastification'

import { useRoute } from 'vue-router'

import { detection_zone_colors_by_typeid } from '@/constants.js'

import { useCompanyStore } from '@/stores/CompanyStore'
import { useDetectionZoneStore } from '@/stores/DetectionZone'

import axios from 'axios'

export default {
    name: 'LiveStream',

    components: {
      
    },

    emits: ["streamstatusChange"],

    props: {
        streamLink: String,
        flowId: Number,
        streamId: Number,
        streamStatus: Boolean,
        socket: Object
    },

    setup(props, { emit }) {
        const socket = ref(null)

        const toast = useToast()

        const companyStore = useCompanyStore()
        const DetectionZoneStore = useDetectionZoneStore()

        const route = useRoute()

        const capturedFrame = ref(null)
        const imageRef = ref(null)

        const entranceCam = ref(false)
        const streamIsRunning = ref(false)

        const detectionZones = ref([])

        const isLoadingStream = ref(true)

        let isComponentAlive = true
        let hasJustMounted = true
        let eventSource = null

        // Create the CancelToken instance
        const source = axios.CancelToken.source()

        const startShowLocalStream = () => {
            if (props.streamLink && props.streamStatus !== null) {
                console.log('Starting local stream:', props.streamLink, props.streamStatus, props.streamId)
                socket.value.emit('start_show_live_stream_local', props.streamLink, props.streamStatus, props.streamId)
                streamIsRunning.value = true
                isLoadingStream.value = true
                setupEventSource()       
            }
        }

        const stopShowLocalStream = () => {
            if (streamIsRunning.value && props.streamLink) {
                console.log('Stopping local stream:', props.streamLink, props.streamId)
                socket.value.emit('stop_show_live_stream_local', props.streamLink, props.streamId)
                streamIsRunning.value = false
                isLoadingStream.value = true
                closeEventSource()
            }
        }

        const closeEventSource = () => {
            if (eventSource) {
                eventSource.close()
                eventSource = null
            }
        }

        const adjustCanvasSize = () => {
            const img = imageRef.value
            if (img) {
                const canvas = document.getElementById('zoneCanvas')
                const widthChanged = canvas.width !== img.clientWidth;
                const heightChanged = canvas.height !== img.clientHeight;
                if (widthChanged || heightChanged) {
                    canvas.width = img.clientWidth;
                    canvas.height = img.clientHeight;
                    console.log('Canvas size adjusted:', canvas.width, canvas.height);
                }
            }
        }

        const drawAllZones = async () => {
            if (detectionZones.value) {
                if (!isComponentAlive) return
                const canvas = document.getElementById('zoneCanvas')
                if (!canvas) return
                const ctx = canvas.getContext('2d')
                if (!ctx) return
                ctx.clearRect(0, 0, canvas.width, canvas.height)
                
                detectionZones.value.forEach(zone => {
                    const points = JSON.parse(zone.DetectionZonePointArray)
                    drawPoly(points, detection_zone_colors_by_typeid[zone.DetectionZoneTypeId] || 'rgba(0, 0, 0, 0.5)')
                })
            }
        }

        const drawPoly = (points, color) => {
            // Get the canvas and its context
            const canvas = document.getElementById('zoneCanvas')
            const ctx = canvas.getContext('2d')

            const img = imageRef.value;
            const scaleX = canvas.width / img.naturalWidth
            const scaleY = canvas.height / img.naturalHeight

            // Set the polygon color
            ctx.fillStyle = color.replace('0.5', '0.3')

            // Begin drawing the polygon
            ctx.beginPath();
            ctx.moveTo(points[0][0] * scaleX, points[0][1] * scaleY);
            points.forEach(([x, y], index) => {
                if (index === 0) return;
                ctx.lineTo(x * scaleX, y * scaleY);
            });
            ctx.closePath();
            ctx.fill();
        }

        const onInitialImageLoad = () => {
            adjustCanvasSize()
            drawAllZones()
        }

        const setupEventSource = () => {
            closeEventSource() // Close any existing EventSource before creating a new one
            const urlEDGEDEVICE = companyStore.company.SocketioURLPublic

            const eventSourceUrl = props.streamStatus 
                ? `${urlEDGEDEVICE}/processed_stream/${props.streamId}` 
                : `${urlEDGEDEVICE}/stream/${props.streamId}`
            eventSource = new EventSource(eventSourceUrl)

            eventSource.onmessage = (event) => {
                const base64Data = event.data;
                console.log(`Received frame data for stream ${props.streamId}: ${base64Data.substring(0, 30)}...`)
                const binaryString = atob(base64Data);
                const len = binaryString.length;
                const bytes = new Uint8Array(len);
                for (let i = 0; i < len; i++) {
                    bytes[i] = binaryString.charCodeAt(i);
                }
                const blob = new Blob([bytes], { type: 'image/jpeg' });
                const imageUrl = URL.createObjectURL(blob);
                capturedFrame.value = imageUrl;
                isLoadingStream.value = false;
            }

            eventSource.onerror = (error) => {
                console.error('Error with SSE:', error)
                toast.error(`Error with ${props.streamStatus ? 'processed' : 'live'} stream`)
                closeEventSource()
            }
        }

        //to prevent zones are continously redrawn (flickering)
        function debounce(func, wait = 100) {
            let timeout
            return (...args) => {
                clearTimeout(timeout);
                timeout = setTimeout(() => {
                    func.apply(this, args)
                }, wait)
            }
        }

        const handleResize = debounce(() => {
            const img = imageRef.value
            if (img) {
                adjustCanvasSize()
                drawAllZones()
            }
        }, 100)

        watch(() => isComponentAlive, (newStatus, oldStatus) => {
            if (newStatus) {
                onInitialImageLoad()
            }
        }, { immediate: true })


        onMounted(async () => {
            window.addEventListener('resize', handleResize)
            detectionZones.value = await DetectionZoneStore.fetchDetectionZonesByFlowId(parseInt(route.params.id))
            socket.value = props.socket
            console.log('started in mount')
            startShowLocalStream()
            hasJustMounted = false
        })

        watch(() => props.streamStatus, (newStatus, oldStatus) => {
            if (hasJustMounted) return
            console.log('Stream status changed:', newStatus, 'from:', oldStatus)
            
            if (newStatus !== oldStatus) {
                isLoadingStream.value = true;
                toast.info('Detection status changed, restarting stream...');
                console.log('stopping in watch');
                stopShowLocalStream();

                setTimeout(() => {
                    console.log('starting in watch');
                    startShowLocalStream();
                }, 3000);
            }

        }, { immediate: true })

        onBeforeUnmount(() => {
            isComponentAlive = false
            if (socket.value) {
                socket.value.off('connect')
                socket.value.off('show_live_stream_handling_processing')
                socket.value.off('local_live_stream_stopped')
                socket.value.off('error')
            }
            stopShowLocalStream()
            window.removeEventListener('resize', handleResize)
            source.cancel('Operation canceled by the user.')
            closeEventSource()
        })

        window.addEventListener('beforeunload', stopShowLocalStream)

        return {
            onInitialImageLoad,
            adjustCanvasSize,
            capturedFrame,
            imageRef,
            isLoadingStream
        }
    }
}
</script>

<style scoped>

.live-stream-container {
    display: flex;
    flex-direction: column;
}

.stream-container {
    width: 100%; /* Ensures it takes the full width of the parent container */
    display: flex;
    justify-content: space-between;
}

.stream-item {
    width: 100%; /* Ensures the stream item takes the full width available */
    position: relative;
}

.btn-primary-start{
  background-color: #6C9046 !important;
  border: none !important;
}

.btn-primary-start:disabled {
    background-color: #a9a9a9 !important;
    cursor: not-allowed !important;
    border: none !important;
}

.btn-primary{
  background-color: #4F7EB3 !important;
  border: none !important;
}

.btn-primary:hover {
  background-color: #B2C149 !important;
}

.frame-container {
  max-width: 100%;
  overflow: hidden;
  padding-top: 10px;
}

.frame-content {
  width: 100%; /* Ensures the content scales to fit the container */
  position: relative;
}

.frame-content img {
  height: auto; /* Display the image at its original size */
  width: 100%; /* Ensures the image scales to fit the container */
}

#zoneCanvas {
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
    width: 100%; /* Ensures the canvas scales to fit the container */
    height: 100%; /* Ensures the canvas scales to fit the container */
}

.loader {
  width: 40px;
  padding: 8px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: #B2C149;
  --_m: 
    conic-gradient(#0000 10%,#000),
    linear-gradient(#000 0 0) content-box;
  -webkit-mask: var(--_m);
          mask: var(--_m);
  -webkit-mask-composite: source-out;
          mask-composite: subtract;
  animation: spinner 1s infinite linear;
}
@keyframes spinner {to{transform: rotate(1turn)}}

</style>