<template>
    <div>
        <label class="form-label card-text" style="margin-top: 20px; margin-bottom: 20px;"><b>Camera Stream:</b></label>
        
        <div style="margin-bottom: 10px">
            <input type="text" class="form-control" id="stream-link" v-model="StreamLink" :disabled="connectionTested">
        </div>

        <button v-if="!connectionTested" type="button" class="btn btn-primary btn-sm" @click="testConnectCamera">Test Camera Connection</button>
        <div v-if="isLoading">
          <div class="loader" style="margin-top: 100px;"></div>
        </div>

        <div class="frame-container" v-if="capturedFrame && connectionError === false">

          <!-- <img v-if="capturedFrame" :src="capturedFrame" alt="Captured Frame" /> -->

        </div>

    </div>
</template>

<script>
import axios from 'axios'

import { ref } from 'vue'
import { useToast } from 'vue-toastification'

import { useCompanyStore } from '@/stores/CompanyStore'

export default {
    name: 'CameraStreamSettings',
    
    components: {
      
    },

    emits: ["streamlink", "capturedframe"],

    setup(props, { emit }) {
        const toast = useToast()

        const companyStore = useCompanyStore()
      
        const StreamLink = ref('')
        const capturedFrame = ref(null)
        const connectionError = ref(false)
        const connectionTested = ref(false)

        const isLoading = ref(false)


        const testConnectCamera = async () => {
            connectionError.value = false
            isLoading.value = true
            try {
                const urlEDGEDEVICE = companyStore.company.SocketioURLPublic

                const response = await axios.get(`${urlEDGEDEVICE}/camera_connection_test`, {
                    params: { cameraStreamLink: StreamLink.value },
                    responseType: 'blob'
                })
                const blob = new Blob([response.data], { type: 'image/jpeg' })
                const urlCreator = window.URL || window.webkitURL
                const imageUrl = urlCreator.createObjectURL(blob)
                capturedFrame.value = imageUrl
                connectionTested.value = true
                emitStreamLinkData()
                toast.success('Connection with camera established')
            } catch (error) {
                console.error('Camera connection test failed:', error)
                connectionError.value = true
                toast.error(`Failed to connect to camera: ${error.message}`)
            } finally {
                isLoading.value = false
            }
        }


        //Send data to parent when value of checkbox changes
        const emitStreamLinkData = () => {
          emit('streamlink', StreamLink.value)
          emit('capturedframe', capturedFrame.value)
        }

        const onCancel = () => {
          console.log('User cancelled the loader.')
        }

    
        return {
          StreamLink,
          capturedFrame,
          connectionError,
          testConnectCamera,
          connectionTested,
          isLoading,
          onCancel
        }
    }
}

</script>

<style scoped>
.btn {
  background-color: #4F7EB3;
  border: none;
}

.btn:hover {
  background-color: #B2C149;
}

.frame-container {
  max-width: 100%;
  /* max-height: 1000px; */
  overflow: hidden;
  padding-top: 10px;
}

.frame-container img {
  width: 67%;
  height: auto;
}

.card-text {
  color: #425461
}

.loader {
  width: 50px;
  padding: 8px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: #B2C149;
  --_m: 
    conic-gradient(#0000 10%,#000),
    linear-gradient(#000 0 0) content-box;
  -webkit-mask: var(--_m);
          mask: var(--_m);
  -webkit-mask-composite: source-out;
          mask-composite: subtract;
  animation: spinner 1s infinite linear;
}
@keyframes spinner {to{transform: rotate(1turn)}}

</style>