<template>
    <div class="container">
        <div class="container">
            <div v-if="!isLoading">

                <MDBTabs v-if="!isLoading" v-model="activeTab">
                    <hr style="margin: 15px 0px 15px 0px">
                    <!-- Tabs navs -->
                    <!-- <MDBTabNav tabsClasses="mb-3">
                        <MDBTabItem tabId="event-count" href="event-count">Event Count</MDBTabItem>
                        <MDBTabItem tabId="event-list" href="event-list">Event List - Time</MDBTabItem>
                        <MDBTabItem tabId="id-list" href="id-list">Event List - ID </MDBTabItem>
                    </MDBTabNav> -->
                    <!-- Tabs navs -->

                    <!-- Tabs content -->
                    <MDBTabContent>
                        <MDBTabPane tabId="event-count">

                            <MDBTabs v-model="activeZoneTab">

                                <!-- Detection Zone Tabs navs -->
                                <MDBTabNav tabsClasses="mb-3">
                                    <MDBTabItem v-for="zoneName in sortedZoneNames(tableZoneCountsData)" :key="zoneName" :tabId="normalizeZoneName(zoneName)" :href="normalizeZoneName(zoneName)">
                                        {{ zoneName }}
                                    </MDBTabItem>
                                </MDBTabNav>
                                <!-- Detection Zone Tabs navs -->
                            
                            
                                <!-- Detection Zone Tabs content -->
                                <MDBTabContent>
                                    <MDBTabPane v-for="zoneName in sortedZoneNames(tableZoneCountsData)" :key="zoneName" :tabId="normalizeZoneName(zoneName)">
                                
                                        <div class="table-container">
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>Date</th>
                                                        <template v-if="tableZoneCountsData[zoneName].zoneType === 'Combined Entrance Exit Zone'">
                                                            <th>Total Store In</th>
                                                            <th>Total Store Out</th>
                                                        </template>
                                                        <template v-else>
                                                            <th>Total Zone Counts</th>
                                                            <th>Average Duration in Zone (sec)</th>
                                                        </template>
                                                    </tr>
                                                </thead>
                                                <tbody v-if="tableZoneCountsData[zoneName].data.length > 0">
                                                    <tr v-for="(item, index) in tableZoneCountsData[zoneName].data" :key="index">
                                                        <!-- Date Column -->
                                                        <td style="padding-left: 30px; font-size: small; color: #425461">
                                                            <b>{{ item.date }}</b>
                                                        </td>
                                                        <!-- Combined Entrance Exit Zones -->
                                                        <template v-if="tableZoneCountsData[zoneName].zoneType === 'Combined Entrance Exit Zone'">
                                                            <td style="text-align: center; font-size: small; color: #425461">
                                                                {{ item.totalStoreIn || '' }}
                                                            </td>
                                                            <td style="text-align: center; font-size: small; color: #425461">
                                                                {{ item.totalStoreOut || '' }}
                                                            </td>
                                                        </template>
                                                        <!-- Regular Zones -->
                                                        <template v-else>
                                                            <td style="text-align: center; font-size: small; color: #425461">
                                                                {{ item.zoneCounts !== 0 && item.zoneCounts !== undefined ? item.zoneCounts : '' }}
                                                            </td>
                                                            <td style="text-align: center; font-size: small; color: #425461">
                                                                {{ item.averageDuration !== '0 sec' ? item.averageDuration : '' }}
                                                            </td>
                                                        </template>
                                                    </tr>
                                                </tbody>
                                                <p v-else>No data to show</p>
                                            </table>
                                        </div>

                                    </MDBTabPane>
                                </MDBTabContent>

                            </MDBTabs>
                        </MDBTabPane>
                        
                        
                        <!-- <MDBTabPane tabId="event-list">
                            <MDBTabs v-model="activeZoneTab"> -->

                                <!-- Detection Zone Tabs navs -->
                                <!-- <MDBTabNav tabsClasses="mb-3">
                                    <MDBTabItem v-for="zoneName in sortedZoneNames(EventListByDurationData)" :key="zoneName" :tabId="normalizeZoneName(zoneName)" :href="normalizeZoneName(zoneName)">
                                        {{ zoneName }}
                                    </MDBTabItem>
                                </MDBTabNav> -->
                                <!-- Detection Zone Tabs navs -->

                                <!-- Detection Zone Tabs content -->
                                <!-- <MDBTabContent>
                                    <MDBTabPane v-for="zoneName in sortedZoneNames(EventListByDurationData)" :key="zoneName" :tabId="normalizeZoneName(zoneName)">
                                        <div class="table-container">
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>Date</th>
                                                        <th>Detection Zone</th>
                                                        <th>ID</th>
                                                        <th>Time In</th>
                                                        <th>Time Out</th>
                                                        <th>Duration (sec)</th>
                                                    </tr>
                                                </thead>
                                                <tbody v-if="EventListByDurationData[zoneName].length > 0">
                                                    <tr v-for="(item, index) in EventListByDurationData[zoneName]" :key="index">
                                                        <td style="padding-left: 30px; font-size: small; color: #425461"><b>{{ item.date }}</b></td>
                                                        <td style="text-align: center; font-size: small; color: #425461">{{ item.detectionZone }}</td>
                                                        <td style="text-align: center; font-size: small; color: #425461">{{ item.trackId }}</td>
                                                        <td style="text-align: center; font-size: small; color: #425461">{{ item.timeIn }}</td>
                                                        <td style="text-align: center; font-size: small; color: #425461">{{ item.timeOut }}</td>
                                                        <td style="text-align: center; font-size: small; color: #425461">{{ item.duration }}</td>
                                                    </tr>
                                                </tbody>
                                                <p v-else>No data to show</p>
                                            </table>
                                        </div>
                                    </MDBTabPane>
                                </MDBTabContent>
                            </MDBTabs>
                        </MDBTabPane>
 -->

                        <!-- <MDBTabPane tabId="id-list">
                            <div class="table-container">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Time of (first) IN Event</th>
                                            <th>Track ID</th>
                                            <th v-for="zoneType in zoneTypes" :key="zoneType">{{ zoneType }}</th>
                                        </tr>
                                    </thead>
                                    <tbody v-if="IdListByDurationData.length > 0">
                                        <tr v-for="(item, index) in IdListByDurationData" :key="index">
                                            <td style="padding-left: 30px; font-size: small; color: #425461"><b>{{ item.zones.Date }}</b></td>
                                            <td style="text-align: center; font-size: small; color: #425461"><b>{{ item.trackId }}</b></td>
                                            <td v-for="zoneType in zoneTypes" :key="zoneType" style="padding-left: 30px; font-size: small; color: #425461">
                                                <div v-if="item.zones[zoneType]">
                                                    <div v-for="(zone, index) in item.zones[zoneType]" :key="index">
                                                        {{ zone }}
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <p v-else>No data to show</p>
                                </table>
                            </div>
                        </MDBTabPane> -->


                    </MDBTabContent>

                </MDBTabs>

            </div>

            <div v-if="isLoading" class="loading-overlay">
                <div class="loader" style="margin-top: 20px;"></div>
            </div>

            
        </div>
    </div>
</template>

<script>
import { ref, onMounted, onUnmounted, watch, toRefs } from 'vue'
import { MDBTabs, MDBTabNav, MDBTabContent, MDBTabItem, MDBTabPane } from 'mdb-vue-ui-kit'


import {getCountersListByDayByDuration, getCountersListByHourByDuration,
        getcountersListByDayByDateRange, getcountersListByHourByDateRange,
        // getEventListByDuration, getIdListByDuration,
        // getEventListByDateRange, getIdListByDateRange
        } from "@/api/eventsAPI.js"

export default {
    name: 'TableView',

    components: {
        MDBTabs,
        MDBTabNav,
        MDBTabContent,
        MDBTabItem,
        MDBTabPane
    },

    props: {
        flowId: Number,
        startDate: Object,
        endDate: Object,
        dataGranularity: String,
        duration: Number,
        dateRangeMode: String,
        minimumDurationThreshold: Number
    },

    setup(props, { emit }) {
        const activeTab = ref('event-count')
        const activeZoneTab = ref('')
        
        const tableZoneCountsData = ref([])
        const EventListByDurationData = ref([])
        const IdListByDurationData = ref([])
        const zoneTypes = ref([])

        const isLoading = ref(false)
        const firstLoad = ref(true)

        let fetchIntervalTableData = null
        const isMounted = ref(true)
        let currentFetchController = null
        let latestRequestId = 0

        const { flowId, duration, dataGranularity, startDate, endDate, dateRangeMode, minimumDurationThreshold } = toRefs(props)

        const today = ref(new Date())

        const stopInterval = () => {
            if (fetchIntervalTableData) {
                clearInterval(fetchIntervalTableData)
                fetchIntervalTableData = null
            }
        }

        const startInterval = () => {
            stopInterval() // Ensure any existing interval is cleared
            fetchIntervalTableData = setInterval(refreshData, 30000)  // Refresh every 30 seconds
        }

        const refreshData = () => {
            if (!isMounted.value) return

            stopInterval() // Stop the interval before making a new API call

            if (dateRangeMode.value === 'duration') {
                fetchEventDataByDuration()
            } else if (dateRangeMode.value === 'dateRange') {
                fetchEventDataByDateRange()
            }
        }

        const debounce = (func, wait) => {
            let timeOut
            return (...args) => {
                clearTimeout(timeOut)
                timeOut = setTimeout(() => func.apply(this, args), wait)
            }
        }


        onMounted(() => {
            isMounted.value = true

            isLoading.value = true

            refreshData()  // Fetch data when component is mounted
            startInterval() // Set up an interval to keep fetching data
        })

        onUnmounted(() => {
            isMounted.value = false

            stopInterval(); // Stop the interval
            if (currentFetchController) {
                currentFetchController.abort(); // Abort any ongoing fetch request
            }
        })

        // Watch for dateRangeMode changes
        watch(() => dateRangeMode.value, debounce((newValue) => {
            if (newValue) {
                isLoading.value = true
                stopInterval() // Stop the interval before making a new API call
                refreshData()
            }
        }, 300))

        watch(() => duration.value, debounce((newValue) => {
            if (newValue) {
                isLoading.value = true
                stopInterval() // Stop the interval before making a new API call
                refreshData()
            }
        }, 300))

        watch(() => props.minimumDurationThreshold, debounce((newValue) => {
            if (newValue || newValue === 0) { // Handle zero and other values
                isLoading.value = true
                stopInterval() // Stop the interval before making a new API call
                refreshData()
            }
        }, 300))

        watch(() => dataGranularity.value, debounce((newValue) => {
            if (newValue) {
                isLoading.value = true
                stopInterval() // Stop the interval before making a new API call
                refreshData()
            }
        }, 300))

        watch(([startDate, endDate]), debounce ((newValues, prevValues) => {
            if (newValues.every(value => value !== null)) { // Both dates are selected
                isLoading.value = true
                stopInterval() // Stop the interval before making a new API call
                refreshData()
            }
        }, 300))

        // Watch for changes in activeTab
        watch(activeTab, (newValue) => {
            if (newValue === 'event-list' || newValue === 'id-list') {
                emit('tabStatusChange', true) // Emit true for these tabs
            } else {
                emit('tabStatusChange', false) // Emit false for other tabs
            }
        })

        const fetchEventDataByDuration = async () => {
            if (currentFetchController) {
                currentFetchController.abort(); // Abort the previous request
            }

            const controller = new AbortController()
            currentFetchController = controller // Update the current controller
            const requestId = ++latestRequestId

            if (flowId.value) {
                try {
                    if (controller.signal.aborted || !isMounted.value) {
                        return  // Abort early if the signal is already aborted
                    }
                    
                    let result
                    let counters
                    let counters_combined_ee

                    const now = new Date()
                    const startDate = new Date(now)
                    startDate.setDate(now.getDate() - duration.value + 1)

                    if (dataGranularity.value === 'hourly') {
                        result = await getCountersListByHourByDuration(props.flowId, minimumDurationThreshold.value, startDate, { signal: controller.signal })
                        counters = result.zone_counters_by_hour
                        counters_combined_ee = result.combined_ee_store_counters_by_hour
                    } else {
                        result = await getCountersListByDayByDuration(props.flowId, minimumDurationThreshold.value, startDate, { signal: controller.signal })
                        counters = result.zone_counters_by_day
                        counters_combined_ee= result.combined_ee_store_counters_by_day
                    }
                    
                    if (isMounted.value && requestId === latestRequestId) {  // Check for the latest request) {
                        createCountsTable(counters, counters_combined_ee)

                        const now = new Date()
                        const startDate = new Date(now)
                        startDate.setDate(now.getDate() - duration.value + 1)
                        
                        // let eventListByDurationResponse = await getEventListByDuration(flowId.value, minimumDurationThreshold.value, startDate, { signal: controller.signal })
                        // console.log('eventListByDurationResponse: ', eventListByDurationResponse)
                        // processEventListData(eventListByDurationResponse)
                        
                        // let idListByDurationResponse = await getIdListByDuration(flowId.value, minimumDurationThreshold.value, startDate, { signal: controller.signal })
                        // processIdListData(idListByDurationResponse)
                        
                        isLoading.value = false
                        startInterval() // Restart the interval after the API call is complete
                    }
                } catch (error) {
                    if (error.name === 'AbortError') {
                        console.log('Fetch aborted')
                    } else {
                        console.error('Error fetching event data:', error)
                    }
                    startInterval() // Restart the interval even if there is an error
                }
            }
        }

        const fetchEventDataByDateRange = async () => {
            if (currentFetchController) {
                currentFetchController.abort(); // Abort the previous request
            }

            const controller = new AbortController()
            currentFetchController = controller // Update the current controller
            const requestId = ++latestRequestId

            // Check if both dates are selected and flowId is available
            if (startDate.value && endDate.value && flowId.value) {
                try {
                    if (controller.signal.aborted || !isMounted.value) {
                        return  // Abort early if the signal is already aborted
                    }
                    
                    let result
                    let counters
                    let counters_combined_ee
                    
                    if (dataGranularity.value === 'hourly') {
                        result = await getcountersListByHourByDateRange(flowId.value, startDate.value, endDate.value, minimumDurationThreshold.value, { signal: controller.signal })
                        counters = result.zone_counters_by_hour
                        counters_combined_ee = result.combined_ee_store_counters_by_hour
                    } else {
                        result = await getcountersListByDayByDateRange(flowId.value, startDate.value, endDate.value, minimumDurationThreshold.value, { signal: controller.signal })
                        counters = result.zone_counters_by_day
                        counters_combined_ee= result.combined_ee_store_counters_by_day
                    }
                    
                    if (isMounted.value && requestId === latestRequestId) {  // Check for the latest request) {
                        createCountsTable(counters, counters_combined_ee)

                        // let eventListByDateRangeResponse = await getEventListByDateRange(flowId.value, startDate.value, endDate.value, minimumDurationThreshold.value, { signal: controller.signal })
                        // processEventListData(eventListByDateRangeResponse)
                        
                        // let idListByDateRangeResponse = await getIdListByDateRange(flowId.value, startDate.value, endDate.value, { signal: controller.signal })
                        // processIdListData(idListByDateRangeResponse)
                        
                        isLoading.value = false
                        startInterval() // Restart the interval after the API call is complete
                    }
                } catch (error) {
                    if (error.name === 'AbortError') {
                        console.log('Fetch aborted')
                    } else {
                        console.error('Error fetching event data:', error)
                    }
                    startInterval() // Restart the interval after the API call is complete
                }
            }
        }

        const createCountsTable = async (counters, counts_combined_ee) => {
            if (!isMounted.value) return

            let groupedData = {}
            if (counters && typeof counters === 'object') {
                // Iterate through each date in the counts object
                Object.keys(counters).forEach(date => {
                    const eventData = counters[date]
                    Object.keys(eventData).forEach(zoneName => {
                        const zoneData = eventData[zoneName]
                        const zoneType = zoneData.zone_type
                
                        // Initialize if not already present
                        if (!groupedData[zoneName]) {
                            groupedData[zoneName] = {
                                zoneType: zoneType,
                                data: []
                            }
                        }

                        // If the zone is a combined entrance-exit zone, show store in and store out
                        if (zoneType === 'Combined Entrance Exit Zone') {
                            if (counts_combined_ee) {
                                const combinedData = counts_combined_ee[date]?.[zoneName] || {};
                                groupedData[zoneName].data.push({
                                    date: formatDate(date, dataGranularity.value),
                                    totalStoreIn: combinedData.zone_in_count || 0,
                                    totalStoreOut: combinedData.zone_out_count || 0
                                });
                            }
                        } else {
                            // Regular zone data
                            groupedData[zoneName].data.push({
                                date: formatDate(date, dataGranularity.value),
                                zoneCounts: zoneData.zone_in_count,
                                averageDuration: formatDuration(Math.round(zoneData.average_duration))
                            });
                        }
                    })
                })

                tableZoneCountsData.value = groupedData
            } else {
                console.error('Unexpected response, event_list is not an array:', counters);
            }
        }

        watch(() => tableZoneCountsData.value, (newValue) => {
            if (!isMounted.value) return

            const zoneNames = Object.keys(newValue)
            if (firstLoad.value && zoneNames.length > 0) {
                activeZoneTab.value = normalizeZoneName(zoneNames[0])
                firstLoad.value = false
            }
        })

        // const processEventListData = (eventList) => {
        //     if (!isMounted.value) return

        //     const groupedData = {}

        //     eventList.forEach(event => {
        //         const zoneInTime = new Date(event.ZoneInTime)
        //         const zoneOutTime = new Date(event.ZoneOutTime)

        //         const rawDate = new Date(event.ZoneInTime);

        //         const formattedZoneInDate = zoneInTime.toLocaleString('en-GB', {
        //             timeZone: 'Europe/Paris',
        //             year: 'numeric',
        //             month: '2-digit',
        //             day: '2-digit',
        //             weekday: 'short'
        //         }).replace(/\//g, '-');

        //         const formattedZoneInTime = zoneInTime.toLocaleTimeString('en-GB', {
        //             timeZone: 'Europe/Paris',
        //             hour: '2-digit',
        //             minute: '2-digit',
        //             second: '2-digit'
        //         });

        //         const formattedZoneOutTime = zoneOutTime.toLocaleTimeString('en-GB', {
        //             timeZone: 'Europe/Paris',
        //             hour: '2-digit',
        //             minute: '2-digit',
        //             second: '2-digit'
        //         });

        //         if (!groupedData[event.DetectionZoneName]) {
        //             groupedData[event.DetectionZoneName] = []
        //         }
                
        //         groupedData[event.DetectionZoneName].push({
        //             date: formattedZoneInDate,
        //             rawDate: zoneInTime,  // Raw date for sorting (full date object)
        //             detectionZone: event.DetectionZoneName,
        //             trackId: event.TrackId,
        //             timeIn: formattedZoneInTime,
        //             timeOut: formattedZoneOutTime,
        //             duration: formatDuration(Math.round(event.Duration))
        //         })
        //     })

        //     // Sort by rawDate first, then by timeIn
        //     Object.keys(groupedData).forEach(zoneName => {
        //         groupedData[zoneName].sort((a, b) => {
        //             if (a.rawDate < b.rawDate) return -1
        //             if (a.rawDate > b.rawDate) return 1
        //             if (a.timeIn < b.timeIn) return -1
        //             if (a.timeIn > b.timeIn) return 1
        //             return 0
        //         })
        //     })

        //     EventListByDurationData.value = groupedData
        // }


        // const processIdListData = (idList) => {
        //     if (!isMounted.value) return

        //     const groupedData = {}
        //     const zonesSet = new Set()

        //     idList.forEach(item => {
        //         const trackId = item.TrackId
        //         const date = item.Date
        //         const formattedDateTime = formatDateTimeForIdList(date)
        //         if (!groupedData[formattedDateTime]) {
        //             groupedData[formattedDateTime] = {}
        //         }
        //         if (!groupedData[formattedDateTime][trackId]) {
        //             groupedData[formattedDateTime][trackId] = {}
        //         }
        //         groupedData[formattedDateTime][trackId]['Date'] = formattedDateTime

        //         Object.keys(item).forEach(key => {
        //             if (key !== 'TrackId' && key !== 'Date') {
        //                 zonesSet.add(key);
        //                 if (!groupedData[formattedDateTime][trackId][key]) {
        //                     groupedData[formattedDateTime][trackId][key] = []
        //                 }
        //                 item[key].forEach(zone => {
        //                     groupedData[formattedDateTime][trackId][key].push(`${zone.DetectionZoneName}: ${formatDuration(Math.round(zone.Duration))}`)
        //                 })
        //             }
        //         })
        //     })

        //     // Convert to an array for sorting
        //     const sortedData = []
        //     Object.keys(groupedData).sort().forEach(date => {
        //         Object.keys(groupedData[date]).sort().forEach(trackId => {
        //             const zones = groupedData[date][trackId]
        //             sortedData.push({ date, trackId, zones })
        //         })
        //     })

        //     IdListByDurationData.value = sortedData
        //     zoneTypes.value = Array.from(zonesSet).sort()

        //     //isLoading.value = false
        // }

        const sortedZoneNames = (data) => {
            return Object.keys(data).sort()
        }

        const formatDuration = (seconds) => {
            if (seconds < 60) {
                return `${seconds} sec`
            } else if (seconds < 3600) {
                const minutes = Math.floor(seconds / 60)
                const remainingSeconds = seconds % 60
                return `${minutes} min ${remainingSeconds} sec`
            } else {
                const hours = Math.floor(seconds / 3600)
                const minutes = Math.floor((seconds % 3600) / 60)
                const remainingSeconds = seconds % 60
                return `${hours} h ${minutes} min ${remainingSeconds} sec`
            }
        }

        const formatDate = (dateStr, datagranularity = 'daily') => {
            const date = new Date(dateStr)
            const day = date.toLocaleString('en-us', { weekday: 'short' })
            const dayOfMonth = date.getDate().toString().padStart(2, '0')
            const month = (date.getMonth() + 1).toString().padStart(2, '0')
            const year = date.getFullYear()
            
            if (datagranularity === 'hourly') {
                const hours = date.getHours().toString().padStart(2, '0')
                const minutes = date.getMinutes().toString().padStart(2, '0')
                const seconds = date.getSeconds().toString().padStart(2, '0')
                return `${day} ${dayOfMonth}-${month}-${year} - ${hours}:${minutes}:${seconds}`
            } else {
                return `${day} ${dayOfMonth}-${month}-${year}`
            }
        }

        const formatTime = (timeStr) => {
            const [hours, minutes, seconds] = timeStr.split(':').map(Number)
            const formattedHours = hours.toString().padStart(2, '0')
            const formattedMinutes = minutes.toString().padStart(2, '0')
            const formattedSeconds = seconds.toString().padStart(2, '0')
            return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`
        }

        const formatDateTimeForIdList = (dateStr) => {
            const date = new Date(dateStr)
            const dayOfMonth = date.getDate().toString().padStart(2, '0')
            const month = (date.getMonth() + 1).toString().padStart(2, '0')
            const year = date.getFullYear()
            const hours = date.getHours().toString().padStart(2, '0')
            const minutes = date.getMinutes().toString().padStart(2, '0')
            const seconds = date.getSeconds().toString().padStart(2, '0')
            return `${year}-${month}-${dayOfMonth} - ${hours}:${minutes}:${seconds}`
        }

        const normalizeZoneName = (name) => {
            return name.replace(/[^a-zA-Z0-9]/g, '_');
        }


        return {
            activeTab,
            activeZoneTab,
            tableZoneCountsData,
            EventListByDurationData,
            IdListByDurationData,
            zoneTypes,
            sortedZoneNames,
            formatDuration,
            formatDate,
            formatTime,
            normalizeZoneName,
            flowId,
            startDate,
            endDate,
            today,
            dataGranularity,
            duration,
            dateRangeMode,
            isLoading
        }

    }
}
</script>

<style scoped>

/* Active tab with red background and white text */
.nav-tabs {
  --bs-nav-tabs-link-active-color: #ffffff !important;
  --bs-nav-tabs-link-active-bg: #4F7EB3 !important;
  --bs-nav-tabs-border-color: #000000 !important;
  --bs-nav-tabs-link-hover-border-color: #000000 !important;
}

/* Inactive tab with white background and black text */
.nav {
  --bs-nav-tabs-link-active-border-color: #000000 !important;
  --bs-nav-link-hover-color: #4F7EB3 !important;
  --bs-nav-link-color: #425461 !important;
}

.table-container {
    max-height: 600px; /* Adjust the height as needed */
    overflow-y: auto;
    margin-top: 20px;
    border: 1px solid #ddd; /* Optional: adds a border around the table */
}

table {
    width: 100%; /* Ensure the table fills the container */
    border-collapse: collapse; /* Optional: for styling */
}

/* Additional styling for your table (optional) */
table, th, td {
    border: 1px solid #ddd; /* Adds borders to your table cells */
}

td {
    padding: 8px; /* Adds space around your table content */
    text-align: left; /* Aligns text to the left */
}

th{
    padding: 8px;/* Adds space around your table content */
    text-align: center; /* Aligns text to the left */
    font-size: small;
    background-color: #B2C149;
    color: #ffffff;
}

.loading-overlay {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 10; /* Ensure it is above other content */
}

.loader {
  width: 80px;
  padding: 8px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: #B2C149;
  --_m: 
    conic-gradient(#0000 10%,#000),
    linear-gradient(#000 0 0) content-box;
  -webkit-mask: var(--_m);
          mask: var(--_m);
  -webkit-mask-composite: source-out;
          mask-composite: subtract;
  animation: spinner 1s infinite linear;
}
@keyframes spinner {to{transform: rotate(1turn)}}

</style>