<template>
    <div class="mb-3">

        <div class="form-check form-check-inline">
            <input type="radio" class="form-check-input" name="analytics_type" v-model="analytics_type" value="Tabular Data" :checked="registered_value == value1" @change="$emit('analytics_type', analytics_type)">
            <label class="form-check-label" for="Tabular Data">Tabular Data</label>
        </div>

        <div class="form-check form-check-inline">
            <input type="radio" class="form-check-input" name="analytics_type" v-model="analytics_type" value="Time Series Data" :checked="registered_value == value2" @change="$emit('analytics_type', analytics_type)">
            <label class="form-check-label" for="Time Series Data">Time Series Data</label>
        </div>

    </div>
</template>

<script>
export default {
    name: 'RadioButtonAnalyticsType',
    
    props: {
        analytics_type_value: {
            registered_value: String,
        }
    },

    setup (props) {
        //Set and listen to v-model        
        const analytics_type = ''

        const registered_value = props.analytics_type_value

        //Values checkbox
        const value1 = "Tabular Data"
        const value2 = "Time Series Data"
        
        return {
            analytics_type,
            registered_value,
            value1,
            value2
        }        
    }
}
</script>

<style scoped>

</style>