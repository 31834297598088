<template>
  <div>
    <div class="container">
      <div class="container">

        <!-- Display loading indicator while data is being fetched -->
        
        <div v-if="isLoading" class="loader-container">
          <div class="loader" style="margin-top: 400px;"></div>
        </div>
        <!-- Render edconnections if all api record data stores are available and loading is complete -->
        
        <template v-else> 
          <div class="card">
            <div class="card-header custom-card-header">
                <button type="button" class="btn btn-primary custom-btn" @click="backHome"><span class="btn-label"><i class="bi bi-house-door-fill"></i></span></button>
                <div class="custom-title">
                    <h3 class="card-title mb-0">External Data Connections</h3>
                </div>
            </div>
            
            <div class="card-body">

              <!-- WEATHER DATA API -->
              <div class="card">
                
                <div class="card-header">
                  <h6 class="card-title">Weather Data Connections</h6>
                </div>
                
                <!-- Display dummy card if no weather api records are available -->
                <div class="card-body">
                  
                  <div v-if="!apiweatherdataStore.apiweatherdatarecords.length && !isDeleting" class="col">
                    <div class="card-body d-flex align-items-center">
                      <div v-if="!isSmallScreen">
                        <router-link :to="{ path: '/connect_api', query: { type: 'Weather Data' } }" class="btn btn-primary mt-3 squared-btn" :class="{ 'disabled-router-link': userRole === 'User' }" @click.native.prevent="userRole === 'User'"><span class="btn-label"></span><span style="font-size: x-large; margin-right: 5px">+</span><i class="bi bi-cloud-sun large-icon"></i></router-link>
                        <div class="text-content ml-3">
                          <p class="card-title-dummy">You haven't connected any weather data yet</p>
                          <p v-if="userRole != 'User'" class="card-text-dummy"><b>Create Your First Weather Data Connection</b></p>
                          <p v-if="userRole == 'User'" class="card-text-dummy"><b>Contact a Key User or an Admin to add your first weather data connection.</b></p>
                        </div>
                      </div>
                      <div v-if="isSmallScreen">
                        <div class="text-content ml-3">
                          <p class="card-title-dummy">You haven't connected any weather data yet</p>
                          <div v-if="userRole != 'User'">
                            <p class="card-text-dummy"><b>Create Your First Weather Data Connection</b></p>
                            <p class="card-text-dummy"><b>Please use a larger screen size (laptop/desktop) to add any externael data</b></p>
                          </div>
                          <p v-if="userRole == 'User'" class="card-text-dummy"><b>Contact a Key User or an Admin to add your first weather data connection.</b></p>
                        </div>
                      </div>
                    </div>
                  </div>
    
                  <!-- Render weather data api's if available -->
                  <div v-else class="d-flex flex-wrap">
                    <div class="m-2" v-for="apiweatherdatarecord in apiweatherdataStore.apiweatherdatarecords" :key="apiweatherdatarecord.APIWeatherRecordId">
                    <button type="button" class="btn rect-btn" :class="{ 'selected': selectedWeatherDataRecordId === apiweatherdatarecord.APIWeatherRecordId }" @click="selectWeatherDataConnection(apiweatherdatarecord.APIWeatherRecordId)" :disabled="userRole == 'User'">
                      <span class="btn-label"></span><i class="bi bi-cloud-sun small-icon"></i><span style="font-size: small; margin-left: 15px">{{ apiweatherdatarecord.APIWeatherRecordName }}</span>
                    </button>
                  </div>
                </div>
                </div>

                <div class="card-footer">
                  <router-link v-if="!isSmallScreen" :to="{ path: '/connect_api', query: { type: 'Weather Data' } }" class="btn btn-primary" :class="{ 'disabled-router-link': userRole === 'User' }" @click.native.prevent="userRole === 'User'" style="margin-right: 5px"><span class="btn-label"></span><i class="bi bi-plus-lg"></i></router-link>
                  <button type="button" class="btn btn-primary" style="margin-right: 5px" @click="deleteSelectedWeatherDataConnection" :disabled="!selectedWeatherDataRecordId || userRole == 'User'"><span class="btn-label"><i class="bi bi-trash"></i></span></button>
                </div>
              </div>
              <!-- ---- -->

            </div>
          
            <div class="card-footer">
              <button type="button" class="btn btn-primary" @click="backHome"><span class="btn-label"><i class="bi bi-house-door-fill"></i></span></button>
            </div>
          
          </div>
        </template>

      </div>
    </div>

  </div>
</template>

<script>
import { onBeforeMount, onBeforeUnmount, ref } from 'vue'
import { RouterLink } from 'vue-router'

import { useToast } from 'vue-toastification'

import { useUserStore } from '@/stores/UserStore'
import { useAPIWeatherDataStore } from '@/stores/APIWeatherDataStore'

export default {
  components: { RouterLink },

  setup() {
      const toast = useToast()

      const userStore = useUserStore()
      const apiweatherdataStore = useAPIWeatherDataStore()

      const userRole = ref('')
      const selectedWeatherDataRecordId = ref(null)

      const isSmallScreen = ref(window.innerWidth <= 768)

      const isLoading = ref(null)
      const isDeleting = ref(false)

      const handleResize = () => {
        isSmallScreen.value = window.innerWidth <= 768
      }

      onBeforeMount(async () => {
        isLoading.value = true
        await apiweatherdataStore.fetchAPIWeatherDataRecords()
        isLoading.value = false
        userRole.value = userStore.user.UserRole

        window.addEventListener('resize', handleResize)
        handleResize() // Set initial state based on current window size
      })

      const selectWeatherDataConnection = (id) => {
        if (selectedWeatherDataRecordId.value === id) {
          selectedWeatherDataRecordId.value = null
        } else {
          selectedWeatherDataRecordId.value = id
        }
      }

      const deleteSelectedWeatherDataConnection = async () => {
          if (confirm("Do you want to cancel the external data connection?")) {
            isDeleting.value = true
            await apiweatherdataStore.deleteAPIWeatherDataRecord(selectedWeatherDataRecordId.value)
            selectedWeatherDataRecordId.value = null
            await apiweatherdataStore.fetchAPIWeatherDataRecords()
            isDeleting.value = false
            toast.success('External Weather Data Connection deleted successfully')
          }
      }

      onBeforeUnmount(() => {
        // Remove the resize event listener when component is unmounted
        window.removeEventListener('resize', handleResize)
    })

      return { 
        apiweatherdataStore,
        userRole,
        isSmallScreen,
        isLoading,
        isDeleting,
        selectedWeatherDataRecordId,
        selectWeatherDataConnection,
        deleteSelectedWeatherDataConnection
      }
    }
}
</script>

<style scoped>

.custom-card-header {
    display: flex;
    align-items: center;
    position: relative;
}

.custom-btn {
    margin-right: auto;
}

.custom-title {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    text-align: center;
    pointer-events: none;
}

.partitle {
  color: #4F7EB3;
}

.card-title {
  color: #4F7EB3;
}

.card-title-dummy {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #4F7EB3;
}

.card-text-dummy {
  font-size: 14px;
  margin-bottom: 0px;
}

.text-content {
  margin-top: 10px;
  margin-left: 20px; /* Adjust space between button and text as needed */
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.squared-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70px; /* Adjust the size as needed */
  height: 70px; /* Adjust the size as needed */
  text-align: center;
  padding: 20px;
  border-radius: 10px;
  background-color: #4F7EB3;
  color: white;
  border: none;
}

.rect-btn {
  display: flex;
  align-items: center;
  width: 250px; /* Adjust the size as needed */
  height: 50px; /* Adjust the size as needed */
  text-align: left;
  padding: 20px;
  border-radius: 10px;
  background-color: #fff !important;
  color: #4F7EB3;
  border: solid 1px #4F7EB3 !important;
}

.rect-btn.selected {
  background-color: #4F7EB3 !important;
  color: white !important;
}

.large-icon {
  font-size: 30px; /* Adjust the size as needed */
}

.small-icon {
  font-size: 20px; /* Adjust the size as needed */
}

.squared-btn:hover,
.rect-btn:hover {
  background-color: #B2C149;
}

.btn {
  background-color: #4F7EB3;
  border: none;
}

.btn:hover {
  background-color: #B2C149;
}

.disabled-router-link {
  pointer-events: none;
  cursor: not-allowed;
  opacity: 0.65; /* Adjust the opacity to visually indicate it's disabled */
}

.loader-container {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  width: 100%; /* Take up the full viewport width */
  position: absolute; /* Make sure the loader covers the entire page */
  top: 0;
  left: 0;
}

.loader {
  width: 80px;
  padding: 8px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: #B2C149;
  --_m: 
    conic-gradient(#0000 10%,#000),
    linear-gradient(#000 0 0) content-box;
  -webkit-mask: var(--_m);
          mask: var(--_m);
  -webkit-mask-composite: source-out;
          mask-composite: subtract;
  animation: spinner 1s infinite linear;
}
@keyframes spinner {to{transform: rotate(1turn)}}

</style>