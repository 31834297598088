<template>
    <div>
        <div class="container">
            <div class="container">

                <div class="card">

                    <div class="card-header">
                        <div class="container text-center">
                            <h3 v-if='APITypeName' class="card-title">{{ APIName }}</h3>
                            <h3 v-else class="card-title">External Data Connection Name</h3>
                        </div>
                    </div>

                    <div class="card-body">
                        <div class="col-6 offset-2">
                            <div class="mb-2">
                                <label class="form-label card-text" for="name"><b>External Data Connection Name:</b></label>
                                <input type="text" class="form-control" id="name" v-model="APIName">
                            </div>

                            <div class="mb-2">
                                <label class="form-label card-text"><b>Choose the Connection Type:</b></label>
                                <dropdown-api-type :api_type_name="APITypeName" @apitype="on_apitype"></dropdown-api-type>
                            </div>

                            <div v-if="APITypeName == 'Weather Data'" class="mb-2">
                                <label class="form-label card-text"><b>Physical Location:</b></label>
                                <form>
                                    <div class="form-group-horizontal">
                                        <div class="form-group">
                                            <label class="form-label sub-label" for="latitude">Latitude:</label>
                                            <input type="number" class="form-control" v-model="Latitude" name="latitude" id="latitude" step="0.0001" min="-90" max="90" value="0">
                                        </div>
                                        <div class="form-group">
                                            <label class="form-label sub-label" for="longitude">Longitude:</label>
                                            <input type="number" class="form-control" v-model="Longitude" name="longitude" id="longitude" step="0.0001" min="-90" max="90" value="0">
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>

                    <div class="card-footer">
                        <div v-if="isSaving" style="margin-top: 5px; margin-bottom: 5px; margin-left: 7px;">
                            <div class="saver"></div>
                        </div>
                        <button type="button" class="btn btn-primary" :class="{ 'disabled-link': isSaveButtonDisabled }" style="margin-right: 5px" @click="createExternalDataConnection"><span class="btn-label"><i class="bi bi-save"></i></span></button>
                        <button type="button" class="btn btn-primary" style="margin-right: 5px" @click="cancelConnectExternalData"><span class="btn-label"><i class="bi bi-x-square"></i></span></button>
                    </div>

                </div>

            </div>
        </div>
    </div>
</template>

<script>
import { onMounted, ref, computed } from 'vue'
import { useRouter, useRoute } from 'vue-router'

import { useToast } from 'vue-toastification'

import { useAPIWeatherDataStore } from '@/stores/APIWeatherDataStore'

import DropdownApiType from './DropdownAPIType.vue'

export default {
    components: {
        DropdownApiType,
    },

    setup(){
        const toast = useToast()

        const apiweatherdataStore = useAPIWeatherDataStore()

        const router = useRouter()
        const route = useRoute()

        const APIName = ref('')
        const APITypeName = ref('')
        const APITypeId = ref('')
        
        const Latitude = ref(0)
        const Longitude = ref(0)
        
        const UserId = ref('')

        const isSaving = ref(null)

        onMounted(() => {
            if (route.query.type) {
                APITypeName.value = route.query.type
                console.log(APITypeName.value)
            }
        })

        //Update values when values in child are changed
        const on_apitype = (value) => {
            APITypeName.value = value
        }

        const resetValues = () => {
            APITypeName.value = ''
            APITypeId.value = ''
            Latitude.value = 0
            Longitude.value = 0
        }
        
        const isSaveButtonDisabled = computed (() => {
            return (
                !APIName.value ||
                APITypeName.value === ''
            )
        })

        const createExternalDataConnection = async () => {
            try{
                if (APITypeName.value === 'Weather Data') {
                    isSaving.value = true
                    const createdAPIWeatherDataRecord = await apiweatherdataStore.createAPIWeatherDataRecord(APIName.value, APITypeName.value, Latitude.value, Longitude.value)
                    
                }
                
                resetValues()
                toast.success('Externale Data Connection added successfully')
                router.push('/external_data')
                await apiweatherdataStore.fetchAPIWeatherDataRecords()
                isSaving.value = false
            } catch (error) {
                isSaving.value = false
                console.error("An unexpected error occurred while writing data to the database:", error)
                toast.error("An unexpected error occurred while writing data to the database. Please try again or contact support if the problem persists.:", error)
            }
        }

        const cancelConnectExternalData = () => {
            if (confirm("Do you want to cancel creating an external data connection and go back to the home page?")) {
                resetValues()
                setTimeout(() => {
                    router.push('/external_data')
                }, 100) // Add a slight delay before navigation
            }
        }

        return {
            APIName,
            APITypeName,
            APITypeId,
            Latitude,
            Longitude,
            UserId,
            apiweatherdataStore,
            createExternalDataConnection,
            on_apitype,
            cancelConnectExternalData,
            isSaveButtonDisabled,
            isSaving,
        }
    }
}
</script>

<style scoped>

.card-title {
  color: #4F7EB3 !important;
}

.sub-label {
    color: #4F7EB3;
}

.form-group-horizontal {
    display: flex;
    gap: 50px; /* Adjust the space between the inputs as needed */
}

.form-group {
    display: flex;
    flex-direction: column;
}

.btn {
  background-color: #4F7EB3;
  border: none;
}

.btn:hover {
  background-color: #B2C149;
}

.card-text {
  padding-top: 15px;
  color: #425461
}

.disabled-link {
  opacity: 0.5;
  pointer-events: none;
}

.saver {
  width: 25px;
  padding: 2px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: #B2C149;
  --_m: 
    conic-gradient(#0000 10%,#000),
    linear-gradient(#000 0 0) content-box;
  -webkit-mask: var(--_m);
          mask: var(--_m);
  -webkit-mask-composite: source-out;
          mask-composite: subtract;
  animation: spinner 1s infinite linear;
}
@keyframes spinner {to{transform: rotate(1turn)}}

</style>