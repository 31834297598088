<template>
  <div>
    <div class="container">
      <div class="container">
        
        <div class="card">

          <div class="card-header">

            <div class="container text-center">
              <h3 class="card-title">{{ flowStore.currentFlow.FlowName }}<button v-if="!isCameraRunning" type="button" class="btn btn-outline-primary btn-sm" @click="openNameEdit" style="margin-left: 10px"><span class="btn-label"><i class="bi bi-pencil"></i></span></button></h3>
              <div v-if="editName" class="mb-3 card-title">
                  <input type="text" class="form-control text-center" style="padding-right: 50px;" id="name" v-model="flowStore.currentFlow.FlowName">
              </div>
            </div>

          </div>

          <div class="card-body">

            <div class="col-6 offset-2">

              <div v-if="isCameraRunning">
                  <p style="font-size: large; color: #C21500;"><b>Status Notification:</b><br>Changes cannot be made while the camera is running</p>
              </div>
            
              <div class="mb-2">
                <div v-if="flowStore.currentFlow.InputTypeId==='Camera Stream'">
                  <div>
                    <span class="card-text"><b>Camera Stream: </b></span>
                    <p class="card-text disabled-text">{{ flowStore.currentFlow.CameraStreamLink}}</p>
                  </div>
                </div>
              </div>

            </div>
              
            <div class="col-8 offset-2">
              <div v-if="isLoading">
                <div class="loader"></div>
              </div>
              <detection-zone :stream_link="cameraStreamLink" :captured_frame="capturedFrame" :detection_zone="DetectionZone" :is_camera_running="isCameraRunning" @detectionzones="on_detectionzone_logic"></detection-zone>  
            </div>

          </div>

          <div class="card-footer">

            <div v-if="isSaving" style="margin-top: 5px; margin-bottom: 5px; margin-left: 7px;">
              <div class="saver"></div>
            </div>
            <button type="button" class="btn btn-primary" style="margin-right: 5px" @click="saveEditedFlow" :disabled="!hasChanges"><span class="btn-label"><i class="bi bi-save"></i></span></button>
            <button type="button" class="btn btn-primary" style="margin-right: 5px" @click="deleteFlow" :disabled="isCameraRunning"><span class="btn-label"><i class="bi bi-trash"></i></span></button>
            <button type="button" class="btn btn-primary" @click="backHome"><span class="btn-label"><i class="bi bi-camera-video-fill"></i></span></button>
          
          </div>

        </div>
      </div>
    </div>

  </div>
</template>

<script>
import axios from 'axios'

import { onBeforeMount, ref, computed} from 'vue'
import { useRoute, useRouter } from 'vue-router'

import { useToast } from 'vue-toastification'

import { useFlowStore } from '@/stores/FlowStore'
import { useCompanyStore } from '@/stores/CompanyStore'
import { useDetectionZoneStore } from '@/stores/DetectionZone'
import { useCameraStreamStore } from '@/stores/CameraStreamStore'

import DropdownInputType from './DropdownInputType.vue'

import DetectionZone from './DetectionZone.vue'

export default {
  components: {
    DropdownInputType,
    DetectionZone,
    },

  setup() {
    const toast = useToast()

    const flowStore = useFlowStore()
    const companyStore = useCompanyStore()
    const detectionzones = useDetectionZoneStore()
    const CameraStreamStore = useCameraStreamStore()

    const cameraStreamLink = ref('')
    const isCameraRunning = ref()

    const route = useRoute()
    const router = useRouter()

    const capturedFrame = ref(null)
    const capturedFrameString = ref('')
    const DetectionZone = ref({})

    const originalFlowName = ref('')
    const originalDetectionZones = ref({})

    const isLoading = ref(null)
    const isSaving = ref(null)

    const showNotification = ref(null)


    onBeforeMount(async () => {
      try {
        await flowStore.fetchCurrentFlow(parseInt(route.params.id))

        if (flowStore.currentFlow.FlowId !== undefined && flowStore.currentFlow.CameraStreamLink !== undefined) {
          isLoading.value = true
          await CameraStreamStore.fetchInputCameraStreambyFlowId(flowStore.currentFlow.FlowId)
          isCameraRunning.value = CameraStreamStore.inputcamerastream.StreamStatus
          cameraStreamLink.value = flowStore.currentFlow.CameraStreamLink
          originalFlowName.value = flowStore.currentFlow.FlowName

          await captureFrame(cameraStreamLink.value)
          DetectionZone.value = flowStore.currentFlow.DetectionZone

          if (DetectionZone.value) {
            originalDetectionZones.value = JSON.stringify(
              Object.entries(DetectionZone.value).map(([name, zone]) => ({
                name,
                type: zone.type,
                points: zone.points
              }))
            )
          }
        }
      } catch (error) {
        console.error('Error during onBeforeMount:', error);
      }
    })

    const captureFrame = async (cameraStreamLink) => {
      try {
        const urlEDGEDEVICE = companyStore.company.SocketioURLPublic

        const response = await axios.get(`${urlEDGEDEVICE}/detection_zone_frame`, {
          params: { cameraStreamLink: cameraStreamLink },
          responseType: 'blob'
        })
        const blob = new Blob([response.data], { type: 'image/jpeg' })
        const urlCreator = window.URL || window.webkitURL
        const imageUrl = urlCreator.createObjectURL(blob)
        capturedFrame.value = imageUrl
        isLoading.value = false
        toast.success('New frame captured successfully')
      } catch (error) {
        console.error('Frame capture failed:', error)
        toast.error(`Failed to capture new frame: ${error.message}`)
        isLoading.value = false
      }
    }

    const onCancel = () => {
      console.log('User cancelled the loader.')
    }

    const editName = ref(false)
    const openNameEdit = () => {
      if (!(editName.value)) {
        editName.value = true
      } else {
        editName.value = false
      }
    }

    const on_detectionzone_logic = (value) => {
        DetectionZone.value = value
    }

    // Check if there are unsaved changes
    const hasChanges = computed(() => {
      const currentFlowName = flowStore.currentFlow.FlowName
      const currentDetectionZones = JSON.stringify(
        Object.entries(DetectionZone.value || {}).map(([name, zone]) => ({
          name,
          type: zone.type,
          points: zone.points,
        }))
      )

      return (
        currentFlowName !== originalFlowName.value ||
        currentDetectionZones !== originalDetectionZones.value
      )
    })

    const saveEditedFlow = async () => {
      try {
        isSaving.value = true
        try {
          await detectionzones.editDetectionZoneToFlow(flowStore.currentFlow.FlowId, DetectionZone.value)
        } catch (error) {
          console.error("Error updating DetectionZone table:", error)
          toast.error("An unexpected error occurred while updating the detection zone(s) to the database. Please try again or contact support if the problem persists.:", error)
        }  
        
        try {
          ///Put this as last. For some reason it mounts the page after execution, resetting the values in the detection zone
          await flowStore.editFlow(flowStore.currentFlow.FlowId, flowStore.currentFlow.FlowName, flowStore.currentFlow.InputTypeId, flowStore.currentFlow.UserId)
          ////End strange behaviour
        } catch (error) {
          console.error("Error updating Flow table:", error)
                        toast.error("An unexpected error occurred while updating the flow to the database. Please try again or contact support if the problem persists.:", error)
        }

        // Update original values after successful save
        originalFlowName.value = flowStore.currentFlow.FlowName
        originalDetectionZones.value = JSON.stringify(
          Object.entries(DetectionZone.value || {}).map(([name, zone]) => ({
            name,
            type: zone.type,
            points: zone.points,
          }))
        )
        
        toast.success('Camera view updated successfully')

        // Clear or update local storage to ensure fresh data
        localStorage.removeItem('listOfFlows')
        localStorage.removeItem('FixedWidgetsData')
        localStorage.removeItem('detectionZoneData')

        await flowStore.fetchFlows()

        isSaving.value = false
        router.push('/camera_overview')
      } catch (error) {
        console.error("An unexpected error occurred while writing data to the database.:", error)
        toast.error("An unexpected error occurred while writing data to the database. Please try again or contact support if the problem persists.:", error)
        isSaving.value = false
      }
    }

    const deleteFlow = async () => {
      try {
        isSaving.value = true
        if (confirm("Do you really want to delete this camera view?")) {
                await flowStore.deleteFlow(flowStore.currentFlow.FlowId)
                
                // Clear or update local storage to ensure fresh data
                localStorage.removeItem('listOfFlows')
                localStorage.removeItem('FixedWidgetsData')
                localStorage.removeItem('detectionZoneData')

                await flowStore.fetchFlows()
                toast.info("Deleted camera view successfully.")
                isSaving.value = false
                setTimeout(() => {
                    router.push('/camera_overview')
                }, 100) // Add a slight delay before navigation
            }
      } catch (error) {
        console.error("An unexpected error occurred while deleting the camera view.", error)
        toast.error("An unexpected error occurred while deleting the camera view. Please try again or contact support if the problem persists.:", error)
        isSaving.value = false
      }
    }

    const backHome = () => {
      router.push('/camera_overview')
    }

    return { 
      flowStore,
      editName,
      openNameEdit,
      DetectionZone,
      cameraStreamLink,
      isCameraRunning,
      on_detectionzone_logic,
      saveEditedFlow,
      deleteFlow,
      backHome,
      capturedFrame,
      capturedFrameString,
      isLoading,
      isSaving,
      showNotification,
      onCancel,
      hasChanges
      }
  }
}
</script>

<style scoped>

.card-title {
  color: #4F7EB3 !important;
}

.disabled-text {
    opacity: 0.5;
    pointer-events: none;
}

.btn-primary{
  background-color: #4F7EB3 !important;
  border: none !important;
}

.btn-primary:hover {
  background-color: #B2C149 !important;
}

.btn-outline-primary{
  color: #4F7EB3 !important;
  border: none;
}
.btn-outline-primary:hover {
  color: #FFFFFF !important;
  background-color: #4F7EB3 !important;
}

.btn-outline-primary:active {
  color: #FFFFFF !important;
  background-color: #4F7EB3 !important;
}

.card-text {
  padding-top: 15px;
}

.frame-container {
  max-width: 50%;
  /* max-height: 1000px; */
  overflow: hidden;
  padding-top: 10px;
}

.frame-container img {
  width: 67%;
  height: auto;
}
.popup {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.4); /* Black with opacity */

    display: flex;
    align-items: center; /* Align vertically */
    justify-content: center; /* Align horizontally */
    z-index: 1000; /* Ensure it's on top */
}

.loader {
  width: 80px;
  padding: 8px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: #B2C149;
  --_m: 
    conic-gradient(#0000 10%,#000),
    linear-gradient(#000 0 0) content-box;
  -webkit-mask: var(--_m);
          mask: var(--_m);
  -webkit-mask-composite: source-out;
          mask-composite: subtract;
  animation: spinner 1s infinite linear;
}
.saver {
  width: 25px;
  padding: 2px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: #B2C149;
  --_m: 
    conic-gradient(#0000 10%,#000),
    linear-gradient(#000 0 0) content-box;
  -webkit-mask: var(--_m);
          mask: var(--_m);
  -webkit-mask-composite: source-out;
          mask-composite: subtract;
  animation: spinner 1s infinite linear;
}
@keyframes spinner {to{transform: rotate(1turn)}}

</style>