<template>
    <select class="form-select" id="toggleExternalDataType" v-model="api_type" @change="onChange">
        <option v-for="api in apitypes.apitypes" :key="api.APIId">{{ api.APIName }}</option>
    </select>
    
</template>

<script>
import { onBeforeMount, ref, watch } from 'vue'
import { useAPITypeStore } from '@/stores/APITypeStore'

export default{
    name: 'DropdownApiType',

    props: {
        api_type_name: String,
    },

    emits: ['apitype'],

    setup (props, { emit }) {
        const apitypes = useAPITypeStore()

        const api_type = ref(props.api_type_name)

        onBeforeMount(() => {
            apitypes.fetchAPITypes()
        })

        watch(() => props.api_type_name, (newValue) => {
            api_type.value = newValue
        })

        watch(api_type, (newType) => {
            emit('apitype', newType)
        })

        const onChange = (event) => {
            api_type.value = event.target.value
        }


        
        return {
            api_type,
            apitypes,
            onChange
        }
    }
}

</script>

<style scoped>

</style>