<template>
    <div>
      
        <div class="date-picker-container" style="font-size: small;">
        <Datepicker 
            v-model="localStartDate" 
            placeholder="Start Date"
            :upper-limit="today"
            @update:modelValue="emitStartDate"
        ></Datepicker>
        <Datepicker 
            v-model="localEndDate" 
            placeholder="End Date"
            :upper-limit="today"
            @update:modelValue="emitEndDate"
        ></Datepicker>
      </div>

    </div>
  </template>

<script>
import { ref, watch, } from 'vue'
import Datepicker from 'vue3-datepicker'

export default {
    name: 'DatePicker',

    components: {
        Datepicker
    },

    props: {
        startDate: Date,
        endDate: Date,
        clearDatePicker: Boolean,
    },

    emits: ['updateStartDate', 'updateEndDate', 'updateDateRangeMode', 'updateClearDatePicker'],
    
    setup(props, { emit }) {
        const today = ref(new Date())
        const dateRangeMode = ref('')

        // Override props with null to ensure the fields start empty
        const localStartDate = ref(null)
        const localEndDate = ref(null)
        const clearDatePicker = ref()

        // Apply the prop values only if they are not null
        if (props.startDate) {
            localStartDate.value = new Date(props.startDate)
        }
        if (props.endDate) {
            localEndDate.value = new Date(props.endDate)
        }

        watch(() => props.clearDatePicker, (newValue) => {
            if (newValue) {
                localStartDate.value = null
                emit('updateStartDate', localStartDate.value)
                localEndDate.value = null
                emit('updateEndDate', localEndDate.value)
                clearDatePicker.value = false
                emit('updateClearDatePicker', clearDatePicker.value)
            }
        })

        const emitStartDate = (newDate) => {
            localStartDate.value = newDate
            emit('updateStartDate', localStartDate.value)
        }

        const emitEndDate = (newDate) => {
            localEndDate.value = newDate
            clearDatePicker.value = false
            dateRangeMode.value = 'dateRange'
            emit('updateEndDate', localEndDate.value)
            emit('updateDateRangeMode', dateRangeMode.value)
            emit('updateClearDatePicker', clearDatePicker.value)
        }


        return {
            localStartDate,
            localEndDate,
            today,
            emitStartDate,
            emitEndDate,
        }
    }
}
</script>

<style scoped>

.date-picker-container {
    display: flex;
    justify-content: start;
    margin-top: 5px;
    margin-bottom: 5px;
    margin-right: 25px;
    gap: 10px;
    flex-wrap: wrap;
}

</style>