<template>
    <div>

        <div class="button-container">
            <button type="button" class="btn btn-primary btn-sm" @click="changeDuration(1)" :disabled="selectedDuration === 1 && dateRangeMode !== 'dateRange'">Today</button>
            <button type="button" class="btn btn-primary btn-sm" @click="changeDuration(7)" :disabled="selectedDuration === 7 && dateRangeMode !== 'dateRange'">Last 7 Days</button>
            <button type="button" class="btn btn-primary btn-sm" @click="changeDuration(30)" :disabled="selectedDuration === 30 && dateRangeMode !== 'dateRange'">Last 30 Days</button>
            <button type="button" class="btn btn-primary btn-sm" @click="changeDuration(365)" :disabled="selectedDuration === 365 && dateRangeMode !== 'dateRange'">Last 365 Days</button>
        </div>

    </div>
  </template>

<script>
import { ref, watch, toRefs } from 'vue'

export default {
    name: 'DurationButtons',

    components: {
        
    },

    props: {
        dateRangeMode: String,
        selectedDuration: {
          type: Number,
          default: 1
        }
    },

    emits: ['updateDateRangeMode', 'updateDuration', 'clearDatePicker'],
    
    setup(props, { emit }) {

        const localSelectedDuration = ref(props.selectedDuration)
        const { dateRangeMode } = toRefs(props)

        const changeDuration = (newDuration) => {
            localSelectedDuration.value = newDuration
            emit('updateDuration', localSelectedDuration.value)
            emit('updateDateRangeMode', 'duration')
            emit('clearDatePicker', true)
        }

        watch(dateRangeMode, (newMode) => {
          if (newMode === 'dateRange') {
            localSelectedDuration.value = null // Enable all buttons
          }
        })

        return {
            changeDuration,
            selectedDuration: localSelectedDuration,
            dateRangeMode
        }
    }
}
</script>

<style scoped>
.btn {
  background-color: #4F7EB3;
  border: none;
  padding: 5px 10px; /* Adds padding inside the button */
  white-space: nowrap; /* Prevents text from wrapping inside the button */
}

.btn:hover {
  background-color: #B2C149;
}

.button-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 5px;
    margin-bottom: 5px;
    margin-right: 25px;
    justify-content: flex-start;
}

</style>