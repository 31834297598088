<template>
    <div>
        <div class="container">
            <div class="container">

                <div class="card">

                    <div class="card-header">
                        <div class="container text-center">
                            <h3 class="card-title">Quick Data Analytics</h3>
                        </div>
                    </div>

                    <div class="card-body">
                        <div class="col-6 offset-2">
                            <div class="mb-3">
                                <label class="form-label card-text" for="fileUpload"><b>Upload your Microsoft Excel File:</b></label>
                                <p style="padding-left: 3px; font-size: x-small; color: #c21500;"><i>Please upload a .xlsx or .xls file.</i></p>
                                <div @click="clickFileInput" @dragover.prevent="dragOver" @dragenter.prevent="dragEnter" @dragleave.prevent="dragLeave" @drop.prevent="fileDrop"
                                    :class="['upload-drop-zone', { 'dragover': isDragOver }]" id="fileUpload">
                                    Drag and drop your file here or click to select a file
                                    <input type="file" @change="fileSelected" hidden ref="fileInputRef">
                                </div>
                                <div v-if="uploadedFile" style="margin-top: 15px;">
                                    <strong>Uploaded file:</strong> {{ uploadedFile.name }}
                                    <p @click="removeFile" style="font-size: x-small; display: inline-block; color: #c21500"><u style="cursor: pointer">Remove file</u></p>
                                </div>
                            </div>

                            <div class="loading-file-indicator-container" v-if="isLoadingFile">
                                <span class="loading-indicator">
                                    Uploading File<span class="dots">{{ animatedDots }}</span>
                                </span>
                            </div>

                            <div>
                                <button id="loadAnalyticsFile" type="button" class="btn btn-primary btn-sm" :disabled="!uploadedFile || isLoadingFile" @click="loadAnalyticsFile"><span class="btn-label"><i class="bi bi-upload"></i> Load File</span></button>
                            </div>

                            <div v-if="fileUploadCompleted">
                                <hr style="margin: 15px 0px 15px 0px">
                                <p style="padding-left: 3px; padding-top: 10px; font-size: small; color: green;">File {{ fileName }} Loaded Successfully</p>
                            
                                <div class="mb-2">
                                    <label class="form-label card-text"><b>Tabular Data or Time Series Data?:</b></label>
                                    <radio-button-analytics-type @analytics_type="on_analytics_type"></radio-button-analytics-type>
                                </div>

                                <div v-if="AnalyticsType">

                                    <div class="mb-2">
                                        <label class="form-label card-text" for="analyticsOptions"><b>Choose Analytic Types:</b></label>
                                        <div class="checkbox-container">
                                            <div class="form-check" v-for="(option, index) in analyticsOptions" :key="index">
                                                <input type="checkbox" class="form-check-input" :id="'analyticsOptions'+index" :value="option" v-model="selectedAnalyticsTypes" :disabled="isAnalyticsTypeCheckboxDisabled(index)">
                                                <label class="form-check-label" :for="'analyticsOptions'+index">{{ option }}</label>
                                            </div>
                                        </div>
                                    </div>

                                    <div v-if="AnalyticsType == 'Time Series Data'" class="card">
                                        <div class="card-header text-left">
                                            <h6  style="margin-bottom: 0px; color: #C21500">Settings - Time Series Data:</h6>
                                        </div>

                                        <div class="card-body">
                                            <label class="form-label" for="timeSeriesColumn" style="font-size: small"><b>Choose the Date / Time Column:</b></label>
                                            <p style="font-size: x-small; color: #c21500;"><i>*The date / time column is the column where the date or time values are present.</i></p>
                                            <div class="checkbox-container" style="font-size: small">
                                                <div class="form-check" v-for="(column, index) in fileColumns" :key="column">
                                                    <input type="checkbox" class="form-check-input" :id="'timeSeriesColumn'+index" :value="column" v-model="timeSeriesColumn" :disabled="isTimeSeriesDisabled(column)">
                                                    <label class="form-check-label" :for="'timeSeriesColumn'+index">{{ column }}</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr class="rounded" style="margin-top: 40px; color: #c21500">

                                    <div v-if="selectedAnalyticsTypes.includes('Basic Statistic Data Analysis')" class="card">

                                        <div class="card-header text-left">
                                            <h6  style="margin-bottom: 0px; color: #C21500">Settings - Basic Statistic Data Analysis:</h6>
                                        </div>

                                        <div class="card-body">
                                            <label class="form-label" for="primaryFileColumn" style="font-size: small"><b>Choose the Primary Data Column:</b></label>
                                            <p style="font-size: x-small; color: #c21500;"><i>*The primary data column is the column with the values where you want all information to be analyzed against to.</i></p>
                                            <div class="checkbox-container" style="font-size: small">
                                                <div class="form-check" v-for="(column, index) in fileColumns" :key="column">
                                                    <input type="checkbox" class="form-check-input" :id="'primaryFileColumn'+index" :value="column" v-model="primaryFileColumn" :disabled="isPrimaryDisabled(column)">
                                                    <label class="form-check-label" :for="'primaryFileColumn'+index">{{ column }}</label>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="card-body">
                                            <label class="form-label" for="dateTimeColumn" style="font-size: small"><b>Choose the Date / Time Column:</b></label>
                                            <p style="font-size: x-small; color: #c21500;"><i>*The date / time column is the column where the date or time values are present.</i></p>
                                            <div class="checkbox-container" style="font-size: small">
                                                <div class="form-check" v-for="(column, index) in fileColumns" :key="column">
                                                    <input type="checkbox" class="form-check-input" :id="'dateTimeColumn'+index" :value="column" v-model="dateTimeColumn" :disabled="isdateTimeColumnDisabled(column)">
                                                    <label class="form-check-label" :for="'dateTimeColumn'+index">{{ column }}</label>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="card-body">
                                            <label class="form-label" for="dataAnalyticsColumn" style="font-size: small"><b>Choose the Data Relevant Columns:</b></label>
                                            <p style="font-size: x-small; color: #c21500;"><i>*The data relevant columns are the columns where you want insights about related to the primary data column and date / time column.</i></p>
                                            <div class="checkbox-container" style="font-size: small">
                                                <div class="form-check" v-for="(column, index) in fileColumns" :key="column">
                                                    <input type="checkbox" class="form-check-input" :id="'dataAnalyticsColumn'+index" :value="column" v-model="dataAnalyticsColumn" :disabled="isDataAnalyticsDisabled(column)">
                                                    <label class="form-check-label" :for="'dataAnalyticsColumn'+index">{{ column }}</label>
                                                </div>
                                            </div>
                                        </div>
                                    
                                    </div>

                                    <div v-if="selectedAnalyticsTypes.includes('Correlation Analysis')" class="card">

                                        <div class="card-header text-left">
                                            <h6  style="margin-bottom: 0px; color: #C21500">Settings - Correlation Analysis:</h6>
                                        </div>

                                        <div class="card-body">
                                            <label class="form-label" for="correlationColumns" style="font-size: small; padding-bottom: 10px;"><b>Choose the Columns for Analyzing Correlations:</b></label>
                                            <div class="checkbox-container" style="font-size: small">
                                                <div class="form-check" v-for="(column, index) in numericalColumns" :key="column">
                                                    <input type="checkbox" class="form-check-input" :id="'correlationColumns'+index" :value="column" v-model="correlationColumns">
                                                    <label class="form-check-label" :for="'correlationColumns'+index">{{ column }}</label>
                                                </div>
                                            </div>
                                            <p v-if="correlationColumns.length < 2" style="padding-left: 3px; font-size: x-small; color: #c21500;">Choose at least <b>2</b> columns where you want to analyze the correlations between.</p>
                                        </div>
                                    
                                    </div>

                                    <div v-if="selectedAnalyticsTypes.includes('Trend Analysis')" class="card">

                                        <div class="card-header text-left">
                                            <h6  style="margin-bottom: 0px; color: #C21500">Settings - Trend Analysis:</h6>
                                        </div>

                                        <div class="card-body">
                                            <label class="form-label" for="trendAnalysisColumns" style="font-size: small"><b>Choose the Trend Data Relevant Columns:</b></label>
                                            <p style="font-size: x-small; color: #c21500;"><i>*The trend data relevant columns are the columns where you want insights about the trends in time.</i></p>
                                            <div class="checkbox-container" style="font-size: small">
                                                <div class="form-check" v-for="(column, index) in fileColumns" :key="column">
                                                    <input type="checkbox" class="form-check-input" :id="'trendAnalysisColumns'+index" :value="column" v-model="trendAnalysisColumns" :disabled="isTrendDataColumnDisabled(column)">
                                                    <label class="form-check-label" :for="'trendAnalysisColumns'+index">{{ column }}</label>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                    <div v-if="selectedAnalyticsTypes.includes('Forecasting')" class="card">

                                        <div class="card-header text-left">
                                            <h6  style="margin-bottom: 0px; color: #C21500">Settings - Forecasting:</h6>
                                        </div>

                                        <div class="card-body">
                                            <label class="form-label" for="forecastColumns" style="font-size: small; padding-bottom: 10px;"><b>Choose Parameters to Forecast:</b></label>
                                            <div class="checkbox-container" style="font-size: small">
                                                <div class="form-check" v-for="(column, index) in fileColumns" :key="column">
                                                    <input type="checkbox" class="form-check-input" :id="'forecastColumns'+index" :value="column" v-model="forecastColumns" :disabled="isForecastColumnDisabled(column)">
                                                    <label class="form-check-label" :for="'forecastColumns'+index">{{ column }}</label>
                                                </div>
                                            </div>

                                            <div class="form-group mb-2 d-flex align-items-center future-periods-form-group" style="font-size: small;">
                                                <label class="form-label card-text me-2 future-periods-form-label" for="futurePeriods"><b>Future forecast periods:</b></label>
                                                <input type="number" min="1" class="form-control future-periods-form-control" id="name" v-model="FuturePeriods">
                                                
                                                <div class="period-granularity">
                                                    <div class="form-check form-check-inline">
                                                        <input type="radio" id="days" class="form-check-input" value="days" v-model="PeriodGranularity">
                                                        <label class="form-check-label" for="days">Days</label>
                                                    </div>
                                                    <div class="form-check form-check-inline">
                                                        <input type="radio" id="hours" class="form-check-input" value="hours" v-model="PeriodGranularity">
                                                        <label class="form-check-label" for="hours">Hours</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <p style="color: #C21500; font-size: x-small; padding-left: 15px;"><i>*Please pick your future forecasting period correctly regarding the time base of your file</i></p>

                                    </div>

                                    <div>
                                        <button style="margin-top: 40px;" id="buttonStartAnalytics" type="button" class="btn btn-primary btn-sm" :disabled="canStartAnalytics || isLoading" @click="startAnalytics"><span class="btn-label"><i class="bi bi-gem"></i> Start Analytics</span></button>
                                    </div>

                                </div>

                            </div>

                            <div v-if="allApiCallsCompleted" class="mt-3">
                                <div v-if="analyticsResponseStatInsights">
                                    <hr style="margin: 15px 0px 15px 0px">
                                    <h5>Basic Statistic Insights:</h5>
                                    <p style="white-space: pre-wrap;">{{ analyticsResponseStatInsights }}</p>
                                </div>

                                <div v-if="correlationResponseInsights">
                                    <hr style="margin: 15px 0px 15px 0px">    
                                    <h5>Correlation Insights:</h5>
                                    <p style="white-space: pre-wrap;">{{ correlationResponseInsights }}</p>
                                </div>

                                <div v-if="trendAnalysisResponseInsights" class="mt-3">
                                    <hr style="margin: 15px 0px 15px 0px">
                                    <h5>Trend Analysis Insights:</h5>
                                    <p style="white-space: pre-wrap;">{{ trendAnalysisResponseInsights }}</p>
                                </div>

                                <div v-if="forecastResponseInsights" class="mt-3" style="font-size: small;">
                                    <hr style="margin: 15px 0px 15px 0px">
                                    <h5>Forecasting Insights:</h5>
                                    <table class="table">
                                    <thead>
                                        <tr>
                                        <!-- Dynamically create table headers -->
                                        <th v-for="header in forecastedColumnsOrder" :key="header">{{ header }}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <!-- Dynamically create table rows -->
                                        <tr v-for="(row, rowIndex) in forecastResponseInsights" :key="`row-${rowIndex}`">
                                        <td v-for="header in forecastedColumnsOrder" :key="`${rowIndex}-${header}`" :class="{ 'font-weight-bold': header === 'Date' }">
                                            <!-- Format date based on periodGranularity -->
                                            <template v-if="header === 'Date'">
                                                {{ formatDate(row[header], PeriodGranularity) }}
                                            </template>
                                            <template v-else>
                                                <!-- Display other values as is -->
                                                {{ typeof row[header] === 'number' ? parseInt(row[header]) : row[header] }}
                                            </template>
                                        </td>
                                        </tr>
                                    </tbody>
                                    </table>
                                </div>

                            </div>

                            <div class="loading-indicator-container" v-if="isLoading">
                                <span class="loading-indicator">
                                    Analytics Running. Hold on, this can take several minutes<span class="dots">{{ animatedDots }}</span>
                                </span>
                            </div>

                        </div>
                    </div>

                    <div class="card-footer">
                        <button type="button" class="btn btn-primary" style="margin-right: 5px" @click="cancelNewFlow"><span class="btn-label"><i class="bi bi-x-square"></i></span></button>
                    </div>

                </div>

            </div>
        </div>
    </div>
</template>

<script>
import { ref, computed } from 'vue'
import { loadFileandGetColumns, startStatisticDataAnalysis, startCorrelationDataAnalysis, startTrendDataAnalysis, startForecastingDataAnalysis } from "@/api/externaldataAPI.js"

import RadioButtonAnalyticsType from './RadioButtonAnalyticsType.vue'

export default {
    components: {
        RadioButtonAnalyticsType        
    },

    setup(){
        const AnalyticsType = ref('')

        const isDragOver = ref(false)
        const uploadedFile = ref(null)
        const fileInputRef = ref(null)
        const validFileTypes = ['xlsx', 'xls']
        const fileName = ref('')

        const fileColumns = ref([])
        const numericalColumns = ref([])

        const timeSeriesColumn = ref([])
        const primaryFileColumn = ref([])
        const dateTimeColumn = ref([])
        const dataAnalyticsColumn = ref([])
        const correlationColumns = ref([])
        const trendAnalysisColumns = ref([])
        const forecastColumns = ref([])
        const FuturePeriods = ref(1)
        const PeriodGranularity = ref('days')
        const forecastedColumnsOrder = ref([])

        const selectedAnalyticsTypes = ref([])

        const analyticsResponseStatInsights = ref('')
        const correlationResponseInsights = ref('')
        const trendAnalysisResponseInsights = ref('')
        const forecastResponseInsights = ref('')

        const isLoadingFile = ref(false)
        const isLoading = ref(false)
        const animatedDots = ref('')
        let loadingInterval = null
        const allApiCallsCompleted = ref(false)
        const fileUploadCompleted = ref(false)

        const dragOver = () => {
            isDragOver.value = true
        }

        const dragEnter = () => {
            isDragOver.value = true
        }

        const dragLeave = () => {
            isDragOver.value = false
        }

        const fileDrop = (event) => {
            const files = event.dataTransfer.files
            if (files.length) {
                const fileType = files[0].name.split('.').pop()
                if (validFileTypes.includes(fileType)) {
                    uploadedFile.value = files[0]
                    fileName.value = uploadedFile.value.name
                } else {
                    alert('Invalid file type. Please upload a .xlsx or .xls file.');
                }
            }
            isDragOver.value = false
            fileUploadCompleted.value = false
        }

        const fileSelected = () => {
            const files = fileInputRef.value.files
            if (files.length) {
                const fileType = files[0].name.split('.').pop()
                if (validFileTypes.includes(fileType)) {
                    uploadedFile.value = files[0]
                    fileName.value = uploadedFile.value.name
                } else {
                    alert('Invalid file type. Please upload a .xlsx or .xls file.');
                    fileInputRef.value.value = ''
                }
            }
            fileUploadCompleted.value = false
        }

        const removeFile = () => {
            uploadedFile.value = null
            if (fileInputRef.value) {
                fileInputRef.value.value = ''
            }
        }

        const clickFileInput = () => {
            fileInputRef.value.click()
        }

        const loadAnalyticsFile = async () => {
            fileUploadCompleted.value = false
            handleLoadingStateFile(true)

            const formData = new FormData()
            formData.append('data_file', uploadedFile.value)
            const fileColumnsResponse = await loadFileandGetColumns(formData)
            fileColumns.value = fileColumnsResponse.file_columns
            numericalColumns.value = fileColumnsResponse.numerical_columns

            removeFile()

            fileUploadCompleted.value = true

            handleLoadingStateFile(false)
            
        }

        const isPrimaryDisabled = (column) => {
            return((dateTimeColumn.value.includes(column) || dataAnalyticsColumn.value.includes(column)) || (primaryFileColumn.value.length === 1 && !primaryFileColumn.value.includes(column)))
        }

        const isdateTimeColumnDisabled = (column) => {
            return ((primaryFileColumn.value.includes(column) || dataAnalyticsColumn.value.includes(column)) || (dateTimeColumn.value.length === 1 && !dateTimeColumn.value.includes(column)))
        }

        const isDataAnalyticsDisabled = (column) => {
            return (primaryFileColumn.value.includes(column) || dateTimeColumn.value.includes(column))
        }

        const isTimeSeriesDisabled = (column) => {
            return (trendAnalysisColumns.value.includes(column) || (timeSeriesColumn.value.length === 1 && !timeSeriesColumn.value.includes(column)))
        }

        const isTrendDataColumnDisabled = (column) => {
            return (timeSeriesColumn.value.includes(column))
        }

        const isForecastColumnDisabled = (column) => {
            return (timeSeriesColumn.value.includes(column))
        }

        const on_analytics_type = (value) => {
            AnalyticsType.value = value
            if (value === 'Tabular Data') {
                // Clear selected values for disabled checkboxes when switching to Tabular Data
                selectedAnalyticsTypes.value = selectedAnalyticsTypes.value.filter(option => !['Trend Analysis', 'Forecasting'].includes(option))
            }
            if (value === 'Time Series Data') {
                // Clear selected values for disabled checkboxes when switching to Tabular Data
                selectedAnalyticsTypes.value = selectedAnalyticsTypes.value.filter(option => !['Basic Statistic Data Analysis'].includes(option))
            }
        }
  
        const analyticsOptions = ['Basic Statistic Data Analysis', 'Correlation Analysis', 'Trend Analysis', 'Forecasting']
        // Watch for changes in selectedOptions and perform actions accordingly
        const selectedOptionsWatcher = computed(() => {
        return selectedAnalyticsTypes.value
        })

        const isAnalyticsTypeCheckboxDisabled = (index) => {
          if (AnalyticsType.value === 'none') {
            return true; // Disable all checkboxes
          } else if (AnalyticsType.value === 'Tabular Data') {
            return [2, 3].includes(index)
          } else if (AnalyticsType.value === 'Time Series Data') {
            return [0].includes(index)
          }
        }

        const cleanupValues = () => {
            removeFile()
            selectedAnalyticsTypes.value = []
            timeSeriesColumn.value = []
            primaryFileColumn.value = []
            dateTimeColumn.value = []
            dataAnalyticsColumn.value = []
            correlationColumns.value = []
            trendAnalysisColumns.value = []
            forecastColumns.value = []
            FuturePeriods.value = 1
            PeriodGranularity.value = 'days'
        }

        const resetValues = () => {
            AnalyticsType.value = ''
            selectedAnalyticsTypes.value = []
            fileColumns.value = []
            numericalColumns.value = []
            timeSeriesColumn.value = []
            primaryFileColumn.value = []
            dateTimeColumn.value = []
            dataAnalyticsColumn.value = []
            correlationColumns.value = []
            trendAnalysisColumns.value = []
            forecastColumns.value = []
            FuturePeriods.value = 1
            PeriodGranularity.value = 'days'
            analyticsResponseStatInsights.value = ''
            correlationResponseInsights.value = ''
            trendAnalysisResponseInsights.value = ''
            forecastResponseInsights.value = ''
            removeFile()
        }
        
        const basicStatisticDataAnalysisEmpty = ref(true)
        const correlationAnalysHas2Values = ref(true)
        const trendDataAnalysisEmpty = ref(true)
        const forecastDataAnalysisEmpty = ref(true)

        const canStartAnalytics = computed (() => {
            
            if (selectedAnalyticsTypes.value.includes('Basic Statistic Data Analysis')) {
                if (primaryFileColumn.value.length > 0 && dateTimeColumn.value.length > 0 && dataAnalyticsColumn.value.length > 0) {
                    basicStatisticDataAnalysisEmpty.value = false
                } else {
                    basicStatisticDataAnalysisEmpty.value = true
                }
            } else {
                basicStatisticDataAnalysisEmpty.value = false
            }

            if (selectedAnalyticsTypes.value.includes('Correlation Analysis')) {
                if (correlationColumns.value.length >= 2) {
                    correlationAnalysHas2Values.value = false
                } else {
                    correlationAnalysHas2Values.value = true
                }
            } else {
                correlationAnalysHas2Values.value = false
            }

            if (selectedAnalyticsTypes.value.includes('Trend Analysis')) {
                if (timeSeriesColumn.value.length > 0 && trendAnalysisColumns.value.length > 0) {
                    trendDataAnalysisEmpty.value = false
                } else {
                    trendDataAnalysisEmpty.value = true
                }
            } else {
                trendDataAnalysisEmpty.value = false
            }

            if (selectedAnalyticsTypes.value.includes('Forecasting')) {
                if (timeSeriesColumn.value.length > 0 && forecastColumns.value.length > 0 && FuturePeriods.value > 0 && PeriodGranularity.value) {
                    forecastDataAnalysisEmpty.value = false
                } else {
                    forecastDataAnalysisEmpty.value = true
                }
            } else {
                forecastDataAnalysisEmpty.value = false
            }

            return (
                !AnalyticsType.value ||
                selectedAnalyticsTypes.value.length === 0 ||
                basicStatisticDataAnalysisEmpty.value ||
                correlationAnalysHas2Values.value ||
                trendDataAnalysisEmpty.value ||
                forecastDataAnalysisEmpty.value
                
            )
        })

        const startAnalytics = async () => {
            try{
                allApiCallsCompleted.value = false
                analyticsResponseStatInsights.value = ''
                correlationResponseInsights.value = ''
                trendAnalysisResponseInsights.value = ''
                forecastResponseInsights.value = ''

                handleLoadingState(true)

                if (selectedAnalyticsTypes.value.includes('Basic Statistic Data Analysis')) {
                    const response_statistical_insights = await startStatisticDataAnalysis(primaryFileColumn.value, dateTimeColumn.value, dataAnalyticsColumn.value)
                    if (!response_statistical_insights) {
                            console.error('Failed to load Statistical Description')
                    }  else {
                        analyticsResponseStatInsights.value = response_statistical_insights.basic_statistical_analysis
                    }
                }

                if (selectedAnalyticsTypes.value.includes('Correlation Analysis')) {
                    const response_correlation_insights = await startCorrelationDataAnalysis(correlationColumns.value)
                    if (!response_correlation_insights) {
                            console.error('Failed to load Correlation Analysis')
                    } else {
                        correlationResponseInsights.value = response_correlation_insights.correlation_analysis
                    }
                }

                if (selectedAnalyticsTypes.value.includes('Trend Analysis')) {
                    const response_trendan_insights = await startTrendDataAnalysis(timeSeriesColumn.value, trendAnalysisColumns.value)
                    if (!response_trendan_insights) {
                            console.error('Failed to load Trend Analysis')
                    } else {
                        trendAnalysisResponseInsights.value = response_trendan_insights.trends_analysis
                    }
                }

                if (selectedAnalyticsTypes.value.includes('Forecasting')) {
                    const response_forecast_insights = await startForecastingDataAnalysis(timeSeriesColumn.value, forecastColumns.value, FuturePeriods.value, PeriodGranularity.value)
                    if (!response_forecast_insights) {
                            console.error('Failed to load Forecast')
                    } else {
                        forecastResponseInsights.value = response_forecast_insights.predictions
                        forecastedColumnsOrder.value = response_forecast_insights.columnsOrder
                    }
                }

                allApiCallsCompleted.value = true
                    
                handleLoadingState(false)

                cleanupValues()

            } catch (error) {
                handleLoadingState(false)
                console.error("Error adding and fetching data:", error)
            }
        }

        const cancelAnalytics = () => {
            if (confirm("Do you want to cancel creating a new flow and go back to the home page?")) {
                resetValues()
                setTimeout(() => {
                    router.push('/')
                }, 100) // Add a slight delay before navigation
            }
        }

        const startLoadingAnimation = () => {
          let dotCount = 0
          loadingInterval = setInterval(() => {
              dotCount = (dotCount % 3) + 1 // Cycle through 1, 2, 3
              animatedDots.value = '.'.repeat(dotCount) // One, two, or three dots
          }, 500) // Adjust time for faster/slower animation
        }

        const stopLoadingAnimation = () => {
          clearInterval(loadingInterval)
          animatedDots.value = '' // Clear the dots when stopping the animation
        }

        const handleLoadingStateFile = (value) => {
            if (value) {
                isLoadingFile.value = value 
                startLoadingAnimation()
            } else {
                isLoadingFile.value = value
                stopLoadingAnimation()
            }
        }

        const handleLoadingState = (value) => {
            if (value) {
                isLoading.value = value 
                startLoadingAnimation()
            } else {
                isLoading.value = value
                stopLoadingAnimation()
            }
        }

        const formatDate = (dateString, dataGranularity) => {
            // Parse the date string
            const date = new Date(dateString);
            
            // Format date based on dataGranularity setting
            if (dataGranularity === 'daily' || dataGranularity === 'days') {
                // Format date as "Day, DD MMM YYYY"
                return date.toDateString().slice(0, 15);
            } else {
                // Format date as "Day, DD MMM YYYY HH:MM:SS"
                return date.toUTCString();
            }
        }

        return {
            loadAnalyticsFile,
            fileName,
            fileColumns,
            numericalColumns,
            AnalyticsType,
            timeSeriesColumn,
            primaryFileColumn,
            dateTimeColumn,
            dataAnalyticsColumn,
            correlationColumns,
            trendAnalysisColumns,
            forecastColumns,
            FuturePeriods,
            PeriodGranularity,
            isPrimaryDisabled,
            isdateTimeColumnDisabled,
            isTimeSeriesDisabled,
            isDataAnalyticsDisabled,
            isTrendDataColumnDisabled,
            isForecastColumnDisabled,
            startAnalytics,
            on_analytics_type,
            isDragOver,
            uploadedFile,
            dragOver,
            dragEnter,
            dragLeave,
            fileDrop,
            fileSelected,
            removeFile,
            clickFileInput,
            fileInputRef,
            analyticsOptions,
            selectedAnalyticsTypes,
            isAnalyticsTypeCheckboxDisabled,
            selectedOptionsWatcher,
            analyticsResponseStatInsights,
            correlationResponseInsights,
            trendAnalysisResponseInsights,
            forecastResponseInsights,
            forecastedColumnsOrder,
            cancelAnalytics,
            canStartAnalytics,
            isLoadingFile,
            isLoading,
            animatedDots,
            handleLoadingStateFile,
            handleLoadingState,
            allApiCallsCompleted,
            fileUploadCompleted,
            formatDate
        }
    }
}
</script>

<style scoped>

.card-title {
  color: #4F7EB3 !important;

}.btn {
  background-color: #4F7EB3;
  border: none;
}

.btn:hover {
  background-color: #B2C149;
}

.card-text {
  padding-top: 15px;
}

.disabled-link {
  opacity: 0.5;
  pointer-events: none;
}

.loading-file-indicator-container {
  display: flex;
  justify-content: left; /* Center the loading indicator horizontally */
  margin-bottom: 20px; /* Adjust as necessary for spacing */
}

.loading-indicator-container {
  display: flex;
  justify-content: center; /* Center the loading indicator horizontally */
  margin-top: 50px; /* Adjust as necessary for spacing */
}

.loading-indicator {
  padding: 5px;
  background-color: #f3f3f3; /* Light grey background, adjust as needed */
  border-radius: 5px;
  text-align: center;
  font-size: 14px; /* Adjust as necessary */
}

.upload-drop-zone {
    height: 100px;
    border: 2px dashed #ccc;
    border-radius: 5px;
    color: #ccc;
    text-align: center;
    padding-top: 35px;
    transition: border-color 0.3s, background-color 0.3s;
}

.upload-drop-zone.dragover {
    border-color: #000;
    background-color: #f0f0f0;
}

.checkbox-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    max-width: 600px;
}

.form-check {
    flex-basis: calc(33.33% - 10px);
    margin-bottom: 10px;
}

.future-periods-form-group {
  padding-top: 20px;
}

.future-periods-form-label {
  flex: 0 1 auto;
  min-width: 20%;
  padding-bottom: 10px;
}

.future-periods-form-control {
  flex-grow: 1;
  flex: 1 1 auto;
  max-width: 17%;
  font-size: small;
}

.period-granularity {
  max-width: 60%;
  padding-left: 20px;
  padding-top: 10px;
}

/* Media query for screens less than 950px */
@media (max-width: 950px) {
  .checkbox-container {
    flex-direction: column; /* Stack vertically under each other */
    align-items: flex-start; /* Align items to the left */
    max-width: none; /* Remove maximum width */
  }

  .form-check {
    flex-basis: 100%; /* Take full width of the container */
  }
}
</style>