<template>

  <div class="container">
    <div class="container">


      <MDBTabs v-model="activeTab">
        <hr style="margin: 15px 0px 15px 0px">
        <!-- Tabs navs -->
        <MDBTabNav tabsClasses="mb-3">
          <MDBTabItem style="font-size: small;" tabId="smart-analytics" href="smart-analytics">Smart Analytics</MDBTabItem>
          <MDBTabItem style="font-size: small;" tabId="prediction" href="prediction">Smart Predictions</MDBTabItem>
        </MDBTabNav>
        <!-- Tabs navs -->
        
        <!-- Tabs content -->
        <MDBTabContent>
          <!-- Content Smart Analytics -->
          <MDBTabPane tabId="smart-analytics">
            <div class="mb-2" style="font-size: small;">

              <button v-if="execSumReady" type="button" style="margin-bottom: 15px;" class="btn btn-primary btn-sm" :disabled="!execSumReady" @click="resetSmartAnalytics"><span class="btn-label"><i class="bi bi-bootstrap-reboot"></i> Reset Smart Analytics</span></button> 
              
              <div class="card" style="margin-bottom: 15px;">
                <div class="card-header">
                  <h6 class="card-title" style="font-size: small;">Select Detection Zone(s):</h6>
                </div>

                <div class="card-body">
                  
                  <div v-if="zoneNames.length > 1"  class="form-check">
                    <label class="form-check-label" style="color: #B2C149;" :class="{ disabledCheckbox: externaldataTested }"><input class="form-check-input" type="checkbox" v-model="selectAllZones" :disabled="externaldataTested" @change="toggleSelectAllZones"/><i>Select All</i></label>
                  </div>

                  
                  <div class="checkbox-container" >
                    <div class="form-check" v-for="zone in zoneNames" :key="zone">
                      <label class="form-check-label" :class="{ disabledCheckbox: selectAllZones || externaldataTested }"><input class="form-check-input" type="checkbox" :value="zone" v-model="selectedZones" :disabled="selectAllZones || externaldataTested"/>{{ zone }}</label>
                    </div>
                  </div>

                </div>
              </div>

              <div class="card" style="margin-bottom: 15px;">
                <div class="card-header">
                  <h6 class="card-title" style="font-size: small;">Select External Data Source to Connect with the Data:</h6>
                </div>

                <div class="card-body">

                  <p style="color: #B2C149"><u><b>Historical Weather Data: </b></u></p>
                  <div class="checkbox-container" >
                    <div class="form-check" v-for="apiweatherdatarecord in apiweatherdataStore.apiweatherdatarecords" :key="apiweatherdatarecord.APIWeatherRecordId">
                      <label class="form-check-label" :class="{ disabledCheckbox: externaldataTested }"><input class="form-check-input" type="checkbox" :value="apiweatherdatarecord.APIWeatherRecordId" v-model="selectedWeatherData" :disabled="externaldataTested"/>{{ apiweatherdatarecord.APIWeatherRecordName }}</label>
                    </div>
                  </div>

                </div>
              </div>
              
              <div v-if="externaldatasetPreview.length > 0 && externalcolumnsOrder.length > 0" class="table-responsive" style="margin-top: 15px;">
                <label class="form-label card-text" style="color: #B2C149"><b>Preview External Data (first 5 rows):</b></label>
                <table class="table">
                  <thead>
                    <tr>
                      <!-- Dynamically create table headers -->
                      <th v-for="header in externalcolumnsOrder" :key="header">{{ header }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <!-- Dynamically create table rows -->
                    <tr v-for="(row, rowIndex) in externaldatasetPreview" :key="`row-${rowIndex}`">
                      <td v-for="header in externalcolumnsOrder" :key="`${rowIndex}-${header}`" :class="{ 'font-weight-bold': header === 'Date' }">
                        <!-- Format date based on dataGranularity -->
                        <template v-if="header === 'Date'">
                            {{ formatDate(row[header], dataGranularity) }}
                        </template>
                        <template v-else>
                            <!-- Display other values as is -->
                          {{ typeof row[header] === 'number' ? (row[header] % 1 === 0 ? row[header] : parseFloat(row[header]).toFixed(3)) : row[header] }}
                        </template>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div v-if="isLoadingPreview" class="loading-overlay">
                  <div class="loader" style="margin-top: 50px;"></div>
              </div>

              <button v-if="externaldataTested" type="button" style="margin-top: 10px;" class="btn btn-primary btn-sm" :disabled="analyticsPerformed" @click="releaseExternalData"><span class="btn-label"><i class="bi bi-arrow-clockwise"></i> Release External Data</span></button> 


              <div class="button-container">
                <button type="button" class="btn btn-primary btn-sm" :disabled="!canPreviewExternalDataset || externaldataTested || isLoadingPreview" @click="previewExternalDataset"><span class="btn-label"><i class="bi bi-eye-fill"></i> Test Connection & Load External Dataset</span></button>
                <button type="button" class="btn btn-primary btn-sm" :disabled="!canConnectDataset || dataConnected  || isLoadingConnected" @click="connectDatasets"><span class="btn-label"><i class="bi bi-bezier2"></i> Connect Dataset</span></button>
              </div>


              <div v-if="connecteddatasetPreview.length > 0 && connectedcolumnsOrder.length > 0" class="table-responsive">
                <hr style="margin: 15px 0px 15px 0px">
                <label class="form-label card-text" style="padding-top: 20px;"><b>Preview Connected Data:</b></label>
                <table class="table">
                  <thead>
                    <tr>
                      <!-- Dynamically create table headers -->
                      <th v-for="header in connectedcolumnsOrder" :key="header">{{ header }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <!-- Dynamically create table rows -->
                    <tr v-for="(row, rowIndex) in connecteddatasetPreview" :key="`row-${rowIndex}`">
                      <td v-for="header in connectedcolumnsOrder" :key="`${rowIndex}-${header}`" :class="{ 'font-weight-bold': header === 'Date' }">
                        <!-- Format date based on dataGranularity -->
                        <template v-if="header === 'Date'">
                            {{ formatDate(row[header], dataGranularity) }}
                        </template>
                        <template v-else>
                            <!-- Display other values as is -->
                          {{ typeof row[header] === 'number' ? (row[header] % 1 === 0 ? row[header] : parseFloat(row[header]).toFixed(3)) : row[header] }}
                        </template>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div v-if="isLoadingConnected" class="loading-overlay">
                  <div class="loader" style="margin-top: 50px;"></div>
              </div>

        
              <div>
                <hr style="margin: 15px 0px 15px 0px">
                <button id="buttonSmartAnalytics" type="button" class="btn btn-primary btn-sm" :disabled="!canStartAnalytics || analyticsPerformed || isLoadingAnalytics" @click="startAnalytics"><span class="btn-label"><i class="bi bi-gem"></i> Start Smart Analytics</span></button>
              </div>

              <div v-if="isLoadingAnalytics" class="loading-overlay">
                  <div class="loader" style="margin-top: 50px;"></div>
              </div>

              <div v-if="smartAnalyticsResponseStatDescrip" class="mt-3">
                <h5>Smart Analytics: Statistic Description Insights:</h5>
                <p style="white-space: pre-wrap;">{{ smartAnalyticsResponseStatDescrip }}</p>
              </div>

              <div v-if="smartAnalyticsResponseCorrAn" class="mt-3">
                <h5>Smart Analytics: Correlation Analysis Insights:</h5>
                <p style="white-space: pre-wrap;">{{ smartAnalyticsResponseCorrAn }}</p>
              </div>

              <div v-if="smartAnalyticsResponseTrendAn" class="mt-3">
                <h5>Smart Analytics: Trends Analysis Insights:</h5>
                <p style="white-space: pre-wrap;">{{ smartAnalyticsResponseTrendAn }}</p>
              </div>

              <hr style="margin: 15px 0px 15px 0px">

              <div v-if="canStartExecSum">
                <div>
                  <button id="buttonExecutiveSummary" type="button" class="btn btn-primary btn-sm" :disabled="!canStartExecSum || execSumReady  || isLoadingExecSum" @click="startExecSum"><span class="btn-label"><i class="bi bi-file-earmark-text"></i> Generate Executive Summary</span></button>
                </div>
                <div v-if="isLoadingExecSum" class="loading-overlay">
                  <div class="loader" style="margin-top: 50px;"></div>
                </div>
                <div v-if="smartAnalyticsResponseExecSum" class="mt-3">
                  <h5>Smart Analytics: Executive Summary:</h5>
                  <p style="white-space: pre-wrap;">{{ smartAnalyticsResponseExecSum }}</p>
                  <hr style="margin: 15px 0px 15px 0px">
                </div>
              </div>

              <button v-if="execSumReady" type="button" style="margin-top: 10px;" class="btn btn-primary btn-sm" :disabled="!execSumReady" @click="resetSmartAnalytics"><span class="btn-label"><i class="bi bi-bootstrap-reboot"></i> Reset Smart Analytics</span></button> 

            </div>
          </MDBTabPane>



          <!-- Content Prediction -->
          <MDBTabPane tabId="prediction">
            <div class="mb-2" style="font-size: small;">

              <button v-if="predictionReady" type="button" style="margin-bottom: 15px;" class="btn btn-primary btn-sm" :disabled="!predictionReady" @click="resetPredictions"><span class="btn-label"><i class="bi bi-bootstrap-reboot"></i> Reset Predictions</span></button> 
              
              <div class="card" style="margin-bottom: 15px;">
                <div class="card-header">
                  <h6 class="card-title" style="font-size: small;">Choose values to predict:</h6>
                </div>
                
                <div class="card-body">
                  
                  <div class="form-check">
                    <label class="form-check-label" style="color: #B2C149;" :class="{ disabledCheckbox: predictionReady || isLoadingPred }"><input class="form-check-input" type="checkbox" v-model="selectAllPredValues" :disabled="predictionReady || isLoadingPred" @change="toggleSelectAllPredValues"/><i>Select All</i></label>
                  </div>

                  <div class="checkbox-container">
                    <div class="form-check" v-for="zone in zoneNames" :key="zone">
                      <input type="checkbox" class="form-check-input" :disabled="selectAllPredValues || predictionReady || isLoadingPred" :id="'total-' + zone" v-model="checkedValuesToPredict" :value="zone + '_total_counts_in'">
                      <label class="form-check-label" :class="{ disabledCheckbox: selectAllPredValues || predictionReady || isLoadingPred }" :for="'total-' + zone">Total in {{ zone }}</label>
                    </div>
                    <div class="form-check" v-for="zone in zoneNames" :key="zone + '-avg'">
                      <input type="checkbox" class="form-check-input" :disabled="selectAllPredValues || predictionReady || isLoadingPred" :id="'avg-' + zone" v-model="checkedValuesToPredict" :value="zone + '_average_duration'">
                      <label class="form-check-label" :class="{ disabledCheckbox: selectAllPredValues || predictionReady || isLoadingPred }" :for="'avg-' + zone">Average duration in {{ zone }}</label>
                    </div>
                  </div>

                </div>

              </div>

              <div class="card" style="margin-bottom: 15px;">
                <div class="card-header">
                  <h6 class="card-title" style="font-size: small;">Select External Data Source to Connect with the Data:</h6>
                </div>

                <div class="card-body">

                  <p style="color: #B2C149"><u><b>Historical Weather Data: </b></u></p>
                  <div class="checkbox-container" >
                    <div class="form-check" v-for="apiweatherdatarecord in apiweatherdataStore.apiweatherdatarecords" :key="apiweatherdatarecord.APIWeatherRecordId">
                      <label class="form-check-label" :class="{ disabledCheckbox: predictionReady || isLoadingPred }"><input class="form-check-input" type="checkbox" :value="apiweatherdatarecord.APIWeatherRecordId" v-model="selectedWeatherDataForPrediction" :disabled="predictionReady || isLoadingPred"/>{{ apiweatherdatarecord.APIWeatherRecordName }}</label>
                    </div>
                  </div>

                </div>
              </div>

              <label class="form-label card-text me-2 future-periods-form-label" for="futurePeriods"><b>Future prediction period (starting from now and max. 14 days ahead):</b></label>
              <div class="form-group mb-2 d-flex align-items-center future-periods-form-group">
                <input type="number" min="1" class="form-control future-periods-form-control" id="name" v-model="FuturePeriods">
                <label class="form-label card-text me-2 future-periods-form-label" for="futurePeriods"><b>{{ future_prediction_period }}</b></label>
              </div>
              <p v-if="(dataGranularity == 'daily' && FuturePeriods > 14) || (dataGranularity == 'hourly' && FuturePeriods > 336)">Future Prediction Period is too large</p>
              
              <div>
                <button id="buttonPredict" type="button" class="btn btn-primary btn-sm" style="margin-top: 15px;" :disabled="!canStartPrediction || isLoadingPred || predictionReady" @click="startPrediction"><span class="btn-label"><i class="bi bi-speedometer"></i> Start Prediction </span></button>
              </div>

              <div v-if="isLoadingPred" class="loading-overlay">
                  <div class="loader" style="margin-top: 50px;"></div>
              </div>

              <div v-if="smartAnalyticsResponsePredict" class="table-responsive" style="margin-top: 15px;">
                <label class="form-label card-text" style="color: #B2C149"><b>Predictions:</b></label>
                <table class="table">
                  <thead>
                    <tr>
                      <!-- Dynamically create table headers -->
                      <th v-for="header in predictedcolumnsOrder" :key="header">{{ header }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <!-- Dynamically create table rows -->
                    <tr v-for="(row, rowIndex) in smartAnalyticsResponsePredict" :key="`row-${rowIndex}`">
                      <td v-for="header in predictedcolumnsOrder" :key="`${rowIndex}-${header}`" :class="{ 'font-weight-bold': header === 'Date' }">
                        <!-- Format date based on periodGranularity -->
                        <template v-if="header === 'Date'">
                            {{ formatDate(row[header], periodGranularity) }}
                        </template>
                        <template v-else>
                            <!-- Display other values as is -->
                            {{ typeof row[header] === 'number' ? parseInt(row[header]) : row[header] }}
                        </template>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <hr style="margin: 15px 0px 15px 0px">
              <button v-if="predictionReady" type="button" style="margin-top: 10px;" class="btn btn-primary btn-sm" :disabled="!predictionReady" @click="resetPredictions"><span class="btn-label"><i class="bi bi-bootstrap-reboot"></i> Reset Predictions</span></button> 

            </div>
          </MDBTabPane>
        </MDBTabContent>
        <!-- Tabs content -->
      </MDBTabs>   
      
    </div>
  </div>

</template>

<script>
import { ref, computed, nextTick, onMounted, toRefs, watch } from 'vue'
import { MDBTabs, MDBTabNav, MDBTabContent, MDBTabItem, MDBTabPane } from 'mdb-vue-ui-kit'

import { useFlowStore } from '@/stores/FlowStore'
import { useAPIWeatherDataStore } from '@/stores/APIWeatherDataStore'

import { previewExternalDataByDateRange, previewExternalDataByDuration,
        loadConnectedDataByDateRange, loadConnectedDataByDuration,
        startSmartAnalyticsStatDescripByDateRange, startSmartAnalyticsStatDescripByDuration,
        startSmartAnalyticsCorrAnByDateRange, startSmartAnalyticsCorrAnByDuration,
        startSmartAnalyticsTrendAnByDateRange, startSmartAnalyticsTrendAnByDuration,
        startSmartAnalyticsExecSum,
        startSmartAnalyticsPrediction, } from "@/api/externaldataAPI.js"

import DatePicker from './DatePicker.vue'
import DataGranularity from './DataGranularityRadioButton.vue'

export default {
    name: 'SmartAnalytics',

    components: {
        DatePicker,
        DataGranularity,
        MDBTabs,
        MDBTabNav,
        MDBTabContent,
        MDBTabItem,
        MDBTabPane
    },

    props: {
      flowId: Number,
      startDate: Object,
      endDate: Object,
      dataGranularity: String,
      duration: Number,
      dateRangeMode: String,
      minimumDurationThreshold: Number
    },

    emits: ['updateClearDatePicker', 'close'],

    setup(props, { emit }) {
        const activeTab = ref('smart-analytics')

        const flowStore = useFlowStore()
        const apiweatherdataStore = useAPIWeatherDataStore()

        const detectionZoneNames = ref({})
        const selectedZones = ref([])
        const selectAllZones = ref(false)

        const selectedWeatherData = ref([])

        const externaldatasetPreview = ref([])
        const externalcolumnsOrder = ref([])
        const externaldataTested = ref(false)

        const connecteddatasetPreview = ref([])
        const connectedcolumnsOrder = ref([])
        const dataConnected = ref(false)

        const smartAnalyticsResponseStatDescrip = ref('')
        const smartAnalyticsResponseCorrAn = ref('')
        const smartAnalyticsResponseTrendAn = ref('')
        const analyticsPerformed = ref(false)

        const smartAnalyticsResponseExecSum = ref('')
        const execSumReady = ref(false)

        const selectAllPredValues = ref(false)
        const checkedValuesToPredict = ref([])
        const selectedWeatherDataForPrediction = ref([])
        const future_prediction_period = ref(null)
        const FuturePeriods = ref(1)
        
        const predictionReady = ref(false)
        
        const { flowId, duration, dataGranularity, startDate, endDate, dateRangeMode, minimumDurationThreshold } = toRefs(props)
      
        const smartAnalyticsResponsePredict = ref('')
        const predictedcolumnsOrder = ref([])

        const isLoadingPreview = ref(false)
        const isLoadingConnected = ref(false)
        const isLoadingAnalytics = ref(false)
        const isLoadingExecSum = ref(false)
        const isLoadingPred = ref(false)

        const zoneNames = computed(() => Object.keys(detectionZoneNames.value))
        const canPreviewExternalDataset = computed(() => { return (selectedWeatherData.value.length > 0 && (duration.value || (startDate.value && endDate.value)) || externaldataTested.value)})
        const canConnectDataset = computed(() => { return ((externaldataTested.value && selectedZones.value.length > 0))})
        const canStartAnalytics = computed(() => { return (selectedZones.value.length > 0)})
        const canStartExecSum = computed(() => { return (smartAnalyticsResponseStatDescrip.value.length > 0 && smartAnalyticsResponseCorrAn.value.length > 0 && smartAnalyticsResponseTrendAn.value.length > 0)})
        const canStartPrediction = computed(() => { return (checkedValuesToPredict.value.length > 0 && FuturePeriods.value > 0)})

        onMounted(async() => {
            await flowStore.fetchCurrentFlow(flowId.value)
            await apiweatherdataStore.fetchAPIWeatherDataRecords()
            detectionZoneNames.value = flowStore.currentFlow.DetectionZone
            
            if (dataGranularity.value === 'daily') {
              future_prediction_period.value = 'days'
            }
            else if (dataGranularity.value === 'hourly') {
              future_prediction_period.value = 'hours'
            }   
        })

        const toggleSelectAllZones = () => {
          if (selectAllZones.value) {
            selectedZones.value = [...zoneNames.value]
          } else {
            selectedZones.value = []
          }
        }

        watch(selectAllZones, (newVal) => {
          toggleSelectAllZones()
        })

        const previewExternalDataset = async () => {
          externaldatasetPreview.value = ''
          connecteddatasetPreview.value = ''
            if (selectedWeatherData.value) {
                try {
                  isLoadingPreview.value = true
                  let response = null
                  if (!startDate.value && !endDate.value) {
                      response = await previewExternalDataByDuration(selectedWeatherData.value, duration.value, dataGranularity.value)
                    }
                    else {
                      response = await previewExternalDataByDateRange(selectedWeatherData.value, startDate.value, endDate.value, dataGranularity.value)
                    }
                    
                    if (response.columnsOrder && response.preview) {
                        externaldatasetPreview.value = response.preview
                        externalcolumnsOrder.value = response.columnsOrder
                        externaldataTested.value = true
                        isLoadingPreview.value = false
                    } else {
                        console.error('Failed to load external dataset: Missing required fields in the response.')
                        isLoadingPreview.value = false
                    }
                    
                } catch (error) {
                  console.error('Could not load external dataset:', error)
                  isLoadingPreview.value = false
                }
            }
        }

        const releaseExternalData = () =>{
          selectedWeatherData.value = []
          externaldatasetPreview.value = []
          externaldataTested.value = false
          selectedZones.value = []
          connecteddatasetPreview.value = []
          dataConnected.value = false
        }

        const connectDatasets = async () => {
          connecteddatasetPreview.value = ''  
          if (externaldataTested && selectedWeatherData.value && selectedZones.value) {
                isLoadingConnected.value = true
                let response = null
                if (!startDate.value && !endDate.value) {
                    response = await loadConnectedDataByDuration(flowId.value, selectedWeatherData.value, selectedZones.value, duration.value, dataGranularity.value, minimumDurationThreshold.value)
                }
                else {
                  response = await loadConnectedDataByDateRange(flowId.value, selectedWeatherData.value, selectedZones.value, startDate.value, endDate.value, dataGranularity.value, minimumDurationThreshold.value)
                }

                if (response && response.columnsOrder && response.preview) {
                    connecteddatasetPreview.value = response.preview
                    connectedcolumnsOrder.value = response.columnsOrder
                    dataConnected.value = true
                    isLoadingConnected.value = false
                } else {
                    console.error('Failed to load connected dataset')
                    isLoadingConnected.value = false
                }
            }
        }

        const startAnalytics = async () => {
            smartAnalyticsResponseStatDescrip.value = ''
            smartAnalyticsResponseCorrAn.value = ''
            smartAnalyticsResponseTrendAn.value = ''
            smartAnalyticsResponseExecSum.value= ''

            if (selectedZones.value) {
              isLoadingAnalytics.value = true
              
              //Splitting the different calls, because merging them in 1 call the connection closes before Azure OpenAI gets an answer back 
              
              let response_stat_descrip = null
              if (!startDate.value && !endDate.value) {
                response_stat_descrip = await startSmartAnalyticsStatDescripByDuration(flowId.value, selectedWeatherData.value, selectedZones.value, duration.value, dataGranularity.value, minimumDurationThreshold.value)
              }
              else {
                response_stat_descrip = await startSmartAnalyticsStatDescripByDateRange(flowId.value, selectedWeatherData.value, selectedZones.value, startDate.value, endDate.value, dataGranularity.value, minimumDurationThreshold.value)
              }
              if (!response_stat_descrip) {
                console.error('Failed to load Statistical Description')
              }

              let response_corr_an = null
              if (!startDate.value && !endDate.value) {           
                response_corr_an = await startSmartAnalyticsCorrAnByDuration(flowId.value, selectedWeatherData.value, selectedZones.value, duration.value, dataGranularity.value, minimumDurationThreshold.value)
              }
              else {
                response_corr_an = await startSmartAnalyticsCorrAnByDateRange(flowId.value, selectedWeatherData.value, selectedZones.value, startDate.value, endDate.value, dataGranularity.value, minimumDurationThreshold.value)
              }
              if (!response_corr_an) {
                console.error('Failed to load Correlation Analysis')
              }
              
              let response_trend_an = null
              if (!startDate.value && !endDate.value) {              
                response_trend_an = await startSmartAnalyticsTrendAnByDuration(flowId.value, selectedWeatherData.value, selectedZones.value, duration.value, dataGranularity.value, minimumDurationThreshold.value)
              }
              else {
                response_trend_an = await startSmartAnalyticsTrendAnByDateRange(flowId.value, selectedWeatherData.value, selectedZones.value, startDate.value, endDate.value, dataGranularity.value, minimumDurationThreshold.value)
              }
              if (!response_trend_an) {
                console.error('Failed to load Trends Analysis')
              }


              if (response_stat_descrip && response_corr_an && response_trend_an) {
                  smartAnalyticsResponseStatDescrip.value = response_stat_descrip.descriptive_statistics
                  smartAnalyticsResponseCorrAn.value = response_corr_an.correlation_analysis
                  smartAnalyticsResponseTrendAn.value = response_trend_an.trends_analysis
                  nextTick(() => {
                      const element = document.getElementById('buttonSmartAnalytics')
                      if (element) {
                          element.scrollIntoView({ behavior: 'smooth' })
                      }
                  })
                  isLoadingAnalytics.value = false
                  analyticsPerformed.value = true
              } else {
                  console.error('Failed to load Smart Analytics')
                  isLoadingAnalytics.value = false
              }
            }
        }

        const startExecSum = async () => {
          smartAnalyticsResponseExecSum.value= ''
            if (smartAnalyticsResponseStatDescrip.value && smartAnalyticsResponseCorrAn.value && smartAnalyticsResponseTrendAn.value) {
              isLoadingExecSum.value = true
              const response_exec_sum = await startSmartAnalyticsExecSum()
              if (response_exec_sum) {
                smartAnalyticsResponseExecSum.value = response_exec_sum.executive_summary
                  nextTick(() => {
                      const element = document.getElementById('buttonExecutiveSummary')
                      if (element) {
                          element.scrollIntoView({ behavior: 'smooth' })
                      }
                  })
                  isLoadingExecSum.value = false
                  execSumReady.value = true
              } else {
                  console.error('Failed to load Executive Summary')
                  isLoadingExecSum.value = false
              }
            }
        }

        const resetSmartAnalytics = () => { 
          externaldatasetPreview.value = []
          externalcolumnsOrder.value = []
          connecteddatasetPreview.value = []
          connectedcolumnsOrder.value = []
          smartAnalyticsResponseStatDescrip.value = ''
          smartAnalyticsResponseCorrAn.value = ''
          smartAnalyticsResponseTrendAn.value = ''
          smartAnalyticsResponseExecSum.value = ''
          externaldataTested.value = false
          dataConnected.value = false
          analyticsPerformed.value = false
          execSumReady.value = false
          selectedZones.value = []
          selectAllZones.value = false
          selectedWeatherData.value = []
        }


        const toggleSelectAllPredValues = () => {
          if (selectAllPredValues.value) {
            checkedValuesToPredict.value = [
              ...zoneNames.value.map(zone => `Total in ${zone}`),
              ...zoneNames.value.map(zone => `Average duration in ${zone}`)]
          } else {
            checkedValuesToPredict.value = []
          }
        }

        watch(selectAllPredValues, (newVal) => {
          toggleSelectAllPredValues()
        })

        watch(dataGranularity, (newVal) => {
          if (newVal === 'daily') {
            future_prediction_period.value = 'days'
          }
          else if (newVal === 'hourly') {
            future_prediction_period.value = 'hours'
          }   
        })

        const startPrediction = async () => {
            smartAnalyticsResponsePredict.value = ''  
            isLoadingPred.value = true

            const response = await startSmartAnalyticsPrediction(flowId.value, selectedWeatherDataForPrediction.value, checkedValuesToPredict.value, FuturePeriods.value, dataGranularity.value, minimumDurationThreshold.value)
            
            if (response && response.columnsOrder && response.predictions) {
              smartAnalyticsResponsePredict.value = response.predictions
              predictedcolumnsOrder.value = response.columnsOrder
              nextTick(() => {
                  const element = document.getElementById('buttonPredict')
                  if (element) {
                      element.scrollIntoView({ behavior: 'smooth' })
                  }
              })
              predictionReady.value = true
              isLoadingPred.value = false
            } else {
                console.error('Failed to load Predictions')
                isLoadingPred.value = false
            }
        }

        const resetPredictions = () => { 
          smartAnalyticsResponsePredict.value = ''
          predictedcolumnsOrder.value = []
          selectAllPredValues.value = false
          checkedValuesToPredict.value = []
          selectedWeatherDataForPrediction.value = []
          FuturePeriods.value = 1
          predictionReady.value = false
        }

        const formatDate = (dateString, dataGranularity) => {
            // Parse the date string
            const date = new Date(dateString + 'Z') //UTC
            
            // Format date based on dataGranularity setting
            if (dataGranularity === 'daily' || dataGranularity === 'days') {
                // Format date as "Day, DD MMM YYYY" in UTC
                return date.toUTCString().slice(0, 16)
            } else {
                // Format date as "Day, DD MMM YYYY HH:MM:SS" in UTC
                return date.toUTCString() // "Day, DD MMM YYYY HH:MM:SS GMT"
            }
        }


        return {
          activeTab,
          zoneNames,
          selectedZones,
          selectAllZones,
          toggleSelectAllZones,
          apiweatherdataStore,
          selectedWeatherData,
          canPreviewExternalDataset,
          previewExternalDataset,
          externaldatasetPreview,
          externalcolumnsOrder,
          externaldataTested,
          releaseExternalData,
          canConnectDataset,
          connectDatasets,
          connecteddatasetPreview,
          connectedcolumnsOrder,
          dataConnected,
          canStartAnalytics,
          startAnalytics,
          smartAnalyticsResponseStatDescrip,
          smartAnalyticsResponseCorrAn,
          smartAnalyticsResponseTrendAn,
          analyticsPerformed,
          canStartExecSum,
          startExecSum,
          smartAnalyticsResponseExecSum,
          execSumReady,
          resetSmartAnalytics,
          selectAllPredValues,
          toggleSelectAllPredValues,
          future_prediction_period,
          selectedWeatherDataForPrediction,
          predictionReady,
          canStartPrediction,
          checkedValuesToPredict,
          startPrediction,
          FuturePeriods,
          smartAnalyticsResponsePredict,
          predictedcolumnsOrder,
          resetPredictions,
          startDate,
          endDate,
          dataGranularity,
          formatDate,  
          isLoadingPreview,
          isLoadingConnected,
          isLoadingAnalytics,
          isLoadingExecSum,
          isLoadingPred,
        }
    }
}
</script>

<style scoped>
.checkbox-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: left;
    max-width: 1000px;
}

.form-check {
    flex-basis: calc(33.33% - 10px);
    margin-bottom: 10px;
}

.disabledCheckbox {
  color: grey;
}
.disabledCheckbox input {
  background-color: #e9ecef;
}


.card-title {
  color: #4F7EB3 !important;
}
.btn-primary{
  background-color: #4F7EB3 !important;
  border: none !important;
}

.btn-primary:hover {
  background-color: #B2C149 !important;
}

.btn-primary:disabled {
    background-color: #a9a9a9 !important;
    cursor: not-allowed !important;
    border: none !important;
}

.button-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 20px;
    margin-bottom: 20px;
    justify-content: flex-start;
}

.radio-buttons {
  display: flex;
  align-items: center;
  gap: 10px; /* Adjust the spacing between radio buttons as needed */
}

.form-check-label {
  margin-right: 15px; /* Adjust the spacing after labels if needed */
}

.checkbox-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px; /* Adjust the gap between checkboxes as needed */
}

.future-periods-form-group {
  padding-top: 10px;
}

.future-periods-form-label {
  flex: 0 1 auto;
  min-width: 20%;
  color: #4F7EB3;
}

.future-periods-form-control {
  flex-grow: 1;
  flex: 1 1 auto;
  max-width: 12%;
  font-size: small;
  margin-right: 5px;
}

.period-granularity {
  max-width: 60%;
  padding-left: 10px;
}


.table-responsive {
  overflow-x: auto;
  max-width: 100%;
}
.table {
  width: auto;
  min-width: 100%;
}

.font-weight-bold {
  font-weight: bold;
}

/* Active tab with red background and white text */
.nav-tabs {
  --bs-nav-tabs-link-active-color: #ffffff !important;
  --bs-nav-tabs-link-active-bg: #4F7EB3 !important;
  --bs-nav-tabs-border-color: #000000 !important;
  --bs-nav-tabs-link-hover-border-color: #000000 !important;
}

/* Inactive tab with white background and black text */
.nav {
  --bs-nav-tabs-link-active-border-color: #000000 !important;
  --bs-nav-link-hover-color: #4F7EB3 !important;
  --bs-nav-link-color: #425461 !important;
}

/* Media query for screens less than 950px */
@media (max-width: 950px) {
  .checkbox-container {
    flex-direction: column; /* Stack vertically under each other */
    align-items: flex-start; /* Align items to the left */
    max-width: none; /* Remove maximum width */
  }

  .form-check {
    flex-basis: 100%; /* Take full width of the container */
  }
}

.loading-overlay {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 10; /* Ensure it is above other content */
}

.loader {
  width: 80px;
  padding: 8px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: #B2C149;
  --_m: 
    conic-gradient(#0000 10%,#000),
    linear-gradient(#000 0 0) content-box;
  -webkit-mask: var(--_m);
          mask: var(--_m);
  -webkit-mask-composite: source-out;
          mask-composite: subtract;
  animation: spinner 1s infinite linear;
}
@keyframes spinner {to{transform: rotate(1turn)}}

</style>