<template>
    <div>
        <div v-if="isLoading" style="display: flex; justify-content: center;">
            <div class="loader" style="margin-top: 400px;"></div>
        </div>
            
        <div v-if="!isLoading" class="card">
            <div class="card-header">
                <div><h3 class="card-title">{{ CompanyStore.company.CompanyName }}’s Intelligence Hub</h3></div>
                <!-- <button class="toggle-sidebar" v-show="!isSidebarOpen" @click="toggleSidebar"> << Intalytic</button> -->
            </div>

            <div class="dashboard-wrapper">
                <div class="container">
                    <fixed-widgets :fixed-widgets-data="fixedWidgetsData"></fixed-widgets>
                </div>

                <div v-if="!isSmartphoneWidth" class="container">
                    <div class="card">
                        <div class="card-header-intervals">
                            <div class="left-group">
                                <div class="top-row">
                                    <duration-buttons :date-range-mode="dateRangeMode" :selected-duration="duration" @updateDateRangeMode="changeDateRangeMode" @updateDuration="changeDuration" @clearDatePicker="updateClearDatePicker"></duration-buttons>
                                    <date-picker style="margin-top: 3px;" :start-date="startDate" :end-date="endDate" :clear-date-picker="clearDatePicker" @updateStartDate="updateStartDate" @updateEndDate="updateEndDate" @updateDateRangeMode="changeDateRangeMode" @updateClearDatePicker="updateClearDatePicker"></date-picker>
                                    <data-granularity style="margin-top: 10px;" :data-granularity="dataGranularity" @updateDataGranularity="updateDataGranularity"></data-granularity>
                                </div>
                            </div>

                            <div class="right-group">
                                <div v-if="editMode" class="add-widget-container">
                                    <button class="btn btn-primary add-widget-btn btn-sm" @click="openAddWidgetModal" :disabled="!allWidgetsPresent">Add Widget</button>
                                </div>
                                
                                <div class="form-switch edit-mode-switch">
                                    <input class="form-check-input" type="checkbox" id="editModeSwitch" v-model="editMode">
                                    <label class="form-check-label" for="editModeSwitch">Edit Mode</label>
                                </div>
                            </div>
                        </div>

                        <div class="card-body">
                            <draggable-resizable-container
                                ref="gridContainer"
                                :key="gridKey"
                                :grid="[20, 20]"
                                :show-grid="editMode"
                                class="dynamic-height-container"
                                :style="{minHeight: editMode ? '5000px' : gridHeight + 'px'}">

                                <div v-if="isLoadingPage" class="loader-container" style="margin-top: 100px;">
                                    <div v-if="!isInitialLoad" class="loader-text">
                                        <p>Loading Dynamic Dashboard Widgets...</p>
                                    </div>
                                    <div v-else class="loader-text">
                                        <p>Loading dashboard widgets for the first time...</p>
                                        <p>Please wait, this may take a moment.</p>
                                        <p>You can customize your dashboard once loading is complete.</p>
                                    </div>
                                    <div class="loader"></div>
                                </div>

                                <div v-else :style="{ minHeight: gridHeight + 'px', width: '100vw' }">
                                    <draggable-resizable-vue
                                        v-for="widget in widgets"
                                        :key="widget.id"
                                        v-model:x="widget.x"
                                        v-model:y="widget.y"
                                        v-model:w="widget.width"
                                        v-model:h="widget.height"
                                        v-model:active="widget.isActive"
                                        :draggable="editMode"
                                        :prevent-deactivation="editMode"
                                        :resizable="false"
                                        handles-type="handles"
                                        :handles="editMode ? ['tm', 'bm', 'ml', 'mr'] : []"
                                        @dragging="handleDragging(widget.id)"
                                        @resizing="onWidgetResize(widget.id)"
                                        :ref="el => refs[`widget-${widget.id}`] = el">
                                    
                                        <div class="widget">
                                            <div class="widget-header">
                                            <span class="widget-title">{{ widget.title }}</span>
                                            <button v-if="editMode" type="button" class="btn btn-sm close-btn" @click="removeWidget(widget.id)">X</button>
                                            </div>
                                            <component :is="widget.component" v-bind="widget.props" :ref="el => refs[`component-${widget.id}`] = el"></component>
                                        </div>

                                    </draggable-resizable-vue>
                                </div>

                            </draggable-resizable-container>
                        </div>
                    
                    </div>
                </div>
                <div v-if="isSmartphoneWidth" class="container text-center" style="margin-top: 0px;">
                    <div class="card p-4" style="border-radius: 7px; background: #fff;">
                        <div class="card-body">
                            <!-- Icon with animation -->
                            <div class="mb-4">
                                <i class="bi bi-display" style="font-size: 3rem; color: #6c757d;"></i>
                            </div>
                            
                            <!-- Informational message -->
                            <h4 class="card-title" style="font-weight: bold; color: #495057; font-size: 1.2rem;">
                                Dynamic Dashboard Unavailable
                            </h4>
                            <p class="card-text" style="color: #6c757d; font-size: 1.0rem;">
                                Please open this page on a larger screen to access the full dynamic dashboard experience.
                            </p>
                        </div>
                    </div>
                </div>


                <!-- Add Widget Modal -->
                <div v-if="showAddWidgetModal" class="modal-overlay">
                    <div class="modal-content">
                        <button class="btn btn-sm btn-primary close-modal" @click="closeAddWidgetModal">X</button>
                        <!-- <h3>Add New Widget</h3> -->

                        <div v-if="isLoadingFilterWidgetOptions">
                            <div class="loader-container-filtered-widget-option">
                                <div class="loader-text">
                                    <p>Loading Available Widgets...</p>
                                </div>
                                <div class="loader-filtered-widget-options"></div>
                            </div>
                        </div>

                        <div v-else-if="isAddingWidget">
                            <div class="loader-container">
                                <div class="loader-text">
                                    <p>Adding Widget...</p>
                                </div>
                                <div class="loader"></div>
                            </div>
                        </div>
                        
                        <div v-else>
                            <div>
                                <label for="widgetType" style="color: #B2C149;">Select Widget Type:</label>
                                <div class="form-check" style="padding-top: 10px;">
                                    <select v-model="selectedWidgetType" @change="updateAvailableZones">
                                        <option v-for="option in filteredWidgetOptions" :value="option.category">{{ option.title }}</option>
                                    </select>
                                </div>
                            </div>

                            
                            <div style="padding-top: 20px;">
                                <label v-if="selectedWidgetType" style="color: #B2C149; padding-bottom: 10px;">Select {{ selectedWidgetType === 'heatmap_entrance' ? 'Heatmap' : selectedWidgetType?.includes('chart') ? 'Chart' : 'Counter' }}:</label>
                                <div v-if="isLoadingAvailableZones" class="loader-available-zones"></div>
                                
                                <div  v-else class="available-zones-list">
                                    <div class="form-check" v-for="zone in availableZones" :key="zone.value.zoneId || zone.value.flowId">
                                        <input 
                                            class="form-check-input"
                                            style="margin-top: 5px"    
                                            type="checkbox" 
                                            :value="zone.value" 
                                            v-model="selectedZones">
                                        <label class="form-check-label">{{ zone.label }}</label>
                                    </div>
                                </div>
                            </div>

                            <button class="btn btn-primary" @click="addSelectedWidgets" :disabled="!selectedZones.length">Add</button>
                        </div>
                    </div>
                </div>

            </div>
        </div>

        <!-- Side Bar for Chat Agent -->
        <div :class="['sidebar', { open: isSidebarOpen }]">
            <div class="sidebar-header">
                <h4 style="padding-top: 10px; padding-left: 10px;">Storalytic Intelligence Assistant</h4>
                <button class="toggle-sidebar close-btn" @click="toggleSidebar">X</button>
            </div>
            <div class="chat-container">
                <div class="chat-messages">
                    <div v-for="message in messages" :key="message.id" :class="message.sender">
                        <span style="font-size: xx-small;">{{ message.timestamp }}</span><br>
                        {{ message.sender }}: {{ message.text }}
                    </div>
                </div>
                <div class="chat-input">
                    <input
                        v-model="userInput"
                        @keydown.enter="sendMessage"
                        placeholder="Ask me anything about your store data..."
                    />
                    <button @click="sendMessage">Send</button>
                </div>
            </div>
        </div>

    </div>
</template>
  
<script>
import { ref, onMounted, onUnmounted, watch, computed, defineComponent, markRaw, nextTick, reactive } from 'vue'
import { useRouter } from 'vue-router'

import CountersDashboard from './CountersDashboard.vue'
import ChartsDashboard from './ChartsDashboard.vue'
import HeatmapDashboard from './HeatmapDashboard.vue'
import FixedWidgets from './FixedWidgets.vue'

import {DraggableResizableVue, DraggableResizableContainer } from 'draggable-resizable-vue3'

import { useCameraStreamStore } from '@/stores/CameraStreamStore'
import { useFlowStore } from '@/stores/FlowStore'
import { useDetectionZoneStore } from '@/stores/DetectionZone'
import { useUserStore } from '@/stores/UserStore'
import { useCompanyStore } from '@/stores/CompanyStore'

import {getCountersByDuration, getCountersByDateRange, getCountersListByDayByDuration, getcountersListByDayByDateRange, getCountersListByHourByDuration, getcountersListByHourByDateRange,  getHeatmapByHourByDuration, getHeatmapByHourByDateRange, getFixedDashboardCountersValues, sendMessageToAgent} from "@/api/eventsAPI.js"

import DurationButtons from './DurationButtons.vue'
import DatePicker from './DatePicker.vue'
import DataGranularity from './DataGranularityRadioButton.vue'

import { detection_zone_colors_by_name_solid } from '@/constants.js'

export default defineComponent({
    name: 'Dashboard',

    components: {
        DraggableResizableContainer,
        DraggableResizableVue,
        CountersDashboard,
        ChartsDashboard,
        DurationButtons,
        DatePicker,
        DataGranularity,
        HeatmapDashboard,
        FixedWidgets,

    },

    setup() {
        const router = useRouter()
        
        const CameraStreamStore = useCameraStreamStore()
        const FlowStore = useFlowStore()
        const CompanyStore = useCompanyStore()
        const detectionZoneStore = useDetectionZoneStore()
        const userStore = useUserStore()
        const storedState = JSON.parse(localStorage.getItem(`dashboardLayout_${userStore.user.UserId}`)) || {}

        const screenWidth = ref(window.innerWidth)

        const widgets = ref([])
        const refs = reactive({})
        const gridContainer = ref(null)
        const gridHeight = ref(0)
        const gridKey = ref(0)

        const data = ref({})

        let fetchIntervalDashboardData = null
        let isFetchingFixedData = false
        let isFetchingWidgetData = false
        let isMounted = ref(true)
        const controller = new AbortController()

        const dateRangeMode = ref(storedState?.dateRange?.startDate ? 'dateRange' : 'duration')
        const clearDatePicker = ref(null)
        const startDate = ref(storedState?.dateRange?.startDate ? new Date(storedState.dateRange.startDate) : null)
        const endDate = ref(storedState?.dateRange?.endDate ? new Date(storedState.dateRange.endDate) : null)
        const dataGranularity = ref(storedState?.dataGranularity || 'daily')
        const duration = ref(storedState?.duration || 1)

        const zoneAverageTime = {}
        const zoneTotalCounts = {}
        const storeTotalCounts = {}
        const flowNames = {}
        const chartDataCountsInOut = ref({})
        const chartDataAverageInteractionTime = ref({})
        const heatmapDataStore = ref({})

        const fixedWidgetsData = ref({})
        const filteredFlowIdsWithEntranceZones = ref()
        const filteredFlowIdsWithProductZones = ref()

        const layoutRestored = ref(false)
        const isRestoringData = ref(true)
        const editMode = ref(false)
        const allWidgetsPresent = ref(false)
        const filteredWidgetOptions = ref([])
        const showAddWidgetModal = ref(false)
        const selectedWidgetType = ref(null)
        const availableZones = ref([])
        const selectedZones = ref([])
        const removedWidgetIds = ref(JSON.parse(localStorage.getItem(`removedWidgets_${userStore.user.UserId}`)) || [])
        const initialLayout = ref([])  // To store the initial layout
        const hasChanges = ref(false)  // To track if changes have been made
        const initialWidgetState = ref([])
        const initialRemovedWidgetIds = ref([])

        const isUpdatingOptions = ref(false)

        const isLoading = ref(false)
        const isLoadingPage = ref(false)
        const isLoadingFilterWidgetOptions = ref(false)
        const isLoadingAvailableZones = ref(false)
        const isAddingWidget = ref(false)
        const isInitialLoad = ref(true)

        const isSidebarOpen = ref(false)
        const messages = ref([])
        const userInput = ref('')

        const durationText = computed(() => {
            if (dateRangeMode.value === 'duration') {
                return duration.value === 1 ? 'Today' : `Last ${duration.value} Days`
            } else if (dateRangeMode.value === 'dateRange') {
                const startDateFormatted = new Date(startDate.value).toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' })
                const endDateFormatted = new Date(endDate.value).toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' })
                return `From ${startDateFormatted} To ${endDateFormatted}`
            }
        })

        const isSmartphoneWidth = computed(() => screenWidth.value <= 1200);

        const generateWidgetId = (type, flowId, zone = '') => {
            return `${type}-${String(flowId)}-${zone}`
        }

        /*----------------------------------------- Block: Page Loading and Setup ------------------------------------------*/

        const getCurrentLayoutWidgets = () => {
            const storedState = JSON.parse(localStorage.getItem(`dashboardLayout_${userStore.user.UserId}`)) || {}
            const currentWidgets = storedState.layout?.filter(widget => !removedWidgetIds.value.includes(widget.id)) || []
            return currentWidgets
        }

        onMounted(async () => {
            isMounted.value = true
            isLoading.value = true

            isLoadingPage.value = true

            if (localStorage.getItem(`dashboardLayout_${userStore.user.UserId}`) !== null) {
                isInitialLoad.value = false
            } else {
                isInitialLoad.value = true
            }

            // Fetch user details to get the current user's CompanyId
            await userStore.fetchUserDetails()
            if (userStore.user.CompanyId) {
                await CompanyStore.fetchCompanyDetails(userStore.user.CompanyId)
            }

            // Fetch detection zones and check if empty => empty = or no flows are added (fresh new user) or no detection zones are configured
            await FlowStore.fetchFlows()
            if (FlowStore.flows.length === 0) {
                // Redirect to /camera_overview if no detection zones are found
                router.push('/camera_overview')
                //isLoading.value = false
                return
            }
            isLoading.value = false

            // await FlowStore.fetchFlows()

            // Fetch the initial data required for widget generation
            await CameraStreamStore.fetchInputCameraStreams()

            // Add window resize event listener
            window.addEventListener('resize', handleResize)
            adjustGridWidthToFitSavedLayout()
            
            // Map FlowId to CompanyId using the flows data
            const flowCompanyMap = new Map(FlowStore.flows.map(flow => [flow.FlowId, flow.CompanyId]))

            // Filter detection zones by DetectionZoneTypeId and CompanyId for getting the flows with entrance zones
            filteredFlowIdsWithEntranceZones.value = [...new Set(
                    detectionZoneStore.detectionzones
                        .filter(zone => {
                            const companyId = flowCompanyMap.get(zone.FlowId)
                            return (zone.DetectionZoneTypeId === 1 || zone.DetectionZoneTypeId === 3) && companyId === userStore.user.CompanyId
                        })
                        .map(zone => zone.FlowId)
                )]
            
            // // Filter detection zones by DetectionZoneTypeId and CompanyId for getting the flows with entrance zones
            filteredFlowIdsWithProductZones.value = [...new Set(
                    detectionZoneStore.detectionzones
                        .filter(zone => {
                            const companyId = flowCompanyMap.get(zone.FlowId)
                            return zone.DetectionZoneTypeId === 6 && companyId === userStore.user.CompanyId
                        })
                        .map(zone => zone.FlowId)
                )]
            
            // Fetch Fixed Widget Data
            await fetchFixedWidgetData()

            if (!isSmartphoneWidth.value) {
                // Restore the widget layout if there is saved data
                layoutRestored.value = restoreDashboardState(data.value)
                // Fetch only data related to widgets in the current layout
                const currentWidgets = getCurrentLayoutWidgets() // Filter widgets based on layout
                data.value = await fetchDataForCurrentWidgets(currentWidgets)
                
                if (!isMounted.value) return
                
                // Only generate widgets if no widgets are restored from localStorage
                if (!layoutRestored.value) {
                    console.log("No widgets restored from localStorage, generating new widgets...")
                    await generateWidgetsFromScratch()  // Ensure initial widget generation
                } else {
                    console.log("Widgets restored from localStorage, updating existing widgets...")
                    updateWidgetValues(data.value) // Only update existing widgets
                    adjustGridWidthToFitSavedLayout() // Adjust grid width to fit the restored layout
                }

                await updateWidgetOptions()
                
                allWidgetsPresent.value = await areAllWidgetsPresent() // Ensure this check is done after widgets are added
            }
            
            isLoadingPage.value = false

            // Start the consolidated data fetch for periodic updates
            startConsolidatedDataFetch()
        })

        onUnmounted(() => {
            isMounted.value = false

            window.removeEventListener('resize', handleResize)
            
            if (fetchIntervalDashboardData) {
                clearInterval(fetchIntervalDashboardData)
                fetchIntervalDashboardData = null
            }
            controller.abort()
        })

        const startConsolidatedDataFetch = () => {
            clearInterval(fetchIntervalDashboardData)

            // Reset the AbortController for new requests
            // controller.abort() // Abort any ongoing requests
            // controller = new AbortController() // Create a new instance for fresh requests

            fetchIntervalDashboardData = setInterval(async () => {
                if (!isMounted.value) return // Skip if unmounted or already fetching

                try {
                    // Trigger `fetchFixedWidgetData` only if not already fetching
                    if (!isFetchingFixedData) {
                        await fetchFixedWidgetData()
                    }

                    // Trigger `fetchAndGenerateWidgets` only if not already fetching
                    if (!isSmartphoneWidth.value && !isFetchingWidgetData) {
                        await fetchAndGenerateWidgets()
                    }
                } catch (error) {
                    console.error("Error during consolidated data fetch:", error)
                }
            }, 10000) // Set interval to 10 seconds or any desired time
        }

        watch(() => dateRangeMode.value, async (newValue) => {
            if (newValue) {
                console.log('Changed dateRangeMode to: ', newValue)
                try {
                    isFetchingWidgetData = false
                    isLoadingPage.value = true
                    await fetchAndGenerateWidgets() // Ensure this is called
                    saveDashboardState()
                } catch (error) {
                    console.error('Error updating widgets:', error)
                } finally {
                    isLoadingPage.value = false; // Reset in finally block
                }
            }
        })

        watch(() => duration.value, async (newValue) => {
            if (newValue) {
                console.log('Changed Duration to: ', newValue)
                try {
                    isFetchingWidgetData = false
                    isLoadingPage.value = true
                    await fetchAndGenerateWidgets() // Ensure this is called
                    saveDashboardState()
                } catch (error) {
                    console.error('Error updating widgets:', error)
                } finally {
                    isLoadingPage.value = false; // Reset in finally block
                }
            }
        })

        watch([startDate, endDate], async (newValues, prevValues) => {
            if (newValues.every(value => value !== null)) { // Both dates are selected
                console.log('Changed Start Date to:', newValues[0], 'and End Date to:', newValues[1]);  
                try {
                    isFetchingWidgetData = false
                    isLoadingPage.value = true
                    await fetchAndGenerateWidgets()
                    saveDashboardState()
                } catch (error) {
                    console.error('Error updating widgets:', error)
                } finally {
                    isLoadingPage.value = false // Reset in finally block
                }
            }
        })

        watch(() => dataGranularity.value, async (newValue) => {
            if (newValue) {
                isFetchingWidgetData = false
                console.log('Changed dataGranularity to: ', newValue)
                try {
                    isLoadingPage.value = true
                    await fetchAndGenerateWidgets() // Ensure this is called
                    saveDashboardState()
                } catch (error) {
                    console.error('Error updating widgets:', error)
                } finally {
                    isLoadingPage.value = false; // Reset in finally block
                }
            }
        })

        // Watch for changes in editMode to increment gridKey
        watch(editMode, (newMode, oldMode) => {
            if (newMode !== oldMode) {
                gridKey.value++ // Increment the key to reload the grid when switching modes
            }
        })

        watch(isSmartphoneWidth, (isSmall) => {
            const handleWidthChange = async () => {
                if (isSmall) {
                    // Stop fetching and hide dynamic widgets
                    clearInterval(fetchIntervalDashboardData)
                } else {
                    // Resume fetching and show dynamic widgets
                    try {
                        isLoadingPage.value = true

                        // Restore the widget layout if there is saved data
                        layoutRestored.value = restoreDashboardState(data.value)

                        // Fetch only data related to widgets in the current layout
                        const currentWidgets = getCurrentLayoutWidgets(); // Filter widgets based on layout
                        data.value = await fetchDataForCurrentWidgets(currentWidgets)

                        if (!isMounted.value) return

                        // Only generate widgets if no widgets are restored from localStorage
                        if (!layoutRestored.value) {
                            console.log("No widgets restored from localStorage, generating new widgets...")
                            await generateWidgetsFromScratch(); // Ensure initial widget generation
                        } else {
                            console.log("Widgets restored from localStorage, updating existing widgets...")
                            updateWidgetValues(data.value) // Only update existing widgets
                            adjustGridWidthToFitSavedLayout() // Adjust grid width to fit the restored layout
                        }

                        await updateWidgetOptions()

                        allWidgetsPresent.value = await areAllWidgetsPresent(); // Ensure this check is done after widgets are added
                        isLoadingPage.value = false
                        startConsolidatedDataFetch()
                    } catch (error) {
                        console.error("Error while handling layout restoration or widget fetching:", error)
                    }
                }
            }

            handleWidthChange(); // Call the async function
        })

        /*----------------------------------------- End of Page Loading and Setup Block -------------------------------------*/


        /*----------------------------------------- Block: Data Fetching ------------------------------------------*/

        const fetchFixedWidgetData = async () => {
            if (!isMounted.value || isFetchingFixedData) return // Early exit if unmounted or already fetching

            isFetchingFixedData = true

            if (fetchIntervalDashboardData) {
                clearInterval(fetchIntervalDashboardData) // Clear the interval to prevent overlap
            }

            try {
                // Get the stored data from localStorage
                const storedData = JSON.parse(localStorage.getItem('FixedWidgetsData'))
                console.log('Fetched storedData: ', storedData)
                const now = new Date()
                const currentDate = `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(2, '0')}-${String(now.getDate()).padStart(2, '0')}`
                console.log('currentDate:', currentDate)

                console.log('if/else storedData && storedData.date === currentDate: ', storedData && storedData.date === currentDate)
                // If there is stored data and the date is the same as today
                if (storedData && storedData.date === currentDate) {
                    console.log('Data is already stored and up to date, fetching only todays vs 30 days average...')

                    // Assign all values except for `result` to fixedWidgetsData immediately
                    fixedWidgetsData.value = {
                        today_weekday: storedData.today_weekday,
                        average_same_weekday_past_30_days: storedData.average_same_weekday_past_30_days,
                        average_past_30_days: storedData.average_past_30_days,
                        highest_total_hour: storedData.highest_total_hour,
                        highest_total_hour_count: storedData.highest_total_hour_count,
                        highest_total_day: storedData.highest_total_day,
                        highest_total_day_count: storedData.highest_total_day_count,
                        busiest_hour_past_30_days: storedData.busiest_hour_past_30_days,
                        highest_average_hourly_count: storedData.highest_average_hourly_count,
                        trend_past_30_days: storedData.trend_past_30_days,
                        
                        best_product_zone_by_visitors: storedData.best_product_zone_by_visitors,
                        best_product_zone_by_avg_duration: storedData.best_product_zone_by_avg_duration,
                        worst_product_zone_by_visitors: storedData.worst_product_zone_by_visitors,
                        total_duration_per_product_zone: storedData.total_duration_per_product_zone,
                        percentage_by_duration_range_in_product_zone: storedData.percentage_by_duration_range_in_product_zone,

                        best_demo_zone_by_visitors: storedData.best_demo_zone_by_visitors,
                        best_demo_zone_by_avg_duration: storedData.best_demo_zone_by_avg_duration,
                        worst_demo_zone_by_visitors: storedData.worst_demo_zone_by_visitors,
                        total_duration_per_demo_zone: storedData.total_duration_per_demo_zone,
                        percentage_by_duration_range_in_demo_zone: storedData.percentage_by_duration_range_in_demo_zone,

                        best_display_zone_by_visitors: storedData.best_display_zone_by_visitors,
                        best_display_zone_by_avg_duration: storedData.best_display_zone_by_avg_duration,
                        worst_display_zone_by_visitors: storedData.worst_display_zone_by_visitors,
                        total_duration_per_display_zone: storedData.total_duration_per_display_zone,
                        percentage_by_duration_range_in_display_zone: storedData.percentage_by_duration_range_in_display_zone,
    
                        average_waiting_time: storedData.average_waiting_time,
                        max_waiting_duration: storedData.max_waiting_duration,
                        max_waiting_zone: storedData.max_waiting_zone,
                        most_typical_waiting_time: storedData.most_typical_waiting_time,
                        average_interaction_time: storedData.average_interaction_time,
                        max_interaction_duration: storedData.max_interaction_duration,
                        max_interaction_zone: storedData.max_interaction_zone,
                        most_typical_interaction_time: storedData.most_typical_interaction_time,
                        fastest_checkout_zone: storedData.fastest_checkout_zone,
                        slowest_checkout_zone: storedData.slowest_checkout_zone,
                        fastest_interaction_zone: storedData.fastest_interaction_zone,
                        slowest_interaction_zone: storedData.slowest_interaction_zone,
                        most_used_interaction_zone: storedData.most_used_interaction_zone,
                        least_used_interaction_zone: storedData.least_used_interaction_zone,
                        
                        date: storedData.date // Keep the date as well
                    }

                    const now = new Date()
                    const startDate = new Date(now)
                    startDate.setDate(now.getDate())

                    let total_entrance_zone_count = 0
                    let total_combined_entrance_exit_store_in_count = 0
                    let total_combined_entrance_exit_store_out_count = 0

                    // Loop through each flowId and make API calls
                    for (let flowId of filteredFlowIdsWithEntranceZones.value) {
                        try {
                            let result = await getCountersByDuration(flowId, 0, startDate, { signal: controller.signal })
                            const zone_counts = result.zone_counters
                            // Filter for zones with zone_type 'Entrance Zone' and sum their zone_in_count
                            for (let zone in zone_counts) {
                                if (zone_counts[zone].zone_type === 'Entrance Zone') {
                                    total_entrance_zone_count += zone_counts[zone].zone_in_count
                                }
                            }
                            const store_counts = result.combined_ee_store_counters
                            for (let zone in store_counts) {
                                if (store_counts[zone].zone_type === 'Combined Entrance Exit Zone') {
                                    total_combined_entrance_exit_store_in_count += store_counts[zone].zone_in_count
                                    total_combined_entrance_exit_store_out_count += store_counts[zone].zone_out_count
                                }
                            }
                        } catch (error) {
                            console.error(`Error fetching data for flowId ${flowId}:`, error)
                        }
                    }
                    
                    // Compare total count to average and set result
                    const todaysVs30DayAverage = (total_entrance_zone_count + total_combined_entrance_exit_store_in_count) > fixedWidgetsData.value.average_past_30_days
                    const todaysVs30DayAverageSameWeekday = (total_entrance_zone_count + total_combined_entrance_exit_store_in_count) > fixedWidgetsData.value.average_same_weekday_past_30_days
                    
                    // Update only the `result` field in fixedWidgetsData (the rest remains the same)
                    fixedWidgetsData.value = {
                        ...fixedWidgetsData.value,  // Keep the previously populated values
                        is_todays_visitors_vs_past_30_days: todaysVs30DayAverage,
                        is_today_above_same_weekday_average: todaysVs30DayAverageSameWeekday,
                        today_current_count: total_entrance_zone_count,
                        today_current_combined_count_store_in: total_combined_entrance_exit_store_in_count,
                        today_current_combined_count_store_out: total_combined_entrance_exit_store_out_count
                    }
                    
                    // Update localStorage with the new `result`
                    localStorage.setItem('FixedWidgetsData', JSON.stringify({
                        ...storedData, // Keep other values unchanged
                        is_todays_visitors_vs_past_30_days: todaysVs30DayAverage,
                        is_today_above_same_weekday_average: todaysVs30DayAverageSameWeekday,
                        today_current_count: total_entrance_zone_count,
                        today_current_combined_count_store_in: total_combined_entrance_exit_store_in_count,
                        today_current_combined_count_store_out: total_combined_entrance_exit_store_out_count
                    }))

                    console.log('Updated todays vs 30-day average')
                } 
                // If there is no stored data or the date is outdated
                else {
                    console.log('Data is not up to date, fetching full fixed widget data...')
                    const now = new Date()
                    const todayDate = new Date(now)
                    const startDate7DaysAgo = new Date(now)
                    const startDate30DaysAgo = new Date(now)
                    const endDateYesterday = new Date(now)
                    todayDate.setDate(now.getDate())
                    startDate7DaysAgo.setDate(now.getDate() - 7)
                    startDate30DaysAgo.setDate(now.getDate() - 30)
                    endDateYesterday.setDate(now.getDate() - 1)

                    const fullData = await getFixedDashboardCountersValues(filteredFlowIdsWithEntranceZones.value, filteredFlowIdsWithProductZones.value, todayDate, startDate7DaysAgo, startDate30DaysAgo, endDateYesterday, { signal: controller.signal })

                    // Store all required values in localStorage
                    const newStoredData = {
                        today_weekday: fullData.today_weekday,
                        average_same_weekday_past_30_days: Math.round(fullData.average_same_weekday_past_30_days),
                        average_past_30_days: Math.round(fullData.average_past_30_days),
                        highest_total_hour: fullData.highest_total_hour,
                        highest_total_hour_count: fullData.highest_total_hour_count,
                        highest_total_day: fullData.highest_total_day,
                        highest_total_day_count: fullData.highest_total_day_count,
                        busiest_hour_past_30_days: fullData.busiest_hour_past_30_days,
                        highest_average_hourly_count: Math.round(fullData.highest_average_hourly_count),
                        trend_past_30_days: fullData.trend_past_30_days,
                        
                        best_product_zone_by_visitors: fullData.best_product_zone_by_visitors,
                        best_product_zone_by_avg_duration: fullData.best_product_zone_by_avg_duration,
                        worst_product_zone_by_visitors: fullData.worst_product_zone_by_visitors,
                        total_duration_per_product_zone: fullData.total_duration_per_product_zone,
                        percentage_by_duration_range_in_product_zone: fullData.percentage_by_duration_range_in_product_zone,

                        best_demo_zone_by_visitors: fullData.best_demo_zone_by_visitors,
                        best_demo_zone_by_avg_duration: fullData.best_demo_zone_by_avg_duration,
                        worst_demo_zone_by_visitors: fullData.worst_demo_zone_by_visitors,
                        total_duration_per_demo_zone: fullData.total_duration_per_demo_zone,
                        percentage_by_duration_range_in_demo_zone: fullData.percentage_by_duration_range_in_demo_zone,

                        best_display_zone_by_visitors: fullData.best_display_zone_by_visitors,
                        best_display_zone_by_avg_duration: fullData.best_display_zone_by_avg_duration,
                        worst_display_zone_by_visitors: fullData.worst_display_zone_by_visitors,
                        total_duration_per_display_zone: fullData.total_duration_per_display_zone,
                        percentage_by_duration_range_in_display_zone: fullData.percentage_by_duration_range_in_display_zone,

                        average_waiting_time: fullData.average_waiting_time,
                        max_waiting_duration: fullData.max_waiting_duration,
                        max_waiting_zone: fullData.max_waiting_zone,
                        most_typical_waiting_time: fullData.most_typical_waiting_time,
                        average_interaction_time: fullData.average_interaction_time,
                        max_interaction_duration: fullData.max_interaction_duration,
                        max_interaction_zone: fullData.max_interaction_zone,
                        most_typical_interaction_time: fullData.most_typical_interaction_time,
                        fastest_checkout_zone: fullData.fastest_checkout_zone,
                        slowest_checkout_zone: fullData.slowest_checkout_zone,
                        fastest_interaction_zone: fullData.fastest_interaction_zone,
                        slowest_interaction_zone: fullData.slowest_interaction_zone,
                        most_used_interaction_zone: fullData.most_used_interaction_zone,
                        least_used_interaction_zone: fullData.least_used_interaction_zone,
                        
                        date: currentDate // Add today's date to track when the data was fetched
                    }

                    let total_entrance_zone_count = 0
                    let total_combined_entrance_exit_store_in_count = 0
                    let total_combined_entrance_exit_store_out_count = 0
                    
                    const startDate = new Date(now)
                    startDate.setDate(now.getDate())
                    
                    // Loop through each flowId and make API calls
                    for (let flowId of filteredFlowIdsWithEntranceZones.value) {
                        try {
                            let result = await getCountersByDuration(flowId, 0, startDate, { signal: controller.signal })
                            const zone_counts = result.zone_counters
                            // Filter for zones with zone_type 'Entrance Zone' and sum their zone_in_count
                            for (let zone in zone_counts) {
                                if (zone_counts[zone].zone_type === 'Entrance Zone') {
                                    total_entrance_zone_count += zone_counts[zone].zone_in_count
                                }
                            }
                            const store_counts = result.combined_ee_store_counters
                            for (let zone in store_counts) {
                                if (store_counts[zone].zone_type === 'Combined Entrance Exit Zone') {
                                    total_combined_entrance_exit_store_in_count += store_counts[zone].zone_in_count
                                    total_combined_entrance_exit_store_out_count += store_counts[zone].zone_out_count
                                }
                            }
                        } catch (error) {
                            console.error(`Error fetching data for flowId ${flowId}:`, error)
                        }
                    }
                    
                    // Compare total count to average and set result
                    const todaysVs30DayAverage = (total_entrance_zone_count + total_combined_entrance_exit_store_in_count) > fixedWidgetsData.value.average_past_30_days
                    const todaysVs30DayAverageSameWeekday = (total_entrance_zone_count + total_combined_entrance_exit_store_in_count) > fixedWidgetsData.value.average_same_weekday_past_30_days
                    
                    // Update only the `result` field in fixedWidgetsData (the rest remains the same)
                    newStoredData.is_todays_visitors_vs_past_30_days = todaysVs30DayAverage;
                    newStoredData.is_today_above_same_weekday_average = todaysVs30DayAverageSameWeekday;
                    newStoredData.today_current_count = total_entrance_zone_count;
                    newStoredData.today_current_combined_count_store_in = total_combined_entrance_exit_store_in_count;
                    newStoredData.today_current_combined_count_store_out = total_combined_entrance_exit_store_out_count;

                    // Store the new data in localStorage
                    localStorage.setItem('FixedWidgetsData', JSON.stringify(newStoredData))

                    // Update the reactive fixedWidgetsData with the new full data
                    fixedWidgetsData.value = newStoredData

                    console.log('Updated Fixed Widgets Data and stored in localStorage:', newStoredData)
                }

            } catch (error) {
                console.error('Error fetching fixed widget data:', error)
            } finally {
                isFetchingFixedData = false;
            }
        }
        
        const fetchDataForCurrentWidgets = async (widgets) => {
            if (controller.signal.aborted) {
                return
            }

            // Set to store unique flowIds
            let uniqueFlowIds = new Set()

            // If no widgets are passed (initial load), fetch data for all flows
            if (widgets.length === 0) {
                const storedState = JSON.parse(localStorage.getItem(`dashboardLayout_${userStore.user.UserId}`)) || {}
                uniqueFlowIds = new Set(storedState.layout?.map(widget => String(widget.flowId)) || [])

                // Check if there are any heatmap widgets in the stored layout
                // const hasHeatmapWidgets = storedState.layout?.some(widget => widget.type === 'heatmap_entrance')

                let flowsToFetch = uniqueFlowIds.size > 0
                    ? Array.from(uniqueFlowIds).map(flowId => String(flowId)) // Normalize to strings
                    : CameraStreamStore.inputcamerastreams.map(stream => String(stream.FlowId)) // Fetch data for all flows if no stored flow ids

                flowsToFetch = Array.from(new Set(flowsToFetch)) // Deduplicate flows

                await Promise.all(flowsToFetch.map(async (flowId) => {
                    if (!isMounted.value) return

                    // Retrieve flowDashboardData from localStorage, or initialize it as an empty object if it doesn’t exist
                    let flowDashboardData = JSON.parse(localStorage.getItem('flowDashboardData')) || {}

                    // Check if flow data for the specific flowId is missing and needs to be fetched
                    if (!flowDashboardData[flowId]) {
                        await FlowStore.fetchDashboardFlow(flowId)
                        // Refresh flowDashboardData after the fetch to ensure the new data is included
                        flowDashboardData = JSON.parse(localStorage.getItem('flowDashboardData'))
                    }

                    flowNames[flowId] = flowDashboardData[flowId].FlowName

                    await initializeZoneCounts(flowId, zoneAverageTime, zoneTotalCounts, storeTotalCounts)

                    // Ensure both flowId and filteredFlowIdsWithEntranceZones are compared as strings
                    const stringFlowId = String(flowId)
                    const stringFilteredFlowIdsWithEntranceZones = filteredFlowIdsWithEntranceZones.value.map(id => String(id))

                    if (dateRangeMode.value === 'dateRange') {
                        await fetchCountersByDateRange(flowId, zoneAverageTime, zoneTotalCounts)

                        if (stringFilteredFlowIdsWithEntranceZones.includes(stringFlowId) || flowsToFetch.length === 0) {
                            await fetchHeatmapDataByDateRange(flowId)
                        }

                        if (dataGranularity.value === 'hourly') {
                            await fetchEventDataByHourByFlowByDateRange(flowId)
                        } else {
                            await fetchEventDataByDayByFlowByDateRange(flowId)
                        }
                    } else if (dateRangeMode.value === 'duration') {
                        await fetchCountersByDuration(flowId, zoneAverageTime, zoneTotalCounts)

                        if (stringFilteredFlowIdsWithEntranceZones.includes(stringFlowId) || flowsToFetch.length === 0) {
                            await fetchHeatmapDataByDuration(flowId)
                        }

                        if (dataGranularity.value === 'hourly') {
                            await fetchEventDataByHourByFlowByDuration(flowId)
                        } else {
                            await fetchEventDataByDayByFlowByDuration(flowId)
                        }
                    }
                }))

                const collectedData = {
                    zoneAverageTime,
                    zoneTotalCounts,
                    flowNames
                }

                return collectedData
            }

            widgets.forEach(widget => {
                if (widget.flowId) uniqueFlowIds.add(String(widget.flowId)) // Ensure flowId exists and is a string
            })

            const flowsToFetch = Array.from(uniqueFlowIds) // Deduplicate flowIds for fetching
            const storedState = JSON.parse(localStorage.getItem(`dashboardLayout_${userStore.user.UserId}`)) || {}
            const hasHeatmapWidgets = storedState.layout?.some(widget => widget.type === 'heatmap_entrance')

            console.log("Start Data Fetching for flows: ", flowsToFetch)

            await Promise.all(flowsToFetch.map(async (flowId) => {
                if (!isMounted.value) return

                // Retrieve flowDashboardData from localStorage, or initialize it as an empty object if it doesn’t exist
                let flowDashboardData = JSON.parse(localStorage.getItem('flowDashboardData')) || {}

                // Check if flow data for the specific flowId is missing and needs to be fetched
                if (!flowDashboardData[flowId]) {
                    await FlowStore.fetchDashboardFlow(flowId)
                    // Refresh flowDashboardData after the fetch to ensure the new data is included
                    flowDashboardData = JSON.parse(localStorage.getItem('flowDashboardData'))
                }

                flowNames[flowId] = flowDashboardData[flowId].FlowName

                await initializeZoneCounts(flowId, zoneAverageTime, zoneTotalCounts, storeTotalCounts)

                if (dateRangeMode.value === 'dateRange') {
                    await fetchCountersByDateRange(flowId, zoneAverageTime, zoneTotalCounts)
                    console.log("Fetched Counters By Date Range of flow: ", flowId)

                    if (hasHeatmapWidgets || flowsToFetch.length === 0) {
                        await fetchHeatmapDataByDateRange(flowId)
                        console.log("Fetched Heatmap Data By Date Range of flow: ", flowId)
                    }

                    if (dataGranularity.value === 'hourly') {
                        await fetchEventDataByHourByFlowByDateRange(flowId)
                        console.log("Fetched Event Data By Hour By Flow By Date Range of flow: ", flowId)
                    } else {
                        await fetchEventDataByDayByFlowByDateRange(flowId)
                        console.log("Fetched Event Data By Day By Flow By Date Range of flow: ", flowId)
                    }
                } else if (dateRangeMode.value === 'duration') {
                    await fetchCountersByDuration(flowId, zoneAverageTime, zoneTotalCounts)
                    console.log("Fetched Counters By Duration of flow: ", flowId)

                    if (hasHeatmapWidgets || flowsToFetch.length === 0) {
                        await fetchHeatmapDataByDuration(flowId)
                        console.log("Fetched Heatmap Data By Duration of flow: ", flowId)
                    }

                    if (dataGranularity.value === 'hourly') {
                        await fetchEventDataByHourByFlowByDuration(flowId)
                        console.log("Fetched Event Data By Hour By Flow By Duration of flow: ", flowId)
                    } else {
                        await fetchEventDataByDayByFlowByDuration(flowId)
                        console.log("Fetched Event Data By Day By Flow By Duration of flow: ", flowId)
                    }
                }
                console.log("Done Fetching Data for flow: ", flowId)
            }))

            const collectedData = {
                zoneAverageTime,
                zoneTotalCounts,
                flowNames
            }

            return collectedData
        }

        const fetchAndGenerateWidgets = async () => {
            if (!isMounted.value || isFetchingWidgetData) return // Early exit if unmounted or already fetching

            isFetchingWidgetData = true

            if (fetchIntervalDashboardData) {
                clearInterval(fetchIntervalDashboardData) // Clear the interval to prevent overlap
            }

            try {
                const currentWidgets = getCurrentLayoutWidgets() // Get only the currently active widgets
                
                // Store the old data for comparison
                const oldData = JSON.parse(JSON.stringify(data.value)) // Deep copy the old data

                // Fetch new data
                data.value = await fetchDataForCurrentWidgets(currentWidgets)
                
                // If the component is unmounted after the fetch, exit early
                if (!isMounted.value) return

                // Compare old and new data to check if any changes have occurred
                if (JSON.stringify(oldData) !== JSON.stringify(data.value)) {
                    // Data has changed, update or generate widgets
                    if (widgets.value.length > 0) {
                        updateWidgetValues(data.value)
                    } else {
                        generateWidgetsForCurrentLayout(data.value, currentWidgets)
                    }
                }

                // Log the width of the grid container
                nextTick(() => {
                    if (gridContainer.value) {
                        const gridWidth = gridContainer.value.$el.clientWidth
                        //console.log('gridWidth:', gridWidth)
                    }
                })

                // Start the interval again only after widgets are generated or updated
                startConsolidatedDataFetch()
            } catch (error) {
                console.error("Error in fetchAndGenerateWidgets:", error)
            } finally {
                isFetchingWidgetData = false
            }
        }

        const generateWidgetsFromScratch = async () => {
            generateWidgetsOnInitialLoad(data.value) // Generate widgets from scratch
            startConsolidatedDataFetch() // Start fetching new data at regular intervals
        }

        /*----------------------------------------- End of Data Fetching Block ------------------------------------------*/


        /*----------------------------------------- Block: Widget Management ------------------------------------------*/

        const generateWidgetsOnInitialLoad = (data) => {
            const restoredWidgets = JSON.parse(localStorage.getItem(`dashboardLayout_${userStore.user.UserId}`)) || []
            const existingWidgetIds = new Set(restoredWidgets.map(widget => widget.id))
            
            // Ensure widgets restored from localStorage are added to existingWidgetIds
            widgets.value.forEach(widget => existingWidgetIds.add(widget.id))
            
            const widgetsArray = []
            const widgetWidth = 220
            const widgetHeight = 120
            const margin = 20
            const gridWidth = gridContainer.value.$el.clientWidth - margin
            let x = margin
            let y = margin

            // Loop through the data and add widgets for each type (counter, chart, heatmap)
            const addWidgetToGrid = (widgetConfig) => {
                // Skip adding the widget if it was removed in a previous session
                if (removedWidgetIds.value.includes(widgetConfig.id)) {
                    console.log(`Skipping widget ${widgetConfig.id} as it was previously removed.`)
                    return
                }

                // If the widget width is larger than the available grid width, cap it
                if (widgetConfig.width > gridWidth) {
                    widgetConfig.width = gridWidth - 2 * margin
                }
                
                // If the widget exceeds the grid width, move it to the next line
                if (x + widgetConfig.width > gridWidth) {
                    x = margin
                    y += widgetConfig.height + margin
                }

                widgetConfig.x = x
                widgetConfig.y = y

                x += widgetConfig.width + margin
                
                widgetsArray.push(widgetConfig)
                
            }

            // Generate Counter Widgets
            for (let zone in zoneTotalCounts) {
                const flowId = zoneTotalCounts[zone].flowId
                const flowName = flowNames[flowId]
                console.log('zoneTotalCounts: ', zoneTotalCounts)
                console.log('storeTotalCounts: ', storeTotalCounts)

                const widgetIdZoneIn = generateWidgetId('counter_zone_in', flowId, zone)
                const widgetIdAvgDuration = generateWidgetId('counter_avg_duration', flowId, zone)

                if (zoneTotalCounts[zone].zone_type === 'Combined Entrance Exit Zone') {
                    addWidgetToGrid({
                        id: generateWidgetId('combined_zone', flowId, zone),
                        width: widgetWidth,
                        height: widgetHeight,
                        isActive: false,
                        title: `${flowName} - Combined Zone`,
                        type: 'combined_zone',
                        component: markRaw(CountersDashboard),
                        props: {
                            zone: zone,
                            type: 'combined_zone',
                            inValue: storeTotalCounts[zone]?.zone_in_count || 0,
                            outValue: storeTotalCounts[zone]?.zone_out_count || 0,
                            zoneType: zoneTotalCounts[zone].zone_type,
                            durationText: durationText,
                            flowId: flowId
                        }
                    });
                }

                if (!existingWidgetIds.has(widgetIdZoneIn) || !removedWidgetIds.value.includes(widgetIdZoneIn)) {
                    addWidgetToGrid({
                        id: widgetIdZoneIn,
                        width: widgetWidth,
                        height: widgetHeight,
                        isActive: false,
                        title: `${flowName}`,
                        type: 'counter_zone_in',
                        component: markRaw(CountersDashboard),
                        props: {
                            zone: zone,
                            value: data.zoneTotalCounts[zone].zone_in,
                            zoneType: data.zoneTotalCounts[zone].zone_type,
                            type: 'zone_in',
                            durationText: durationText,
                            flowId: flowId
                        }
                    })
                    existingWidgetIds.add(widgetIdZoneIn)
                }

                if (!existingWidgetIds.has(widgetIdAvgDuration) || !removedWidgetIds.value.includes(widgetIdAvgDuration)) {
                    addWidgetToGrid({
                        id: widgetIdAvgDuration,
                        width: widgetWidth,
                        height: widgetHeight,
                        isActive: false,
                        title: `${flowName}`,
                        type: 'counter_avg_duration',
                        component: markRaw(CountersDashboard),
                        props: {
                            zone: zone,
                            value: data.zoneAverageTime[zone].average_duration,
                            zoneType: data.zoneAverageTime[zone].zone_type,
                            type: 'average_duration',
                            durationText: durationText,
                            flowId: flowId
                        }
                    })
                    existingWidgetIds.add(widgetIdAvgDuration)
                }
            }

            // Generate Chart Widgets
            for (let flowId in chartDataCountsInOut.value) {
                const flowName = data.flowNames[flowId]
                const chartWidth = widgetWidth * (680/220) + margin
                const chartHeight = widgetHeight * (240/120) + margin

                const widgetIdZoneInChart = generateWidgetId('chart_zone_in', flowId, 'chart_undefined')  // Generate ID for chart_zone_in
                const widgetIdAvgDurationChart = generateWidgetId('chart_avg_duration', flowId, 'chart_undefined') // Generate ID for chart_avg_duration

                if (!existingWidgetIds.has(widgetIdZoneInChart) || !removedWidgetIds.value.includes(widgetIdZoneInChart)) {
                    addWidgetToGrid({
                        id: widgetIdZoneInChart,
                        width: chartWidth,
                        height: chartHeight,
                        isActive: false,
                        title: `Total Zone In - ${flowName}`,
                        type: 'chart_zone_in',
                        component: markRaw(ChartsDashboard),
                        props: {
                            chartData: chartDataCountsInOut.value[flowId] || {},
                            chartOptions: chartOptionsCounts,
                            flowId: flowId
                        }
                    })
                    existingWidgetIds.add(widgetIdZoneInChart)
                }

                if (!existingWidgetIds.has(widgetIdAvgDurationChart) || !removedWidgetIds.value.includes(widgetIdAvgDurationChart)) {
                    addWidgetToGrid({
                        id: widgetIdAvgDurationChart,
                        width: chartWidth,
                        height: chartHeight,
                        isActive: false,
                        title: `Average Time In Zone - ${flowName}`,
                        type: 'chart_avg_duration',
                        component: markRaw(ChartsDashboard),
                        props: {
                            chartData: chartDataAverageInteractionTime.value[flowId] || {},
                            chartOptions: chartOptionsAverageTime,
                            flowId: flowId
                        }
                    })
                    existingWidgetIds.add(widgetIdAvgDurationChart)
                }
            }

            // Generate Heatmap Widgets
            for (let zone in heatmapDataStore.value) {
                const flowId = data.zoneTotalCounts[zone]?.flowId
                const flowName = data.flowNames[flowId]
                const heatmapWidth = widgetWidth * (680/220) + margin
                const heatmapHeight = widgetHeight * (380/120) + margin

                const widgetIdHeatmap = generateWidgetId('heatmap_entrance', flowId, zone) // Generate ID for heatmap_entrance

                if (!existingWidgetIds.has(widgetIdHeatmap) || !removedWidgetIds.value.includes(widgetIdHeatmap)) {
                    addWidgetToGrid({
                        id: widgetIdHeatmap,
                        width: heatmapWidth,
                        height: heatmapHeight,
                        isActive: false,
                        title: `Heatmap - ${flowName}: ${zone}`,
                        type: 'heatmap_entrance',
                        component: markRaw(HeatmapDashboard),
                        props: {
                            zone: zone,
                            heatmapData: heatmapDataStore.value[zone],
                            flowId: flowId
                        }
                    })
                    existingWidgetIds.add(widgetIdHeatmap)
                }
            }
            
            widgets.value = [...widgets.value, ...widgetsArray]
            
            console.log("Generated widgets on Initial Load:", widgets.value)
            
            updateGridHeight()

            // Save existingWidgetIds to localStorage
            localStorage.setItem(`existingWidgetIds_${userStore.user.UserId}`, JSON.stringify([...existingWidgetIds]))

            // Save the widget layout whenever the widgets are generated
            saveDashboardState()
        }
        
        const generateWidgetsForCurrentLayout = (data, currentWidgets) => {
            const existingWidgetIds = new Set(currentWidgets.map(widget => widget.id))
            
            const widgetsArray = []
            const widgetWidth = 220
            const widgetHeight = 120
            const margin = 20
            const gridWidth = gridContainer.value.$el.clientWidth - margin
            let x = margin
            let y = margin

            const addWidgetToGrid = (widgetConfig) => {
                // Skip adding the widget if it was removed in a previous session
                if (removedWidgetIds.value.includes(widgetConfig.id)) {
                    return  // Skips removed widgets
                }

                // If the widget width is larger than the available grid width, cap it
                if (widgetConfig.width > gridWidth) {
                    widgetConfig.width = gridWidth - 2 * margin
                }

                // If the widget exceeds the grid width, move it to the next line
                if (x + widgetConfig.width > gridWidth) {
                    x = margin
                    y += widgetConfig.height + margin
                }

                widgetConfig.x = x
                widgetConfig.y = y

                x += widgetConfig.width + margin

                widgetsArray.push(widgetConfig)
            }

            currentWidgets.forEach(savedWidget => {
                // Ensure we're only adding widgets that are not already added and are part of the current layout
                if (!existingWidgetIds.has(savedWidget.id)) {
                    // Dynamically generate widget based on type
                    switch (savedWidget.type) {
                        case 'counter_zone_in':
                        case 'counter_avg_duration':
                            addWidgetToGrid({
                                id: savedWidget.id,
                                width: widgetWidth,
                                height: widgetHeight,
                                isActive: false,
                                title: `${savedWidget.flowName}`,
                                type: savedWidget.type,
                                component: markRaw(CountersDashboard),
                                props: {
                                    zone: savedWidget.zone,
                                    value: data.zoneTotalCounts[savedWidget.zone]?.zone_in || 0,
                                    zoneType: data.zoneTotalCounts[savedWidget.zone]?.zone_type,
                                    type: savedWidget.type === 'counter_zone_in' ? 'zone_in' : 'average_duration',
                                    durationText: durationText,
                                    flowId: savedWidget.flowId
                                }
                            })
                            break
                        
                        case 'combined_zone':
                            addWidgetToGrid({
                                id: savedWidget.id,
                                width: widgetWidth,
                                height: widgetHeight,
                                isActive: false,
                                title: `${savedWidget.flowName}`,
                                type: 'combined_zone',
                                component: markRaw(CountersDashboard),
                                props: {
                                    zone: savedWidget.zone,
                                    inValue: data.zoneTotalCounts[savedWidget.zone]?.zone_in_count || 0,
                                    outValue: data.zoneTotalCounts[savedWidget.zone]?.zone_out_count || 0,
                                    zoneType: data.zoneTotalCounts[savedWidget.zone]?.zone_type,
                                    durationText: durationText,
                                    flowId: savedWidget.flowId
                                }
                            });
                            break;

                        case 'chart_zone_in':
                        case 'chart_avg_duration':
                            addWidgetToGrid({
                                id: savedWidget.id,
                                width: widgetWidth * (680 / 220) + margin,
                                height: widgetHeight * (240 / 120) + margin,
                                isActive: false,
                                title: `${savedWidget.flowName}`,
                                type: savedWidget.type,
                                component: markRaw(ChartsDashboard),
                                props: {
                                    chartData: savedWidget.type === 'chart_zone_in' ? chartDataCountsInOut.value[savedWidget.flowId] : chartDataAverageInteractionTime.value[savedWidget.flowId],
                                    chartOptions: savedWidget.type === 'chart_zone_in' ? chartOptionsCounts : chartOptionsAverageTime,
                                    flowId: savedWidget.flowId
                                }
                            })
                            break

                        case 'heatmap_entrance':
                            addWidgetToGrid({
                                id: savedWidget.id,
                                width: widgetWidth * (680 / 220) + margin,
                                height: widgetHeight * (380 / 120) + margin,
                                isActive: false,
                                title: `Heatmap - ${savedWidget.flowName}: ${savedWidget.zone}`,
                                type: 'heatmap_entrance',
                                component: markRaw(HeatmapDashboard),
                                props: {
                                    zone: savedWidget.zone,
                                    heatmapData: heatmapDataStore.value[savedWidget.zone] || {},
                                    flowId: savedWidget.flowId
                                }
                            })
                            break

                        default:
                            console.warn(`Unsupported widget type: ${savedWidget.type}`)
                    }
                }
            })

            widgets.value = [...widgets.value, ...widgetsArray]  // Append generated widgets to the main widget array
            updateGridHeight()  // Adjust grid height based on new widget layout

            // Save widget IDs and layout to localStorage
            localStorage.setItem(`existingWidgetIds_${userStore.user.UserId}`, JSON.stringify([...existingWidgetIds]))
            saveDashboardState()
        }

        const updateWidgetValues = (data) => {
            widgets.value.forEach(widget => {
                if (widget.props.zoneType === 'Combined Entrance Exit Zone') {
                    const zoneData = storeTotalCounts[widget.props.zone] || {};
                    widget.props.inValue = zoneData.zone_in_count || 0;
                    widget.props.outValue = zoneData.zone_out_count || 0;
                }
                else if (widget.component.name === 'ValueDisplay') {
                    
                    // Update counter widgets
                    // const zoneDataTotalCounts = data.zoneTotalCounts[widget.props.zone]
                    // const zoneDataAverageTime = data.zoneAverageTime[widget.props.zone]
                    const zoneDataTotalCounts = zoneTotalCounts[widget.props.zone]
                    const zoneDataAverageTime = zoneAverageTime[widget.props.zone]

                    if (!zoneDataTotalCounts || !zoneDataAverageTime) {
                        return
                    }

                    if (widget.props.type === 'zone_in') {
                        widget.props.value = zoneDataTotalCounts.zone_in
                    } else if (widget.props.type === 'average_duration') {
                        widget.props.value = zoneDataAverageTime.average_duration
                    }

                    // Ensure durationText is updated
                    widget.props.durationText = durationText

                    //console.log(`Updated widget ID ${widget.id}:`, widget.props)

                } else if (widget.component.name === 'ChartsDashboard') {
                    
                    // Update chart widgets
                    const flowId = widget.props.flowId
                    
                    if (flowId) {
                        if (widget.title.includes("Total Zone In")) {
                            const chartData = chartDataCountsInOut.value[flowId] || { labels: [], datasets: [] }
                            widget.props.chartData = chartData
                        } else if (widget.title.includes("Average Time In Zone")) {
                            const chartData = chartDataAverageInteractionTime.value[flowId] || { labels: [], datasets: [] }
                            widget.props.chartData = chartData
                        }
                        //console.log(`Updated chart widget ID ${widget.id}:`, widget.props)
                    }
                } else if (widget.component.name === 'HeatmapDashboard') {
                    
                    // Update heatmap widgets
                    const zone = widget.props.zone
                    
                    if (zone && heatmapDataStore.value[zone]) {
                        widget.props.heatmapData = heatmapDataStore.value[zone]
                    }
                }
            })
            updateGridHeight()
        }

        const removeWidget = async (widgetId) => {
            const index = widgets.value.findIndex(widget => widget.id === widgetId)
            if (index !== -1) {
                removedWidgetIds.value.push(widgetId) // Track removed widget ID
                localStorage.setItem(`removedWidgets_${userStore.user.UserId}`, JSON.stringify(removedWidgetIds.value)) // Persist removed widget IDs
                
                widgets.value.splice(index, 1) // Remove the widget
                
                await nextTick() // Wait for the DOM to update
                
                // Save the widget layout after removing a new widget
                saveDashboardState()

                onWidgetChange()

                updateGridHeight()
            }

            await updateWidgetOptions()
            
            allWidgetsPresent.value = await areAllWidgetsPresent() // Ensure this check is done after a widget is removed
        }

        /*----------------------------------------- End of Widget Management Block ------------------------------------------*/


        /*----------------------------------------- Block: Modal Handling and Widget Addition ------------------------------------------*/

        const openAddWidgetModal = async () => {
            isLoadingFilterWidgetOptions.value = true
            selectedZones.value = [] // Clear previously selected zones
            filteredWidgetOptions.value = [] // Clear options to prevent stale data
            availableZones.value = [] // Clear available zones to prevent stale data

            showAddWidgetModal.value = true // Open the modal immediately

            setTimeout(async () => {
                await updateWidgetOptions() // Refresh the available widget options
            }, 0) // Defer updating to avoid blocking UI thread
        }

        const closeAddWidgetModal = () => {
            showAddWidgetModal.value = false
            selectedWidgetType.value = null // Reset the selected widget type
            selectedZones.value = [] // Clear selected zones when the modal is closed
            filteredWidgetOptions.value = [] // Clear widget options to prevent stale data
            availableZones.value = [] // Clear available zones to prevent stale data
        }

        const addSelectedWidgets = async () => {
            isAddingWidget.value = true

            if (!selectedZones.value.length) {
                // console.log('No zones selected')
                return
            }

            // const data = await fetchDataFromDB()  // Ensure correct data fetching
            const data = await fetchDataForCurrentWidgets(selectedZones.value.map(zone => ({ flowId: zone.flowId, zone: zone.zoneId })))

            selectedZones.value.forEach(zone => {
                // Directly access the properties
                const zoneId = zone?.zoneId
                const flowId = zone?.flowId

                if (selectedWidgetType.value.includes('chart')) {
                    addNewWidget(selectedWidgetType.value, null, flowId, data)
                } else {
                    addNewWidget(selectedWidgetType.value, zoneId, flowId, data)
                }
                // Save the widget layout after adding a new widget
                saveDashboardState()
            })

            await nextTick()  // Wait for the DOM to update before closing the modal
            
            isAddingWidget.value = false
            closeAddWidgetModal()

            // Update the widget options and check if all widgets are present
            await updateWidgetOptions()

            allWidgetsPresent.value = await areAllWidgetsPresent() // Ensure this check is done after widgets are added
        }

        const addNewWidget = async (category, detectionZone, flowId, data = null, x = null, y = null) => {
            flowId = String(flowId)
            
            // Retrieve flowDashboardData from localStorage, or initialize it as an empty object if it doesn’t exist
            let flowDashboardData = JSON.parse(localStorage.getItem('flowDashboardData')) || {};

            // Check if flow data for the specific flowId is missing and needs to be fetched
            if (!flowDashboardData[flowId]) {
                await FlowStore.fetchDashboardFlow(flowId);
                // Refresh flowDashboardData after the fetch to ensure the new data is included
                flowDashboardData = JSON.parse(localStorage.getItem('flowDashboardData'));
            }

            await fetchNewFlowData(flowId, category)

            let newWidget = {}
            const widgetWidth = 220
            const widgetHeight = 120
            const margin = 20

            // Determine initial x and y position
            let newX = x !== null ? x : margin * 2
            let newY = y !== null ? y : margin * 2
            
            //const flowName = data ? data.flowNames[flowId] || `Flow ${flowId}` : `Flow ${flowId}`
            //const flowName = flowNames[flowId] || `Flow ${flowId}`
            const flowName = flowDashboardData[flowId]?.FlowName || `Flow ${flowId}`
            const widgetDurationText  = durationText

            let newWidgetId = detectionZone 
                ? generateWidgetId(category, flowId, detectionZone)
                : generateWidgetId(category, flowId, 'chart_undefined')

            switch (category) {
                case 'combined_zone':
                    newWidget = {
                        id: newWidgetId,
                        x: newX,
                        y: newY,
                        width: widgetWidth,
                        height: widgetHeight,
                        isActive: editMode.value,
                        type: category,
                        title: `${flowName}`,
                        component: markRaw(CountersDashboard),
                        props: {
                            zone: detectionZone,
                            type: 'combined_zone',
                            inValue: storeTotalCounts[detectionZone]?.zone_in_count || 0,
                            outValue: storeTotalCounts[detectionZone]?.zone_out_count || 0,
                            zoneType: zoneTotalCounts[detectionZone]?.zone_type,
                            durationText: widgetDurationText,
                            flowId: flowId
                        }
                    };
                    break;

                case 'counter_zone_in':
                    newWidget = {
                        id: newWidgetId,
                        x: newX,
                        y: newY,
                        width: widgetWidth,
                        height: widgetHeight,
                        isActive: editMode.value,
                        type: category,
                        title: `${flowName}`,
                        component: markRaw(CountersDashboard),
                        props: {
                            zone: detectionZone,
                            //value: 0,  // initial value, should be updated after fetching data
                            value: zoneTotalCounts[detectionZone]?.zone_in || 0,  // Use the fetched value
                            //zoneType: data ? data.zoneTotalCounts[detectionZone]?.zone_type : null,
                            zoneType: zoneTotalCounts[detectionZone]?.zone_type,
                            type: 'zone_in',
                            durationText: widgetDurationText ,
                            flowId: flowId
                        }
                    }
                    break

                case 'counter_avg_duration':
                    newWidget = {
                        id: newWidgetId,
                        x: newX,
                        y: newY,
                        width: widgetWidth,
                        height: widgetHeight,
                        isActive: editMode.value,
                        type: category,
                        title: `${flowName}`,
                        component: markRaw(CountersDashboard),
                        props: {
                            zone: detectionZone,
                            //value: 0,  // initial value, should be updated after fetching data
                            value: zoneAverageTime[detectionZone]?.average_duration || 0,  // Use the fetched value
                            //zoneType: data ? data.zoneAverageTime[detectionZone]?.zone_type : null,
                            zoneType: zoneAverageTime[detectionZone]?.zone_type,
                            type: 'average_duration',
                            durationText: widgetDurationText ,
                            flowId: flowId
                        }
                    }
                    break

                case 'chart_zone_in':
                    newWidget = {
                        id: newWidgetId,
                        x: newX,
                        y: newY,
                        width: widgetWidth * (680/220) + 20,
                        height: widgetHeight * (240/120) + 20,
                        isActive: editMode.value,
                        type: category,
                        title: `Total Zone In - ${flowName}`,
                        component: markRaw(ChartsDashboard),
                        props: {
                            //chartData: { labels: [], datasets: [] }, // Placeholder until data is fetched
                            chartData: chartDataCountsInOut.value[flowId] || { labels: [], datasets: [] }, // Use fetched chart data
                            chartOptions: chartOptionsCounts,
                            flowId: flowId
                        }
                    }
                    break

                case 'chart_avg_duration':
                    newWidget = {
                        id: newWidgetId,
                        x: newX,
                        y: newY,
                        width: widgetWidth * (680/220) + 20,
                        height: widgetHeight * (240/120) + 20,
                        isActive: editMode.value,
                        type: category,
                        title: `Average Time In Zone - ${flowName}`,
                        component: markRaw(ChartsDashboard),
                        props: {
                            //chartData: { labels: [], datasets: [] }, // Placeholder until data is fetched
                            chartData: chartDataAverageInteractionTime.value[flowId] || { labels: [], datasets: [] }, // Use fetched chart data
                            chartOptions: chartOptionsAverageTime,
                            flowId: flowId
                        }
                    }
                    break

                case 'heatmap_entrance':
                    newWidget = {
                        id: newWidgetId,
                        x: newX,
                        y: newY,
                        width: widgetWidth * (680/220) + 20,
                        height: widgetHeight * (380/120) + 20,
                        isActive: editMode.value,
                        type: category,
                        title: `Heatmap - ${flowName}: ${detectionZone}`,
                        component: markRaw(HeatmapDashboard),
                        props: {
                            zone: detectionZone,
                            //heatmapData: {}, // Placeholder until data is fetched
                            heatmapData: heatmapDataStore.value[detectionZone] || {}, // Use fetched heatmap data
                            flowId: flowId
                        }
                    }
                    break

                default:
                    console.error('Unsupported widget category:', category)
                    return
            }

            //isAddingWidget.value = false
            //closeAddWidgetModal()

            // Remove widget ID from removedWidgetIds if it exists
            removedWidgetIds.value = removedWidgetIds.value.filter(id => id !== newWidgetId)
            localStorage.setItem(`removedWidgets_${userStore.user.UserId}`, JSON.stringify(removedWidgetIds.value))
            
            widgets.value.push(newWidget)

            updateWidgetValues(data)

            onWidgetChange()
            updateGridHeight()
            updateWidgetOptions()
        }

        /*----------------------------------------- End of Modal Handling and Widget Addition Block ------------------------------------------*/


        /*----------------------------------------- Block: Layout and Widget Resizing ------------------------------------------*/

        const handleDragging = (widgetId) => {
            onWidgetChange() // Mark that a change has occurred
            updateGridHeight() // Update the grid height after dragging

            // Save the widget layout after dragging
            saveDashboardState()
        }

        const onWidgetResize = (widgetId) => {
            const widget = widgets.value.find(w => w.id === widgetId)
            if (widget) {
                nextTick(() => {
                    const widgetRef = refs[`widget-${widgetId}`]

                    if (widgetRef) {
                        const componentInstance = refs[`component-${widgetId}`]

                        if (componentInstance && typeof componentInstance.resizeChart === 'function') {
                            componentInstance.resizeChart()
                        } else {
                            console.warn(`Could not resize widget: ${widgetId}`)
                        }
                    } else {
                        console.warn(`Widget reference not found: ${widgetId}`)
                    }
                })
            } else {
                console.warn(`Widget not found: ${widgetId}`)
            }
            onWidgetChange()

            // Save the widget layout after resizing
            saveDashboardState()
        }

        const updateGridHeight = () => {
            let maxHeight = 0
            
            widgets.value.forEach(widget => {
                const widgetBottom = widget.y + widget.height
                if (widgetBottom > maxHeight) {
                    maxHeight = widgetBottom
                }
            })
            
            if (editMode.value) {
                gridHeight.value = editMode.value ? Math.max(maxHeight + 25, 5000) : maxHeight + (5000 - maxHeight) // Adding grid space below the widgets
            }
            else {
                gridHeight.value = editMode.value ? Math.max(maxHeight + 25, 5000) : maxHeight + 25 // Adding some margin
            }
       
        }
        
        const adjustGridWidthToFitSavedLayout = () => {
            if (!gridContainer.value || !gridContainer.value.$el) {
                console.warn("Grid container is not available. Skipping adjustment.");
                return; // Exit early if gridContainer is not available
            }

            let maxX = 0
    
            // Calculate the maximum width needed based on the widgets' positions and sizes
            widgets.value.forEach(widget => {
                const widgetRightEdge = widget.x + widget.width
                if (widgetRightEdge > maxX) {
                    maxX = widgetRightEdge
                }
            })
            const cardBody = gridContainer.value.$el.parentNode
            const gridWidth = Math.max(maxX + 40, cardBody.clientWidth) // Ensure the gridWidth is at least the card body width

            gridContainer.value.$el.style.width = `${gridWidth}px`

            // Adjust the grid container width only if necessary
            if (gridWidth > cardBody.clientWidth) {
                cardBody.style.overflowX = 'auto' // Enable horizontal scrolling
                gridContainer.value.$el.style.width = `${gridWidth}px`
            } else {
                cardBody.style.overflowX = 'hidden' // Disable horizontal scrolling if not needed
                gridContainer.value.$el.style.width = '100%'
            }
        }

        const handleResize = () => {
            // Update grid layout
            if (!isSmartphoneWidth) {
                adjustGridWidthToFitSavedLayout()

                const cardBody = gridContainer.value.$el.parentNode
                if (gridContainer.value.$el.clientWidth <= cardBody.clientWidth) {
                    cardBody.style.overflowX = 'hidden' // Disable horizontal scroll if not needed
                }
            }
            // Update screen width
            screenWidth.value = window.innerWidth
        }

        /*----------------------------------------- End of Layout and Widget Resizing Block ------------------------------------------*/


        /*----------------------------------------- Block: Dashboard State and Options ------------------------------------------*/

        const saveDashboardState = () => {
            const layoutData = widgets.value.map(widget => ({
                id: widget.id,
                x: widget.x,
                y: widget.y,
                width: widget.width,
                height: widget.height,
                type: widget.type,
                flowId: String(widget.props.flowId),
                title: widget.title,
                zone: widget.props.zone,
                zoneType: widget.props.zoneType
            }))

            const dashboardState = {
                layout: layoutData,
                duration: duration.value,
                dateRangeMode: dateRangeMode.value,
                dateRange: {
                    startDate: startDate.value,
                    endDate: endDate.value,
                },
                dataGranularity: dataGranularity.value,
            }
            
            if (!isRestoringData.value) {  // Only save if restoration is complete
                localStorage.setItem(`dashboardLayout_${userStore.user.UserId}`, JSON.stringify(dashboardState))
            }
        }

        const restoreDashboardState = (data) => {
            isRestoringData.value  = true

            const storedState = JSON.parse(localStorage.getItem(`dashboardLayout_${userStore.user.UserId}`)) || {} // Ensure you're fetching the correct key
            
            // Check if storedState exists and has a valid layout array
            if (storedState && storedState.layout && Array.isArray(storedState.layout) && storedState.layout.length > 0) {
                const layoutData = storedState.layout

                // Restore widgets layout
                layoutData.forEach(savedWidget => {
                    const widget = widgets.value.find(w => w.id === savedWidget.id)
                    savedWidget.flowId = String(savedWidget.flowId)
                    if (widget) {
                        widget.x = savedWidget.x
                        widget.y = savedWidget.y
                        widget.width = savedWidget.width
                        widget.height = savedWidget.height
                    } else {
                        if (savedWidget.type && savedWidget.flowId) {
                            // Distinguish between charts and other types
                            if (savedWidget.type.includes('chart')) {
                                addNewWidget(savedWidget.type, null, savedWidget.flowId, data, savedWidget.x, savedWidget.y)
                            } else {
                                addNewWidget(savedWidget.type, savedWidget.zone, savedWidget.flowId, data, savedWidget.x, savedWidget.y)
                            }
                        } else {
                            console.error("Widget data is incomplete:", savedWidget)
                        }
                    }
                })

                // Restore additional settings
                if (storedState.duration) {
                    duration.value = storedState.duration
                }
                if (storedState.dateRangeMode) {
                    dateRangeMode.value = storedState.dateRangeMode
                }
                if (storedState.dateRange) {
                    if (storedState.dateRange.startDate) {
                        startDate.value = new Date(storedState.dateRange.startDate)
                    } else {
                        startDate.value = null
                    }
                    if (storedState.dateRange.endDate) {
                        endDate.value = new Date(storedState.dateRange.endDate)
                    } else {
                        endDate.value = null
                    }
                }
                if (storedState.dataGranularity) {
                    dataGranularity.value = storedState.dataGranularity
                }

                nextTick(() => {
                    updateGridHeight()  // Ensure the grid height is updated based on restored widget positions
                })

                isRestoringData.value  = false

                console.log("Restored dashboard state from localStorage.")
                return true  // Layout restored successfully
            } else {
                isRestoringData.value  = false
                console.log("No dashboard layout found in localStorage.")
                return false  // No layout found
            }
        }

        const areAllWidgetsPresent = async () => {
            if (!isMounted.value) {
                return false
            }

            // Check if there is an existing dashboard layout with saved widget IDs
            const storedState = JSON.parse(localStorage.getItem(`dashboardLayout_${userStore.user.UserId}`)) || {}
            const existingWidgetIds = storedState.layout?.map(widget => widget.id) || []

            // If we have existingWidgetIds in memory or storage, use that for comparison
            if (existingWidgetIds.length > 0) {
                // Use the existing widget IDs directly
                const existingWidgetIdsSet = new Set(existingWidgetIds)

                // Check if all possible widgets are in the layout by comparing with the existing IDs
                const currentWidgets = getCurrentLayoutWidgets() // Use your existing logic to get the current layout widgets
                for (const widget of currentWidgets) {
                    if (!existingWidgetIdsSet.has(widget.id)) {
                        return false // Missing widget, return false
                    }
                }

                return true // All widgets are present
            }

            // If no layout is saved, fallback to the slower full check (e.g., new PC or no layout saved)
            const allPossibleWidgets = await generateAllPossibleWidgets()

            // Adjusting to include both flow and zone as part of the key
            const existingWidgetKeys = new Set(widgets.value.map(widget => {
                const key = widget.type
                if (widget.props.zone) {
                    return `${key}-${widget.props.zone}`
                } else if (widget.props.flowId) {
                    return `${key}-${widget.props.flowId}`
                } else {
                    return `${key}-undefined`
                }
            }))

            for (const possibleWidget of allPossibleWidgets) {
                const key = `${possibleWidget.type}-${possibleWidget.zone || possibleWidget.flowId}`
                if (!existingWidgetKeys.has(key)) {
                    return false
                }
            }

            return true
        }

        const generateAllPossibleWidgets = async (category = null) => {
            const allWidgets = []
            const flows = CameraStreamStore.inputcamerastreams

            const categories = category 
                ? [category] 
                : ['counter_zone_in', 'counter_avg_duration', 'chart_zone_in', 'chart_avg_duration', 'heatmap_entrance']

            for (const flow of flows) {
                const flowId = flow.FlowId
                const flowData = await FlowStore.fetchDashboardFlow(flowId)
                const detectionZones = flowData.DetectionZone

                for (const cat of categories) {
                    if (cat === 'counter_zone_in' || cat === 'counter_avg_duration') {
                        for (const zoneId in detectionZones) {
                            allWidgets.push({ type: cat, flowId: flowId, zone: zoneId })
                        }
                    } else if (cat === 'chart_zone_in' || cat === 'chart_avg_duration') {
                        allWidgets.push({ type: cat, flowId: flowId })
                    } else if (cat === 'heatmap_entrance') {
                        for (const zoneId in detectionZones) {
                            if (detectionZones[zoneId].type === 'Entrance Zone' || detectionZones[zoneId].type === 'Combined Entrance Exit Zone') {
                                allWidgets.push({ type: cat, flowId: flowId, zone: zoneId })
                            }
                        }
                    }
                }
            }

            return allWidgets
        }

        const updateWidgetOptions = async () => {
            if (!isMounted.value) {
                return false
            }

            if (isUpdatingOptions.value) {
                // console.log('updateWidgetOptions is already running, skipping...')
                return
            }
            isUpdatingOptions.value = true

            try {
                const baseOptions = [
                    { category: 'counter_zone_in', title: 'Counter Zone In' },
                    { category: 'counter_avg_duration', title: 'Counter Average Duration' },
                    { category: 'chart_zone_in', title: 'Chart Zone In' },
                    { category: 'chart_avg_duration', title: 'Chart Average Duration' },
                    { category: 'heatmap_entrance', title: 'Entrance Heatmap' },
                ]

                const checks = await Promise.all(baseOptions.map(async (option) => {
                    const isPresent = await areAllWidgetsOfCategoryPresent(option.category)
                    return { option, isPresent }
                }))

                filteredWidgetOptions.value = checks
                    .filter(check => !check.isPresent)
                    .map(check => check.option)

                // console.log('Updated filteredWidgetOptions:', filteredWidgetOptions.value)
            } catch (error) {
                console.error('Error in updateWidgetOptions:', error)
            } finally {
                isUpdatingOptions.value = false // Reset the flag after the function finishes
                isLoadingFilterWidgetOptions.value = false
            }
        }

        const areAllWidgetsOfCategoryPresent = async (category) => {
            const allPossibleWidgets = await generateAllPossibleWidgets(category)

            const possibleWidgetsOfCategory = allPossibleWidgets.filter(widget => widget.type === category)

            const existingWidgetKeys = new Set(widgets.value
                .filter(widget => widget.type === category)
                .map(widget => `${widget.props.zone || widget.props.flowId}`)
            )

            const allPresent = possibleWidgetsOfCategory.every(widget => {
                const key = widget.zone || widget.flowId
                return existingWidgetKeys.has(String(key))
            })

            // console.log(`Are all widgets of category ${category} present?`, allPresent)
            return allPresent
        }

        const updateAvailableZones = async () => {
            isLoadingAvailableZones.value = true
            const type = selectedWidgetType.value

            availableZones.value = [] // Clear available zones

            const flows = CameraStreamStore.inputcamerastreams.map(async flow => {
                const flowId = flow.FlowId
                const flowData = await FlowStore.fetchDashboardFlow(flowId)

                if (!flowData.DetectionZone || typeof flowData.DetectionZone !== 'object') {
                    // console.log('No valid detection zones found for flow:', flowData.FlowName)
                    return
                }

                if (type === 'counter_zone_in' || type === 'counter_avg_duration') {
                    Object.entries(flowData.DetectionZone)
                        .filter(([zoneId, zoneData]) => {
                            // Only add zones that aren't already in use by the same type and flow ID
                            const zoneExists = widgets.value.some(widget => widget.props.zone === zoneId && widget.type === type && String(widget.props.flowId) === String(flowId))
                            return !zoneExists
                        })
                        .forEach(([zoneId, zoneData]) => {
                            availableZones.value.push({
                                label: `${zoneId} - ${flowData.FlowName}`,
                                value: { zoneId, flowId }
                            })
                        })
                } else if (type === 'chart_zone_in' || type === 'chart_avg_duration') {
                    const flowExists = widgets.value.some(widget => String(widget.props.flowId) === String(flowId) && widget.type === type)

                    if (!flowExists) {
                        availableZones.value.push({
                            label: `${type === 'chart_zone_in' ? 'Total Zone In' : 'Average Time In Zone'} - ${flowData.FlowName}`,
                            value: { flowId }
                        })
                    }
                } else if (type === 'heatmap_entrance') {
                    Object.entries(flowData.DetectionZone)
                        .filter(([zoneId, zoneData]) => {
                            const isEntranceZone = zoneData.type === 'Entrance Zone' || zoneData.type === 'Combined Entrance Exit Zone'
                            const zoneExists = widgets.value.some(widget => widget.props.zone === zoneId && widget.type === type && String(widget.props.flowId) === String(flowId))
                            return isEntranceZone && !zoneExists
                        })
                        .forEach(([zoneId, zoneData]) => {
                            availableZones.value.push({
                                label: `${zoneId} - ${flowData.FlowName}`,
                                value: { zoneId, flowId }
                            })
                        })
                }
            })

            await Promise.all(flows) // Wait for all flow data to be fetched and processed
            isLoadingAvailableZones.value = false

            //console.log('Final available zones:', availableZones.value)
        }

        watch(editMode, (newValue, oldValue) => {
            widgets.value.forEach(widget => {
                widget.isActive = newValue
            })

            if (newValue) {
                // Save initial layout when entering edit mode
                initialWidgetState.value = widgets.value.map(widget => ({
                    ...widget,
                    component: markRaw(widget.component) // Ensure component is non-reactive
                }))
                initialRemovedWidgetIds.value = [...removedWidgetIds.value] // Copy the removed widget IDs
                initialLayout.value = widgets.value.map(widget => ({
                    id: widget.id,
                    x: widget.x,
                    y: widget.y,
                    width: widget.width,
                    height: widget.height,
                }))
                hasChanges.value = false // Reset change flag
            } 
        })

        const onWidgetChange = () => {
            const initialState = initialLayout.value.map(widget => ({
                id: widget.id,
                x: widget.x,
                y: widget.y,
                width: widget.width,
                height: widget.height,
            }))

            const currentState = widgets.value.map(widget => ({
                id: widget.id,
                x: widget.x,
                y: widget.y,
                width: widget.width,
                height: widget.height,
            }))

            hasChanges.value = !initialState.every((widget, index) => {
                const currentWidget = currentState[index]
                if (!currentWidget) return false
                return (
                    widget.x === currentWidget.x &&
                    widget.y === currentWidget.y &&
                    widget.width === currentWidget.width &&
                    widget.height === currentWidget.height
                )
            })

            updateGridHeight()
        }

        // Watch for changes in widgets during edit mode
        watch(widgets, onWidgetChange, { deep: true })

        /*----------------------------------------- End of Dashboard State and Options Block ------------------------------------------*/


        /*----------------------------------------- Block: Counters and Charts ------------------------------------------*/

        const initializeZoneCounts = async (flowId, zoneAverageTime, zoneTotalCounts,storeTotalCounts) => {
            // Retrieve flowDashboardData from localStorage, or initialize it as an empty object if it doesn’t exist
            let flowDashboardData = JSON.parse(localStorage.getItem('flowDashboardData')) || {};

            // Check if flow data for the specific flowId is missing and needs to be fetched
            if (!flowDashboardData[flowId]) {
                await FlowStore.fetchDashboardFlow(flowId);
                // Refresh flowDashboardData after the fetch to ensure the new data is included
                flowDashboardData = JSON.parse(localStorage.getItem('flowDashboardData'));
            }

            const detectionZoneNames = flowDashboardData[flowId].DetectionZone

            for (let key in detectionZoneNames) {
                if (!zoneAverageTime[key]) {
                    zoneAverageTime[key] = { zone_type: detectionZoneNames[key]['type'], average_duration: 0, flowId: flowId }
                }
                if (!zoneTotalCounts[key]) {
                    zoneTotalCounts[key] = { zone_type: detectionZoneNames[key]['type'], zone_in: 0, flowId: flowId }
                }
                if (!storeTotalCounts[key]) {
                    storeTotalCounts[key] = { zone_in_count: 0, zone_out_count: 0, flowId: flowId}
                }
            }
        }

        const fetchCountersByDuration = async (flowId, zoneAverageTime, zoneTotalCounts) => {
            const minimumDurationThreshold = 0
            try {
                const now = new Date()
                const startDate = new Date(now)
                startDate.setDate(now.getDate() - duration.value + 1)

                let counts = await getCountersByDuration(flowId, minimumDurationThreshold, startDate, { signal: controller.signal })
                mergeZoneCounts(counts.zone_counters, zoneAverageTime, zoneTotalCounts)
                // Update storeTotalCounts granularly
                updateStoreCounts(counts.combined_ee_store_counters)       
            } catch (error) {
                console.error('Error fetching event data:', error)
            }
        }

        const fetchCountersByDateRange = async (flowId, zoneAverageTime, zoneTotalCounts) => {
            const minimumDurationThreshold = 0
            try {
                let counts = await getCountersByDateRange(flowId, startDate.value, endDate.value, minimumDurationThreshold, { signal: controller.signal })
                mergeZoneCounts(counts.zone_counters, zoneAverageTime, zoneTotalCounts)
                // Update storeTotalCounts granularly
                updateStoreCounts(counts.combined_ee_store_counters) 
            } catch (error) {
                console.error('Error fetching event data:', error)
            }
        }

        const fetchEventDataByHourByFlowByDuration = async (flowId) => {
            const minimumDurationThreshold = 0
            if (flowId) {
                const now = new Date()
                const startDate = new Date(now)
                startDate.setDate(now.getDate() - duration.value + 1)

                try {
                    const counters = await getCountersListByHourByDuration(flowId, minimumDurationThreshold, startDate, { signal: controller.signal })
                    createCountsChart(flowId, counters.zone_counters_by_hour, counters.combined_ee_store_counters_by_hour)
                    createAverageInteractionChart(flowId, counters.zone_counters_by_hour)
                } catch (error) {
                    console.error('Error fetching event data:', error)
                }
            }
        }

        const fetchEventDataByDayByFlowByDuration = async (flowId) => {
            const minimumDurationThreshold = 0
            if (flowId) {
                try {
                    const now = new Date()
                    const startDate = new Date(now)
                    startDate.setDate(now.getDate() - duration.value + 1)

                    const counters = await getCountersListByDayByDuration(flowId, minimumDurationThreshold, startDate, { signal: controller.signal })
                    createCountsChart(flowId, counters.zone_counters_by_day, counters.combined_ee_store_counters_by_day)
                    createAverageInteractionChart(flowId, counters.zone_counters_by_day)
                } catch (error) {
                    console.error('Error fetching event data:', error)
                }
            }
        }

        const fetchEventDataByHourByFlowByDateRange = async (flowId) => {
            const minimumDurationThreshold = 0
            if (flowId) {
                try {
                    const counters = await getcountersListByHourByDateRange(flowId, startDate.value, endDate.value, minimumDurationThreshold, { signal: controller.signal })
                    createCountsChart(flowId, counters.zone_counters_by_hour, counters.combined_ee_store_counters_by_hour)
                    createAverageInteractionChart(flowId, counters.zone_counters_by_hour)
                } catch (error) {
                    console.error('Error fetching event data:', error)
                }
            }
        }

        const fetchEventDataByDayByFlowByDateRange = async (flowId) => {
            const minimumDurationThreshold = 0
            if (flowId) {
                try {
                    const counters = await getcountersListByDayByDateRange(flowId, startDate.value, endDate.value, minimumDurationThreshold, { signal: controller.signal })
                    createCountsChart(flowId, counters.zone_counters_by_day, counters.combined_ee_store_counters_by_day)
                    createAverageInteractionChart(flowId, counters.zone_counters_by_day)
                } catch (error) {
                    console.error('Error fetching event data:', error)
                }
            }
        }

        const fetchHeatmapDataByDuration = async (flowId) => {
            await FlowStore.fetchDashboardFlow(flowId)
            const detectionZoneNames = FlowStore.dashboardFlow.DetectionZone
            
            const minimumDurationThreshold = 0

            const now = new Date()
            const startDate = new Date(now)
            startDate.setDate(now.getDate() - duration.value + 1)

            for (const key in detectionZoneNames) {
                if (detectionZoneNames[key].type === 'Entrance Zone') {
                    const heatmapData = await getHeatmapByHourByDuration(flowId, minimumDurationThreshold, key, startDate, duration.value, { signal: controller.signal })
                    heatmapDataStore.value[key] = heatmapData.heatmap_data
                }
                if (detectionZoneNames[key].type === 'Combined Entrance Exit Zone') {
                    const heatmapData = await getHeatmapByHourByDuration(flowId, minimumDurationThreshold, key, startDate, duration.value, { signal: controller.signal })
                    heatmapDataStore.value[key] = heatmapData.heatmap_data_combined_ee

                }
            }
        }

        const fetchHeatmapDataByDateRange = async (flowId) => {
            await FlowStore.fetchDashboardFlow(flowId)
            const detectionZoneNames = FlowStore.dashboardFlow.DetectionZone
            
            const minimumDurationThreshold = 0
            for (const key in detectionZoneNames) {
                if (detectionZoneNames[key].type === 'Entrance Zone') {
                    const heatmapData = await getHeatmapByHourByDateRange(flowId, startDate.value, endDate.value, minimumDurationThreshold, key, { signal: controller.signal })
                    heatmapDataStore.value[key] = heatmapData.heatmap_data
                }
                if (detectionZoneNames[key].type === 'Combined Entrance Exit Zone') {
                    const heatmapData = await getHeatmapByHourByDateRange(flowId, minimumDurationThreshold, key, startDate, duration.value, { signal: controller.signal })
                    heatmapDataStore.value[key] = heatmapData.heatmap_data_combined_ee

                }
            }
        }

        const fetchNewFlowData = async (flowId, widgetType) => {
            // Fetch new flow data logic (e.g., fetching detection zones and flow names)
            await FlowStore.fetchDashboardFlow(flowId)
            const detectionZoneNames = FlowStore.dashboardFlow.DetectionZone

            // Initialize zone counts if needed
            for (let key in detectionZoneNames) {
                if (!zoneAverageTime[key]) {
                    zoneAverageTime[key] = { zone_type: detectionZoneNames[key]['type'], average_duration: 0, flowId: flowId }
                }
                if (!zoneTotalCounts[key]) {
                    zoneTotalCounts[key] = { zone_type: detectionZoneNames[key]['type'], zone_in: 0, flowId: flowId }
                }
            }

            // Check if the widget is a heatmap widget
            if (widgetType === 'heatmap_entrance') {
                await fetchHeatmapDataForFlow(flowId, detectionZoneNames)
            } else {
                // Fetch counter and chart data
                await fetchCounterAndChartDataForFlow(flowId)
            }
        }

        // Function to fetch heatmap data for the flow
        const fetchHeatmapDataForFlow = async (flowId, detectionZoneNames) => {
            const minimumDurationThreshold = 0
            for (const key in detectionZoneNames) {
                if (detectionZoneNames[key].type === 'Entrance Zone') {
                    if (dateRangeMode.value === 'duration') {
                        const now = new Date()
                        const startDate = new Date(now)
                        startDate.setDate(now.getDate() - duration.value + 1)
                        
                        const heatmapData = await getHeatmapByHourByDuration(flowId, minimumDurationThreshold, key, startDate, duration.value,{ signal: controller.signal })
                        
                        heatmapDataStore.value[key] = heatmapData.heatmap_data
                    } else if (dateRangeMode.value === 'dateRange') {
                        const heatmapData = await getHeatmapByHourByDateRange(flowId, startDate.value, endDate.value, minimumDurationThreshold, key, { signal: controller.signal })
                        heatmapDataStore.value[key] = heatmapData.heatmap_data
                    }
                }
                if (detectionZoneNames[key].type === 'Combined Entrance Exit Zone') {
                    if (dateRangeMode.value === 'duration') {
                        const now = new Date()
                        const startDate = new Date(now)
                        startDate.setDate(now.getDate() - duration.value + 1)
                        
                        const heatmapData = await getHeatmapByHourByDuration(flowId, minimumDurationThreshold, key, startDate, duration.value,{ signal: controller.signal })
                        
                        heatmapDataStore.value[key] = heatmapData.heatmap_data_combined_ee
                    } else if (dateRangeMode.value === 'dateRange') {
                        const heatmapData = await getHeatmapByHourByDateRange(flowId, startDate.value, endDate.value, minimumDurationThreshold, key, { signal: controller.signal })
                        heatmapDataStore.value[key] = heatmapData.heatmap_data_combined_ee
                    }
                }
            }
        }

        // Function to fetch counter and chart data for the flow
        const fetchCounterAndChartDataForFlow = async (flowId) => {
            await initializeZoneCounts(flowId, zoneAverageTime, zoneTotalCounts, storeTotalCounts)
            
            if (dateRangeMode.value === 'dateRange') {
                await fetchCountersByDateRange(flowId, zoneAverageTime, zoneTotalCounts)
                if (dataGranularity.value === 'hourly') {
                    await fetchEventDataByHourByFlowByDateRange(flowId)
                } else {
                    await fetchEventDataByDayByFlowByDateRange(flowId)
                }
            } else if (dateRangeMode.value === 'duration') {
                await fetchCountersByDuration(flowId, zoneAverageTime, zoneTotalCounts)
                if (dataGranularity.value === 'hourly') {
                    await fetchEventDataByHourByFlowByDuration(flowId)
                } else {
                    await fetchEventDataByDayByFlowByDuration(flowId)
                }
            }
        }

        const mergeZoneCounts = (counts, zoneAverageTime, zoneTotalCounts) => {
            for (let key in counts) {
                if (zoneAverageTime[key]) {
                    zoneAverageTime[key].average_duration = counts[key].average_duration
                    zoneTotalCounts[key].zone_in = counts[key].zone_in_count
                } else {
                    zoneAverageTime[key] = { average_duration: counts[key].average_duration, zone_type: counts[key].zone_type, flowId: counts[key].flowId }
                    zoneTotalCounts[key] = { zone_in: counts[key].zone_in_count, zone_type: counts[key].zone_type, flowId: counts[key].flowId }
                }
            }
        }

        // Function to update storeTotalCounts granularly - Only applicable for Combined Entrance Exit Zones
        const updateStoreCounts = (newCounts) => {
            for (const zone in newCounts) {
                storeTotalCounts[zone] = {
                    ...storeTotalCounts[zone], // Preserve existing data for this zone
                    zone_in_count: newCounts[zone]?.zone_in_count || 0,
                    zone_out_count: newCounts[zone]?.zone_out_count || 0,
                };
            }
        };


        const createCountsChart = async (flowId, counts, counts_combined_ee) => {
            // Extract dates as labels
            const labels = Object.keys(counts)
            
            const detectionZones = new Set()
            const zoneTypes = {}
            
            // Extract detection zone names and types
            labels.forEach(date => {
                Object.keys(counts[date]).forEach(zone => {
                    // Skip combined entrance-exit zones for this part
                    if (counts[date][zone]['zone_type'] !== 'Combined Entrance Exit Zone') {
                        detectionZones.add(zone);
                        zoneTypes[zone] = counts[date][zone]['zone_type'];
                    }
                })
            })

            // Add separate Store IN and Store OUT for Combined Entrance Exit Zones
            if (counts_combined_ee) {
                Object.keys(counts_combined_ee).forEach(date => {
                    Object.keys(counts_combined_ee[date]).forEach(zone => {
                        if (counts_combined_ee[date][zone]['zone_type'] === 'Combined Entrance Exit Zone') {
                            detectionZones.add(`${zone} Store IN`);
                            detectionZones.add(`${zone} Store OUT`);
                            zoneTypes[`${zone} Store IN`] = 'Combined Entrance Exit Zone';
                            zoneTypes[`${zone} Store OUT`] = 'Combined Entrance Exit Zone';
                        }
                    })
                })
            }

            const detectionZoneArray = Array.from(detectionZones).sort()

            const datasets = detectionZoneArray.map(zone => {
                const zoneType = zoneTypes[zone]
                const data = labels.map(date => {
                    if (zone.endsWith('Store IN')) {
                        const originalZone = zone.replace(' Store IN', '')
                        return counts_combined_ee[date]?.[originalZone]?.zone_in_count || 0
                    } else if (zone.endsWith('Store OUT')) {
                        const originalZone = zone.replace(' Store OUT', '')
                        return counts_combined_ee[date]?.[originalZone]?.zone_out_count || 0
                    } else {
                        return counts[date]?.[zone]?.zone_in_count || 0
                    }
                })
                return {
                    label: zone,
                    data: data,
                    backgroundColor: detection_zone_colors_by_name_solid[zoneType] || '#000000'
                }
            })
            const chartData = {
                labels: labels,
                datasets: datasets
            }

            chartDataCountsInOut.value[flowId] = chartData
        }

        const createAverageInteractionChart = async (flowId, averageDuration) => {         
            const labels = Object.keys(averageDuration)
            const detectionZones = new Set()
            const zoneTypes = {}
            labels.forEach(date => {
                Object.keys(averageDuration[date]).forEach(zone => {
                    detectionZones.add(zone)
                    zoneTypes[zone] = averageDuration[date][zone]['zone_type']
                })
            })
            const detectionZoneArray = Array.from(detectionZones).sort()
            const datasets = detectionZoneArray.map(zone => {
                const zoneType = zoneTypes[zone]
                const data = labels.map(date => averageDuration[date][zone]['average_duration'] || 0)
                return {
                    label: zone,
                    data: data,
                    backgroundColor: detection_zone_colors_by_name_solid[zoneType] || '#000000'
                }
            })
            const chartData = {
                labels: labels,
                datasets: datasets
            }
            
            chartDataAverageInteractionTime.value[flowId] = chartData
        }

        const chartOptionsCounts = {
            responsive: true,
            maintainAspectRatio: false,
            scales: {
                y: {
                    beginAtZero: true,
                    ticks: {
                        font:
                        {
                            size:10
                        }
                    }
                },
                x: {
                    stacked: false,
                    ticks: {
                        font:
                        {
                            size:10
                        }
                    }
                }
            },
            plugins: {
                legend: {
                    display: true,
                    position: 'top',
                },
                title: {
                    display: true,
                    text: 'Total Zone In (#)',
                },
                tooltip: {
                    enable: true
                },
            }
        }

        const chartOptionsAverageTime = {
            responsive: true,
            maintainAspectRatio: false,
            scales: {
                y: {
                    beginAtZero: true,
                    ticks: {
                        font:
                        {
                            size:10
                        }
                    }
                },
                x: {
                    stacked: false,
                    ticks: {
                        font:
                        {
                            size:10
                        }
                    }
                }
            },
            plugins: {
                legend: {
                    display: true,
                    position: 'top',
                },
                title: {
                    display: true,
                    text: 'Average Time In Zone (sec)',
                },
                tooltip: {
                    enable: true
                },
            }
        }

        /*----------------------------------------- End of Counters and Charts Block ------------------------------------------*/


        /*----------------------------------------- Block: Side Bar Chat Agent ------------------------------------------*/

        const toggleSidebar = () => {
            isSidebarOpen.value = !isSidebarOpen.value
        };

        const sendMessage = async () => {
            if (!userInput.value) return

            // Add user message to chat
            messages.value.push({
                id: Date.now(),
                sender: 'You',
                text: userInput.value,
                timestamp: new Date().toLocaleString(),
        })

        let prompt = userInput.value

        // Clear input
        userInput.value = ''
        try {
            // Send query to backend
            const response = await sendMessageToAgent(prompt)

            // Add assistant response to chat
            messages.value.push({
            id: Date.now(),
            sender: 'IntAlytic',
            text: response,
            timestamp: new Date().toLocaleString(),
            })
        } catch (error) {
            messages.value.push({
            id: Date.now(),
            sender: 'IntAlytic',
            text: "Sorry, I couldn't process your request.",
            timestamp: new Date().toLocaleString(),
            })
        }

        
        }

        /*----------------------------------------- End of Side Bar Chat Agent ------------------------------------------*/


        /*----------------------------------------- Block: Helper Functions ------------------------------------------*/

        const changeDuration = (newDuration) => {
            duration.value = newDuration
        }
        
        const changeDateRangeMode = (newMode) => {
            dateRangeMode.value = newMode
        }

        const updateClearDatePicker = (newValue) => {
            clearDatePicker.value = newValue
        }

        const updateStartDate = (date) => {
            startDate.value = date
        }

        const updateEndDate = (date) => {
            endDate.value = date
        }

        const updateDataGranularity = (granularity) => {
            dataGranularity.value = granularity
        }
        
        const formattedAverageDurationTime = (seconds) => {
            if (seconds < 60) {
                return `${seconds.toFixed(0)} sec`
            } else if (seconds < 3600) {
                const minutes = Math.floor(seconds / 60)
                const remainingSeconds = seconds % 60
                return `${minutes} min ${remainingSeconds.toFixed(0)} sec`
            } else {
                const hours = Math.floor(seconds / 3600)
                const remainingMinutes = Math.floor((seconds % 3600) / 60)
                const remainingSeconds = seconds % 60
                return `${hours} h ${remainingMinutes} min ${remainingSeconds.toFixed(0)} sec`
            }
        }

        /*----------------------------------------- End of Helper Functions Block ------------------------------------------*/

        return {
            CompanyStore,
            isSmartphoneWidth,
            widgets,
            gridContainer,
            gridHeight,
            gridKey,
            refs,
            updateGridHeight,
            onWidgetResize,
            formattedAverageDurationTime,
            changeDuration,
            duration,
            changeDateRangeMode,
            dateRangeMode,
            updateClearDatePicker,
            clearDatePicker,
            updateStartDate,
            startDate,
            updateEndDate,
            endDate,
            updateDataGranularity,
            dataGranularity,
            chartDataCountsInOut,
            chartDataAverageInteractionTime,
            chartOptionsCounts,
            chartOptionsAverageTime,
            heatmapDataStore,
            fixedWidgetsData,
            layoutRestored,
            editMode,
            removeWidget,
            openAddWidgetModal,
            addNewWidget,
            saveDashboardState,
            restoreDashboardState,
            showAddWidgetModal,
            selectedWidgetType,
            availableZones,
            selectedZones,
            filteredWidgetOptions,
            openAddWidgetModal,
            closeAddWidgetModal,
            addSelectedWidgets,
            areAllWidgetsPresent,
            allWidgetsPresent,
            updateAvailableZones,
            handleDragging,
            onWidgetChange,
            isLoading,
            isLoadingPage,
            isLoadingFilterWidgetOptions,
            isLoadingAvailableZones,
            isAddingWidget,
            isInitialLoad,
            isSidebarOpen,
            messages,
            userInput,
            toggleSidebar,
            sendMessage,

        }
    }
})
</script>
  
<style scoped>
.dashboard-wrapper {
    min-height: 100vh; /* Cover full viewport height */
    height: auto; /* Allow it to adjust based on content */
    display: flex;
    flex-direction: column;
    overflow: visible;
}

.container {
    /* flex: 1; */
    display: flex;
    flex-direction: column;
    overflow: visible; /* Ensure the container itself does not scroll */
    min-height: 0; /* Allows the container to shrink and expand based on content */
}

.card {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: visible; /* Ensure the card itself does not scroll */
    min-height: 0; /* Allows the card to shrink and expand based on content */
}

.card-title {
  color: #4F7EB3 !important;
}

.card-header {
    display: flex;
    justify-content: space-between;
    align-items: center; /* Center content vertically */
    padding: 10px;
    background-color: #f5f5f5;
    border-bottom: 1px solid #ccc;
}

.card-header-intervals {
    display: flex;
    flex-wrap: wrap; /* Allow all elements in the header to wrap */
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background-color: #f5f5f5;
    border-bottom: 1px solid #ccc;
    row-gap: 10px; /* This adds 10px between rows when wrapping */
}

.left-group, .right-group {
    display: flex;
    align-items: center;
    gap: 25px; /* Reduce gap between elements */
    font-size: 0.8em; /* Further reduce font size */
    height: 100%; /* Ensure it takes full height of the header */
}

.top-row {
    display: flex;
    flex-wrap: wrap; /* Allow items to wrap in the top row */
}

.add-widget-btn {
    padding: 5px 10px; /* Adjust padding for the Add Widget button */
    background-color: #4F7EB3 !important;
    border: none !important;
}

.add-widget-btn:disabled {
    background-color: #cccccc !important; /* Adjust disabled state color */
}

.form-check-label, .form-check-input {
    margin: 0;
    display: flex;
    align-items: center; /* Align label and switch vertically */
}

.edit-mode-switch {
    font-size: 14px;
    padding-top: 0; /* Remove padding top to align with the button */
    padding-left: 0;
    display: flex;
    align-items: center; /* Center the toggle switch vertically */
}

.form-check-input {
    margin-right: 5px; /* Space between the switch and the label */
}

.right-group {
    display: flex;
    align-items: center;
    gap: 15px; /* Space between the button and toggle switch */
    height: 100%; /* Take full height of the card header */
}

.card-body {
    flex: 1;
    overflow-x: hidden; /* Enable horizontal scrolling */
    overflow-y: visible; /* Ensure vertical overflow is handled normally */
    display: flex;
    flex-direction: column;
    justify-content: flex-start; /* Ensure the content starts from the top */
    min-height: 0; /* Allows the card body to shrink and expand based on content */
    padding: 0; /* Ensure no padding causes unnecessary scroll bars */
    position: relative;
}

.scrollable-container {
    flex: 1;
    min-height: 100%; /* Ensure it takes at least 100% height */
    overflow: visible; /* Remove the internal scroll bar */
    display: flex;
    flex-direction: column;
}

.dynamic-height-container {
    position: relative;
    flex: 1;
    min-height: 100%;
    height: auto;
    overflow-x: auto; /* This enables horizontal scrolling */
    overflow-y: visible; /* This keeps the vertical overflow behavior intact */
    display: flex; /* Make it a flex container */
    flex-direction: row; /* Ensure that child elements are aligned horizontally */
    align-items: flex-start; /* Align child elements to the top */
    width: fit-content; /* Ensure the container width is based on content */
    white-space: nowrap; /* Prevents wrapping of child elements */
}

.widget {
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

.widget-header {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    background-color: #f5f5f5;
    border-bottom: 1px solid #ccc;
    position: relative;
    }

.widget-title {
    flex: 1;
    text-align: center;
    font-size: small;
}

.btn-primary{
  background-color: #4F7EB3 !important;
  border: none !important;
}

.btn-primary:hover {
  background-color: #B2C149 !important;
}

.close-btn {
    position: absolute;
    right: 5px;
    font-size: 10px; /* Reduce font size */
    padding: 2px px; /* Adjust padding */
    line-height: 1; /* Decrease line height for a compact look */
    color: #9FC8E3
}

.close-btn:hover {
    color: #B2C149
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.available-zones-list {
    max-height: 200px;
    overflow-y: auto;
    padding-right: 10px;
}

.modal-content {
    background-color: white;
    padding: 40px;
    border-radius: 5px;
    width: 500px;
    border-style: solid;
    border-color: #4F7EB3;
    max-height: 80vh;
    overflow-y: auto;
}

.close-modal {
    font-size: 12px;
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
}

.checkbox-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    max-width: 600px;
}

.form-check {
    flex-basis: calc(33.33% - 10px);
    margin-bottom: 10px;
    padding-left: 0px;
}

/* Media query for screens less than 950px */
@media (max-width: 950px) {
  .checkbox-container {
    flex-direction: column; /* Stack vertically under each other */
    align-items: flex-start; /* Align items to the left */
    max-width: none; /* Remove maximum width */
  }

  .form-check {
    flex-basis: 100%; /* Take full width of the container */
    padding-left: 0px;
  }
}

@media (min-width: 1600px) {
    .container {
        max-width: 1520px;
    }
}

.loader-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 300px;
    width: 100%; /* Ensure it takes the full width of the parent container */
    text-align: center; /* Center the text if any */
}

.loader-text {
  margin-bottom: 10px;
  font-size: small;
}

.loader-filtered-widget-options {
  width: 40px;
  padding: 2px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: #B2C149;
  --_m: 
    conic-gradient(#0000 10%,#000),
    linear-gradient(#000 0 0) content-box;
  -webkit-mask: var(--_m);
          mask: var(--_m);
  -webkit-mask-composite: source-out;
          mask-composite: subtract;
  animation: spinner 1s infinite linear;
}
/* @keyframes spinner {to{transform: rotate(1turn)}} */

.loader-available-zones {
  width: 20px;
  padding: 2px;
  margin-bottom: 15px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: #B2C149;
  --_m: 
    conic-gradient(#0000 10%,#000),
    linear-gradient(#000 0 0) content-box;
  -webkit-mask: var(--_m);
          mask: var(--_m);
  -webkit-mask-composite: source-out;
          mask-composite: subtract;
  animation: spinner 1s infinite linear;
}
/* @keyframes spinner {to{transform: rotate(1turn)}} */


.loader {
    width: 80px;
    padding: 8px;
    aspect-ratio: 1;
    border-radius: 50%;
    background: #B2C149;
    --_m: 
        conic-gradient(#0000 10%,#000),
        linear-gradient(#000 0 0) content-box;
    -webkit-mask: var(--_m);
            mask: var(--_m);
    -webkit-mask-composite: source-out;
            mask-composite: subtract;
    animation: spinner 1s infinite linear;
}
@keyframes spinner {to{transform: rotate(1turn)}}

.toggle-sidebar {
  background-color: #4f7eb3;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
}

.toggle-sidebar:hover {
  background-color: #b2c149;
}

.close-btn {
  background-color: #4f7eb3;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
}

.close-btn:hover {
  background-color: #b2c149;
  color: #fff;
}

/* Sidebar */
.sidebar {
  position: fixed;
  top: 0;
  right: -450px;
  width: 450px;
  height: 100vh;
  background-color: white;
  border-left: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  transition: right 0.3s ease;
  z-index: 1000;
  padding-top: 0px;
  margin-top: 0px;
}

.sidebar.open {
  right: 0;
}

.sidebar-header {
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
  padding: 5px;
  background-color: rgba(33, 37, 42, 0.03);
  border-bottom: 1px solid #ccc;
  color: #4f7eb3;
  padding-top: 10px;
}

.chat-container {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.chat-messages {
  flex: 1;
  overflow-y: auto;
  padding: 10px;
  background-color: white;
}

.chat-input {
  display: flex;
  padding: 10px;
  border-top: 1px solid #ccc;
  background-color: rgba(33, 37, 42, 0.03);
}

.chat-input input {
  flex: 1;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.chat-input button {
  margin-left: 10px;
  padding: 5px 10px;
  background-color: #4f7eb3;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.chat-input button:hover {
  background-color: #b2c149;
}

/* Chat Messages */
.user {
  text-align: right;
  margin-bottom: 10px;
}

.assistant {
  text-align: left;
  margin-bottom: 10px;
}

</style>