<template>
    
    <select class="form-select" id="toggleDetectionZoneType" :value="modelValue" @change="onChange">
        <option disabled value="">Zone Type</option>
        <option v-for="detectionzonetype in detectionzonetypes.detectionzonetypes" :key="detectionzonetype.DetectionZoneTypeId" :value="detectionzonetype.DetectionZoneTypeName">{{ detectionzonetype.DetectionZoneTypeName }}</option>
    </select>
    
</template>

<script>
import { onBeforeMount } from 'vue'
import { useDetectionZoneTypeStore } from '@/stores/DetectionZoneTypeStore'

export default{
    name: 'DropdownDetectionZoneType',

    props: {
        modelValue: String
    },
    setup (props, { emit }) {
        const detectionzonetypes = useDetectionZoneTypeStore()

        onBeforeMount(() => {
            detectionzonetypes.fetchDetectionZoneTypes()
        })

        const onChange = (event) => {
            const value = event.target.value
            emit('update:modelValue', value)
            emit('detectionzonetype', value) // Emit custom event if needed
        }
        
        return {
            detectionzonetypes,
            onChange
        }
    }
}

</script>

<style scoped>

</style>