<template>
    <div class="heatmap-widget">
      <canvas ref="heatmapCanvas"></canvas>
    </div>
  </template>
  
<script>
import { ref, onMounted, onBeforeUnmount, watch } from 'vue';
import { Chart } from 'chart.js';
import { MatrixController, MatrixElement } from 'chartjs-chart-matrix';
import { color } from 'chart.js/helpers'

Chart.register(MatrixController, MatrixElement);

export default {
  name: 'HeatmapDashboard',
  
  props: {
    heatmapData: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    flowId: {
      type: String,
      required: true
    }
  },
    
  setup(props) {
    const heatmapCanvas = ref(null)
    let heatmapChart = null

    const highestValue = ref(0)
  
    const formatHeatmapData = () => {
      if (!props.heatmapData || Object.keys(props.heatmapData).length === 0) {
        return [] // Return an empty array if data is not ready
      }

      // Define the correct order of the days
      const dayOrder = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"]

      // Sort the days according to the defined order
      const sortedDays = Object.keys(props.heatmapData).sort((a, b) => {
          return dayOrder.indexOf(a) - dayOrder.indexOf(b)
      });

      const formattedData = [];
        for (const day of sortedDays) {
          const hours = props.heatmapData[day]
            
          // Sort hours numerically
          const sortedHours = Object.keys(hours).sort((a, b) => Number(a) - Number(b))

          for (const hour of sortedHours) {
            
            const value = hours[hour]

            // Format as HH:00 in local time
            const localTime = `${hour.toString().padStart(2, '0')}:00`

            formattedData.push({
              x: day, // Use day names directly
              y: localTime, // Display as HH:00 in local time
              d: `${day} ${localTime}`,
              v: value,
            })
          }
        }

        return formattedData
      }
  
      const scales = {
        y: {
          type: 'category',
          left: 'left',
          offset: true,
          labels: () => {
            if (!props.heatmapData || Object.keys(props.heatmapData).length === 0) {
              return [] // Return empty array if data is not ready
            }

            const formattedData = formatHeatmapData()
            const uniqueTimes = [...new Set(formattedData.map(dataPoint => dataPoint.y))]
            return uniqueTimes.sort((a, b) => parseInt(a) - parseInt(b)) // Sort by hour
          },
          grid: {
            display: false,
            drawBorder: false,
            tickLength: 0,
          },
          title: {
            display: true,
            font: { size: 12, weight: 'bold' },
            text: 'Hours',
            padding: 0,
          },
        },
        x: {
          type: 'category',
          position: 'top',
          offset: true,
          labels: () => {
            if (!props.heatmapData || Object.keys(props.heatmapData).length === 0) {
              return [] // Return empty array if data is not ready
            }

            return Object.keys(props.heatmapData)
                .sort((a, b) => {
                    const dayOrder = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
                    return dayOrder.indexOf(a) - dayOrder.indexOf(b);
                });
          },
          ticks: {
            padding: 0,
            maxRotation: 0,
          },
          grid: {
            display: false,
            drawBorder: false,
          },
          title: {
              display: true,
              font: { size: 12, weight: 'bold' },
              text: 'Days of the Week',
          },
        },
      };
  
      const options = {
        maintainAspectRatio: false,  // Disable Chart.js aspect ratio management
        responsive: true,
        plugins: {
          legend: { display: false },
          tooltip: {
            displayColors: false,
            callbacks: {
              title() {
                return ''
              },
              label(context) {
                const v = context.dataset.data[context.dataIndex]
                return [`d: ${v.d}`, `v: ${v.v.toFixed(0)}`]
              },
            },
          },
        },
        scales: scales,
        layout: {
          padding: {
            top: 10,
          },
        },
      };
  
      const currentDay = new Date().toLocaleDateString('en-GB', { weekday: 'long' })
      
      const data = {
        datasets: [{
          data: formatHeatmapData(),
          
          backgroundColor({ raw }) {
            if (raw && typeof raw.v !== 'undefined') {
              //const alpha = (10 + raw.v) / (highestValue.value)
              const scaleFactor = 10; // Adjust as needed
              const adjustedValue = raw.v * scaleFactor;
              const alpha = (10 + adjustedValue) / (highestValue.value * scaleFactor);
              
              // Check if the cell's day (raw.x) matches the current day
              const day = raw?.x;
              const baseColor = day === currentDay ? '#4F7EB3' : 'rgba(66, 84, 97)';
              
              // Apply calculated transparency (alpha) to the base color
              return color(baseColor).alpha(alpha).rgbString();

            }
            return color('rgba(66, 84, 97)').alpha(0.2).rgbString() // Fallback color
          },

          borderColor({ raw }) {
            if (raw && typeof raw.v !== 'undefined') {
              const alpha = (10 + raw.v) / 60;
              
              // Check if the cell's day (raw.x) matches the current day
              const day = raw?.x;
              const baseColor = day === currentDay ? '#4F7EB3' : 'rgba(66, 84, 97)';
              
              // Apply calculated transparency (alpha) to the base color
              return color(baseColor).alpha(alpha).darken(0.3).rgbString();

            }
            return color('rgba(66, 84, 97)').alpha(0.2).darken(0.3).rgbString() // Fallback color
          },
          borderWidth: 1,
          hoverBackgroundColor: 'black',
          hoverBorderColor: 'black',
          width: ({ chart }) => (chart.chartArea || {}).width / chart.scales.x.ticks.length - 3,
          height: ({ chart }) => (chart.chartArea || {}).height / chart.scales.y.ticks.length - 3,
        }],
      }

      
  
      const renderHeatmap = () => {
        if (heatmapChart) {
          // If the chart already exists, just update the data
          heatmapChart.data.datasets[0].data = formatHeatmapData()
          heatmapChart.update({
            duration: 0,  // Disable the animation
          })
        } else {
          // If the chart doesn't exist, create it
          const ctx = heatmapCanvas.value.getContext('2d')

          const config = {
            type: 'matrix',
            data: data,
            options: options,
          }

          heatmapChart = new Chart(ctx, config)
        }
    }

    const resizeChart = () => {
      if (heatmapChart) {
        const parent = heatmapCanvas.value.parentNode
        heatmapCanvas.value.width = parent.clientWidth
        heatmapCanvas.value.height = parent.clientHeight
        heatmapChart.resize()
      }
    }

    watch(() => props.heatmapData, () => {
      renderHeatmap()
      window.addEventListener('resize', resizeChart)
    })

    const findHighestValue = (heatmapData) => {
      let highestValue = 0 // Initialize the highest value

      // Iterate over each day in heatmapData
      for (const day in heatmapData) {
        const hours = heatmapData[day]
          
        // Iterate over each hour in the day
        for (const hour in hours) {
          const value = hours[hour]

          // Update highestValue if current value is greater
          if (value > highestValue) {
            highestValue = value
          }
        }
      }

        return highestValue
    }
  
    onMounted(async () => {
      const flowId = Number(props.flowId)
      highestValue.value = findHighestValue(props.heatmapData)
      renderHeatmap()
    })

    onBeforeUnmount(() => {
      if (heatmapChart) {
        heatmapChart.destroy()
      }
      window.removeEventListener('resize', resizeChart)
    })
  
    return {
      heatmapCanvas,
      resizeChart
    }
  },
}

</script>


<style scoped>
.heatmap-widget {
  position: relative;
  width: 100%;
  height: 100%;
}

.heatmap-widget canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
}
</style>