<template>
    <div class="container" v-if="userStore.user && companyStore.company">
        <div class="container">
            <div class="card">
                <div class="card-header">
                    <h5 class="card-title">Settings</h5>
                </div>
            
                <div class="card-body" >
                    <div class="card">
                        <div class="card-header custom-card-header">
                            <button type="button" class="btn btn-primary custom-btn" @click="backHome"><span class="btn-label"><i class="bi bi-house-door-fill"></i></span></button>
                            <div class="custom-title">
                                <p class="card-title mb-0">General Settings</p>
                            </div>
                        </div>
                        
                        <div class="card-body" v-if="companyStore.company.settings">
                            <div>

                                <div class="mb-3">
                                    <label class="form-label">Local Device - Connection URL & Port:</label>
                                    <div v-if="!editSocketioURLPublic" class="card-text" style="padding-top: 25px;">
                                        <span style="color: #B2C149"><i><b>External</b> Network Access: </i></span>
                                        <span :class="{'disabled-text': !editSocketioURLPublic}">{{ companyStore.company.SocketioURLPublic }}</span>
                                        <button v-if="userStore.user.UserRole === 'Admin'" type="button" class="btn btn-outline-primary btn-sm" @click="openSocketioURLPublicEdit" style="margin-left: 10px">
                                            <span class="btn-label"><i class="bi bi-pencil"></i></span>
                                        </button>
                                    </div>
                                    
                                    <div v-if="editSocketioURLPublic" class="d-flex align-items-center card-title" style="padding-top: 25px;">
                                        <span style="color: #B2C149"><i><b>External</b> Network Access: </i></span>
                                        <input type="text" class="form-control" style="width: 250px; margin-left:10px; margin-right: 10px;" id="name" v-model="companyStore.company.SocketioURLPublic">
                                        <button v-if="userStore.user.UserRole === 'Admin'"type="button" class="btn btn-outline-primary btn-sm" @click="openSocketioURLPublicEdit" style="margin-left: 10px">
                                            <span class="btn-label"><i class="bi bi-pencil"></i></span>
                                        </button>
                                    </div>
                                
                                </div>

                                <hr class="divider">

                                <div class="mb-3">
                                    <label class="form-label">Detection Overlap Mode:</label>
                                    <div class="form-check form-check-inline">
                                        <input type="radio" class="form-check-input" id="bottom_overlap" value="bottom_overlap" v-model="companyStore.company.settings.DetectionOverlapMode">
                                        <label class="form-check-label" for="bottom_overlap">Bottom Overlap</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input type="radio" class="form-check-input" id="box_overlap" value="box_overlap" v-model="companyStore.company.settings.DetectionOverlapMode">
                                        <label class="form-check-label" for="box_overlap">Bounding Box Overlap</label>
                                    </div>
                                </div>

                                <hr class="divider">

                                <div class="mb-3" v-if="companyStore.company.settings.DetectionOverlapMode === 'box_overlap'">
                                    <label class="form-label">Minimum Bounding Box Overlap (%):</label>
                                    <div class="slider-container">
                                        <Slider class="slider" v-model="boundingBoxOverlapPercentage" :min="55" :max="100" />
                                    </div>
                                </div>

                                <div class="mb-3" v-if="companyStore.company.settings.DetectionOverlapMode === 'bottom_overlap'">
                                    <label class="form-label">Minimum Bottom Line Overlap (%):</label>
                                    <div class="slider-container">
                                        <Slider class="slider" v-model="bottomOverlapPercentage" :min="55" :max="100" />
                                    </div>
                                </div>

                                <hr class="divider">

                                <div class="mb-3">
                                    <label class="form-label">Debouncing Threshold In/Out Zone (sec):</label>
                                    <div class="slider-container">
                                        <Slider class="slider" v-model="debounceWritingThreshold" :min="0" :max="5" :step="0.1" :format="formatDebounceTooltip" />
                                    </div>
                                </div>

                                <hr class="divider">

                                <div class="mb-3">
                                    <label class="form-label">Threshold to Remove Person ID when Invisble (# frames):</label>
                                    <div class="slider-container">
                                        <Slider class="slider" v-model="thresholdRemoveId" :min="0" :max="200" :step="1" />
                                    </div>
                                </div>

                            </div>
                        
                        </div>

                        <div class="card-footer">
                            <div v-if="isSaving" style="margin-top: 5px; margin-bottom: 5px; margin-left: 7px;">
                                <div class="saver"></div>
                            </div>
                            <button type="button" class="btn btn-primary" style="margin-right: 5px" @click="saveSettings" :disabled="!settingsChanged && !companyPublicUrlChanged"><span class="btn-label"><i class="bi bi-save"></i></span></button>
                        </div>

                    </div>

                    <div class="flex-break"></div>

                    <div v-if="userStore.user.UserRole === 'Admin'" class="card">
                        <div class="card-header custom-card-header">
                            <button type="button" class="btn btn-primary custom-btn" @click="backHome"><span class="btn-label"><i class="bi bi-house-door-fill"></i></span></button>
                            <div class="custom-title">
                                <p class="card-title mb-0">User Management {{ companyStore.company.CompanyName }}</p>
                            </div>
                        </div>
                        
                        <div class="card-body" >
                                    
                            <div class="table-container">
                                <table>
                                    <thead>
                                        <tr>
                                            <th style="padding-left: 30px; font-size: small; color: #B2C149">Name</th>
                                            <th style="padding-left: 30px; font-size: small; color: #B2C149">Surname</th>
                                            <th style="padding-left: 30px; font-size: small; color: #B2C149">Email</th>
                                            <th style="padding-left: 30px; font-size: small; color: #B2C149">Role</th>
                                            <th style="padding-left: 30px; font-size: small; color: #B2C149">Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(item, index) in userList" :key="index">
                                            <td>
                                                <div v-if="item.editing">
                                                    <input type="text" style="padding-left: 10px; font-size: small; color: #425461" class="form-control" v-model="item.UserName" />
                                                </div>
                                                <div v-else style="padding-left: 10px; font-size: small; color: #425461">
                                                    <b>{{ item.UserName }}</b>
                                                </div>
                                            </td>
                                            <td>
                                                <div v-if="item.editing">
                                                    <input type="text" style="padding-left: 10px; font-size: small; color: #425461" class="form-control" v-model="item.UserSurname" />
                                                </div>
                                                <div v-else style="padding-left: 10px; font-size: small; color: #425461">
                                                    <b>{{ item.UserSurname }}</b>
                                                </div>
                                            </td>
                                            <td>
                                                <div v-if="item.editing">
                                                    <input type="text" style="padding-left: 10px; font-size: small; color: #425461" class="form-control" v-model="item.UserEmail" @blur="item.emailTouched = true" :class="{ 'is-invalid': item.emailTouched && !isEmailValid(item.UserEmail) && item.UserEmail !== '' }" />
                                                    <div v-if="item.emailTouched && !isEmailValid(item.UserEmail) && item.UserEmail !== ''" class="invalid-feedback">
                                                        Please enter a valid email address.
                                                    </div>
                                                </div>
                                                <div v-else style="padding-left: 10px; font-size: small; color: #425461">
                                                    {{ item.UserEmail }}
                                                </div>
                                            </td>
                                            <td>
                                                <div v-if="item.editing" >
                                                    <select class="form-select" style="padding-left: 10px; font-size: small; color: #425461" v-model="item.UserRole">
                                                        <option>User</option>
                                                        <option>Key User</option>
                                                        <option>Admin</option>
                                                    </select>
                                                </div>
                                                <div v-else style="padding-left: 10px; font-size: small; color: #425461">
                                                    {{ item.UserRole }}
                                                </div>
                                            </td>
                                            <td>
                                                <div v-if="item.editing">
                                                    <div v-if="isLoading">
                                                        <div class="loader"></div>
                                                    </div>
                                                    <div v-else>
                                                        <button type="button" class="btn btn-outline-primary btn-sm" @click="cancelEditUser(item)">
                                                            <span class="btn-label"><i class="bi bi-x-lg"></i></span>
                                                        </button>
                                                        <button type="button" class="btn btn-outline-primary btn-sm" @click="updateUser(item.UserId)" :disabled="!hasChanges(item)">
                                                            <span class="btn-label"><i class="bi bi-check-lg"></i></span>
                                                        </button>
                                                    </div>
                                                </div>
                                                <div v-else>
                                                    <div v-if="isLoading">
                                                        <div class="loader"></div>
                                                    </div>
                                                    <div v-else>
                                                        <button type="button" class="btn btn-outline-primary btn-sm" @click="editUser(item)">
                                                            <span class="btn-label"><i class="bi bi-person"></i></span>
                                                        </button>
                                                        <button type="button" class="btn btn-outline-primary btn-sm" @click="deleteUser(item.UserEmail)">
                                                            <span class="btn-label"><i class="bi bi-person-x"></i></span>
                                                        </button>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <input v-if="addingUser" style="padding-left: 10px; font-size: small; color: #425461" type="text" class="form-control" v-model="newUserName" placeholder="Name">
                                                <b v-else style="padding-left: 10px; font-size: small; color: #425461">&nbsp;</b>
                                            </td>
                                            <td>
                                                <input v-if="addingUser" style="padding-left: 10px; font-size: small; color: #425461" type="text" class="form-control" v-model="newUserSurname" placeholder="Surname">
                                                <b v-else style="padding-left: 10px; font-size: small; color: #425461">&nbsp;</b>
                                            </td>
                                            <td>
                                                <div v-if="addingUser">
                                                    <input type="text" style="padding-left: 10px; font-size: small; color: #425461" class="form-control" v-model="newUserEmail" @blur="newUserEmailTouched = true" :class="{ 'is-invalid': newUserEmailTouched && !isEmailValid(newUserEmail) && newUserEmail !== '' }" placeholder="Email" />
                                                    <div v-if="newUserEmailTouched && !isEmailValid(newUserEmail) && newUserEmail !== ''" class="invalid-feedback">
                                                        Please enter a valid email address.
                                                    </div>
                                                </div>
                                                <b v-else style="padding-left: 10px; font-size: small; color: #425461">&nbsp;</b>
                                            </td>
                                            <td>
                                                <select v-if="addingUser" style="padding-left: 10px; font-size: small; color: #425461" class="form-select" v-model="newUserRole">
                                                <option>User</option>
                                                <option>Key User</option>
                                                <option>Admin</option>
                                                </select>
                                                <b v-else style="padding-left: 10px; font-size: small; color: #425461">&nbsp;</b>
                                            </td>
                                            <td>
                                                <div v-if="isLoading">
                                                    <div class="loader"></div>
                                                </div>
                                                <div v-else>
                                                    <button v-if="!addingUser" type="button" class="btn btn-outline-primary btn-sm" @click="toggleAddUser">
                                                        <span class="btn-label"><i class="bi bi-plus-lg"></i></span>
                                                    </button>
                                                    <div v-else>
                                                        <button type="button" class="btn btn-outline-primary btn-sm" @click="cancelAddUser">
                                                            <span class="btn-label"><i class="bi bi-x-lg"></i></span>
                                                        </button>
                                                        <button type="button" class="btn btn-outline-primary btn-sm" @click="addUser(newUserEmail)">
                                                            <span class="btn-label"><i class="bi bi-person-plus"></i></span>
                                                        </button>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        
                        </div>

                        

                    </div>

                </div>

            </div>
        </div>
    </div>
</template>


<script>
import { ref, onBeforeMount, computed, watch } from 'vue'
import { useRouter } from 'vue-router'

import { useToast } from 'vue-toastification'

import Slider from '@vueform/slider'
import '@vueform/slider/themes/default.css'

import { useUserStore } from '@/stores/UserStore'
import { useCompanyStore } from '@/stores/CompanyStore'

import { MDBTabs, MDBTabNav, MDBTabContent, MDBTabItem, MDBTabPane } from 'mdb-vue-ui-kit'


export default {
    name: 'Settings',

    components: {
        Slider,
        MDBTabs,
        MDBTabNav,
        MDBTabContent,
        MDBTabItem,
        MDBTabPane
    },

    props: {

    },

    setup(props, { emit }) {
        const toast = useToast()

        const userStore = useUserStore()
        const companyStore = useCompanyStore()

        const router = useRouter()

        const userList = ref([])
        const addingUser = ref(false)
        const newUserName = ref('')
        const newUserSurname = ref('')
        const newUserEmail = ref('')
        const newUserRole = ref('User')

        const newUserEmailTouched = ref(false)

        const editSocketioURLPublic = ref(false)

        const bottom_overlap = 'bottom_overlap'
        const box_overlap = 'box_overlap'

        const boundingBoxOverlapPercentage = ref(0);
        const bottomOverlapPercentage = ref(0);
        const debounceWritingThreshold = ref(0);
        const thresholdRemoveId = ref(0);

        const userData = ref({
            userName: '',
            userSurname: '',
            userEmail: '',
            companyId: '',
            userRole: ''
        })

        const originalCompany = ref({})
        const originalSettings = ref({})
        const companyPublicUrlChanged = ref(false)
        const loadingComplete = ref(false)
        const settingsChanged = ref(false)
        const isLoading= ref(false)
        const isSaving = ref(false)
        
        onBeforeMount(async () => {
            await userStore.fetchUserDetails()
            await companyStore.fetchCompanyDetails(userStore.user.CompanyId)
            userList.value = companyStore.company.users.map(user => ({
                ...user,
                editing: false,
                emailTouched: false
            }))

            // Initialize sliders with settings values
            debounceWritingThreshold.value = companyStore.company.settings.DebounceWritingThreshold;
            thresholdRemoveId.value = companyStore.company.settings.ThresholdRemoveId;
            boundingBoxOverlapPercentage.value = companyStore.company.settings.BoundingBoxOverlapPercentage * 100;
            bottomOverlapPercentage.value = companyStore.company.settings.BottomOverlapPercentage * 100;

            originalCompany.value = JSON.parse(JSON.stringify(companyStore.company))
            originalSettings.value = JSON.parse(JSON.stringify(companyStore.company.settings))

            // Mark loading as complete to enable watchers
            loadingComplete.value = true
        })

        watch(
            () => companyStore.company.SocketioURLPublic,
            (newVal) => {
                if (loadingComplete.value) {
                    companyPublicUrlChanged.value = newVal !== originalCompany.value.SocketioURLPublic;
                }
            }
        )

        // Enable watcher only after initial setup is complete
        watch(
            () => ({
                debounceWritingThreshold: debounceWritingThreshold.value,
                thresholdRemoveId: thresholdRemoveId.value,
                boundingBoxOverlapPercentage: boundingBoxOverlapPercentage.value,
                bottomOverlapPercentage: bottomOverlapPercentage.value,
            }),
            (newVal) => {
                if (loadingComplete.value) {
                    settingsChanged.value = (
                        newVal.debounceWritingThreshold !== originalSettings.value.DebounceWritingThreshold ||
                        newVal.thresholdRemoveId !== originalSettings.value.ThresholdRemoveId ||
                        newVal.boundingBoxOverlapPercentage !== originalSettings.value.BoundingBoxOverlapPercentage * 100 ||
                        newVal.bottomOverlapPercentage !== originalSettings.value.BottomOverlapPercentage * 100
                    );
                }
            },
            { deep: true }
        );

        watch(
            () => companyStore.company.settings,
            (newVal) => {
                settingsChanged.value = JSON.stringify(newVal) !== JSON.stringify(originalSettings.value)
            },
            { deep: true }
        )

        const saveSettings = async () => {
            try {
                isSaving.value = true
                if (companyPublicUrlChanged.value) {
                    await companyStore.updateCompanyUrl(companyStore.company)
                }
                if (settingsChanged.value) {
                    companyStore.company.settings.DebounceWritingThreshold = debounceWritingThreshold.value;
                    companyStore.company.settings.ThresholdRemoveId = thresholdRemoveId.value;
                    companyStore.company.settings.BoundingBoxOverlapPercentage = boundingBoxOverlapPercentage.value / 100;
                    companyStore.company.settings.BottomOverlapPercentage = bottomOverlapPercentage.value / 100;

                    await companyStore.updateCompanySettings(companyStore.company)
                }
                toast.success('Settings saved successfully')
                // Update original settings after save
                originalCompany.value = JSON.parse(JSON.stringify(companyStore.company))
                originalSettings.value = JSON.parse(JSON.stringify(companyStore.company.settings))
                companyPublicUrlChanged.value = false
                settingsChanged.value = false
                isSaving.value = false
            } catch (error) {
                console.error("An unexpected error occurred while saving the settings to the database:", error)
                toast.error("An unexpected error occurred while saving the settings to the database. Please try again or contact support if the problem persists.")
                isSaving.value = false
            }
        }
        
        
        const openSocketioURLPublicEdit = () => {
            if (!(editSocketioURLPublic.value)) {
                editSocketioURLPublic.value = true
            } else {
                editSocketioURLPublic.value = false
            }
        }

        const toggleAddUser = () => {
            if (addingUser.value && newUserName.value && newUserSurname.value && newUserEmail.value) {
                userList.value.push({
                UserName: newUserName.value,
                UserSurname: newUserSurname.value,
                UserEmail: newUserEmail.value,
                UserRole: newUserRole.value,
                })
                newUserName.value = ''
                newUserSurname.value = ''
                newUserEmail.value = ''
                newUserRole.value = 'User'
            }
            addingUser.value = !addingUser.value
        }

        const cancelAddUser = () => {
            addingUser.value = false
            newUserName.value = ''
            newUserSurname.value = ''
            newUserEmail.value = ''
            newUserRole.value = 'User'
            newUserEmailTouched.value = false
        };

        const addUser = async () => {
            try {
                isLoading.value = true
                userData.value = {
                    userName: newUserName.value,
                    userSurname: newUserSurname.value,
                    userEmail: newUserEmail.value,
                    companyId: companyStore.company.CompanyId,
                    userRole: newUserRole.value
                }

                await userStore.addUser(userData.value)
                toast.success('User added successfully')

                // Add the new user to the list
                userList.value.push({
                    UserName: newUserName.value,
                    UserSurname: newUserSurname.value,
                    UserEmail: newUserEmail.value,
                    UserRole: newUserRole.value,
                    editing: false,
                    emailTouched: false
                })

                // Reset form data after successful registration
                newUserName.value = ''
                newUserSurname.value = ''
                newUserEmail.value = ''
                newUserRole.value = 'User'
                newUserEmailTouched.value = false

                isLoading.value = false

            } catch (error) {
                console.error("An unexpected error occurred while adding the user to the database.:", error)
                toast.error("An unexpected error occurred while adding the user to the database. Please try again or contact support if the problem persists.:", error)
                isLoading.value = false
            }
        }

        const editUser = (user) => {
            user.editing = true
            user.originalData = { ...user } // Store a copy of the original data
        }

        const hasChanges = (user) => {
            return user.UserName !== user.originalData.UserName ||
                user.UserSurname !== user.originalData.UserSurname ||
                user.UserEmail !== user.originalData.UserEmail ||
                user.UserRole !== user.originalData.UserRole
        }

        const cancelEditUser = (user) => {
            user.editing = false
            user.emailTouched = false
        }

        const updateUser = async (userId) => {
            isLoading.value = true
            const user = userList.value.find(u => u.UserId === userId)
            if (!user) return
            try {
                await userStore.updateUser(user)
                toast.success('User updated successfully')
                user.editing = false
                isLoading.value = false
            } catch (error) {
                console.error("An unexpected error occurred while updating the user in the database.:", error)
                toast.error("An unexpected error occurred while updating the user in the database. Please try again or contact support if the problem persists.")
                isLoading.value = false
            }
        };

        const deleteUser = async (userEmail) => {
            try{
                if (confirm("Do you really want to delete this user?")) {
                    isLoading.value = true
                    await userStore.deleteUser(userEmail)
                    // Update userList after successful deletion
                    userList.value = userList.value.filter(user => user.UserEmail !== userEmail)
                    toast.success('User deleted successfully')
                    isLoading.value = false
                }
            } catch (error) {
                console.error("An unexpected error occurred while deleting the user from the database.:", error)
                toast.error("An unexpected error occurred while deleting the user from the database. Please try again or contact support if the problem persists.:", error)
                isLoading.value = false
            }
        }

        const sendInvite = (email) => {
            // Send invite logic here
        }

        const isEmailValid = (email) => {
            return email !== '' && /\S+@\S+\.\S+/.test(email)
        }

        const formatDebounceTooltip = value => {
            return value.toFixed(1)
        }

        const backHome = () => {
            router.push('/')
        }

        const onCancel = () => {
            console.log('User cancelled the loader.')
        }

        return {
            userStore,
            companyStore,
            openSocketioURLPublicEdit,
            editSocketioURLPublic,
            bottom_overlap,
            box_overlap,
            boundingBoxOverlapPercentage,
            bottomOverlapPercentage,
            debounceWritingThreshold,
            thresholdRemoveId,
            formatDebounceTooltip,
            userList,
            addUser,
            editUser,
            hasChanges,
            cancelEditUser,
            updateUser,
            deleteUser,
            sendInvite,
            addingUser,
            newUserName,
            newUserSurname,
            newUserEmail,
            newUserRole,
            toggleAddUser,
            cancelAddUser,
            newUserEmailTouched,
            isEmailValid,
            originalCompany,
            originalSettings,
            companyPublicUrlChanged,
            settingsChanged,
            saveSettings,
            isLoading,
            isSaving,
            backHome,
            onCancel
        }
    }
}


</script>



<style scoped>

.flex-break {
    padding: 20px
}

.divider {
    color: #B2C149;
}

.custom-card-header {
    display: flex;
    align-items: center;
    position: relative;
}

.custom-btn {
    margin-right: auto;
}

.custom-title {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    text-align: center;
    pointer-events: none;
}

.card-title {
  color: #4F7EB3 !important;
  text-align: center;
}

.disabled-text {
    opacity: 0.5;
    pointer-events: none;
}

.btn-primary{
  background-color: #4F7EB3 !important;
  border: none !important;
}

.btn-primary:hover {
  background-color: #B2C149 !important;
}

.btn-outline-primary{
  color: #4F7EB3 !important;
  border: none;
}
.btn-outline-primary:hover {
  color: #FFFFFF !important;
  background-color: #4F7EB3 !important;
}

.btn-outline-primary:active {
  color: #FFFFFF !important;
  background-color: #4F7EB3 !important;
}

.slider-container {
    width: 100%; /* Make sure it takes full width */
}

.slider {
    margin-top: 50px;
    padding-left: 10px;
    --slider-connect-bg: #B2C149;
    --slider-tooltip-bg: #B2C149;
    --slider-height: 2px;
    --slider-handle-width: 12px;
    --slider-handle-height: 12px;
    --slider-tooltip-line-height: 1.0rem;
    --slider-tooltip-font-size: 0.7rem;
    width: 80%
    
}

/* Active tab with red background and white text */
.nav-tabs {
  --bs-nav-tabs-link-active-color: #ffffff !important;
  --bs-nav-tabs-link-active-bg: #4F7EB3 !important;
  --bs-nav-tabs-border-color: #000000 !important;
  --bs-nav-tabs-link-hover-border-color: #000000 !important;
}

/* Inactive tab with white background and black text */
.nav {
  --bs-nav-tabs-link-active-border-color: #000000 !important;
  --bs-nav-link-hover-color: #4F7EB3 !important;
  --bs-nav-link-color: #425461 !important;
}

.table-container {
    max-height: 600px; /* Adjust the height as needed */
    overflow-y: auto;
    margin-top: 20px;
    border: 1px solid #ddd; /* Optional: adds a border around the table */
}

table {
    width: 100%; /* Ensure the table fills the container */
    border-collapse: collapse; /* Optional: for styling */
}

/* Additional styling for your table (optional) */
table, th, td {
    border: 1px solid #ddd; /* Adds borders to your table cells */
}

th, td {
    padding: 8px; /* Adds space around your table content */
    text-align: left; /* Aligns text to the left */
}

.form-label {
    display: block;
    margin-bottom: .5rem;
    font-weight: bold;
    color: #425461
}

.mb-3 {
    margin-bottom: 2.5rem !important;
}

.loader {
  width: 20px;
  padding: 2px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: #B2C149;
  --_m: 
    conic-gradient(#0000 10%,#000),
    linear-gradient(#000 0 0) content-box;
  -webkit-mask: var(--_m);
          mask: var(--_m);
  -webkit-mask-composite: source-out;
          mask-composite: subtract;
  animation: spinner 1s infinite linear;
}
.saver {
  width: 25px;
  padding: 2px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: #B2C149;
  --_m: 
    conic-gradient(#0000 10%,#000),
    linear-gradient(#000 0 0) content-box;
  -webkit-mask: var(--_m);
          mask: var(--_m);
  -webkit-mask-composite: source-out;
          mask-composite: subtract;
  animation: spinner 1s infinite linear;
}
@keyframes spinner {to{transform: rotate(1turn)}}

</style>