<template>
    <div>
        <!-- Hamburger Menu for small screens -->
        <div class="hamburger" @click="toggleSidebar" v-if="isSmallScreen && !sidebarOpen">
            &#9776; <!-- This is the hamburger icon (3 lines) -->
        </div>
        
        <!-- Sidebar -->
        <div class="sidebar" :class="{ 'open': sidebarOpen, 'small-screen': isSmallScreen }" v-if="!isSmallScreen || sidebarOpen">

            <!-- Close button for small screens -->
            <div class="close-btn" @click="toggleSidebar" v-if="isSmallScreen">
                &times; <!-- Close Icon (X) -->
            </div>

            <div class="sidebar-logo">
                <img :src="logo_storalytic" alt="Logo Storalytic" />
            </div>

            <!-- Company name and subscription type at the top -->
            <div class="company-info" v-if="!isRegistrationRoute && isLoggedIn">
                <div>{{ companyName }}</div>
                <div class="subscription-type">{{ subscriptionType }} Subscription</div>
                <hr class="thin-divider">
            </div>
            

            <!-- Navigation links -->
            <div class="main-links" v-if="!isRegistrationRoute && isLoggedIn">
                <router-link to="/" class="nav-link" exact-active-class="active-link">Dashboard</router-link>
                
                <hr class="thin-divider">
                
                <router-link to="/camera_overview" class="nav-link" exact-active-class="active-link">All Camera Overview</router-link>
                
                <div>
                    <!-- Cameras toggle with triangle icon -->
                    <div class="expandable-header" @click="toggleFlowList">
                        <span :class="{'triangle-icon': true, 'expanded': showFlowList}"></span>
                        Cameras
                    </div>

                    <!-- Flow names list under Camera Overview, visible only if expanded -->
                    <div v-if="showFlowList" class="flow-list">
                        <router-link
                            v-for="flow in flowStore.flows"
                            :key="flow.FlowId"
                            :to="`/flow/${flow.FlowId}`"
                            class="flow-link"
                            exact-active-class="active-link"
                        >
                            {{ flow.FlowName }}
                        </router-link>
                    </div>
                </div>
                
                <hr class="thin-divider">
                
                <router-link to="/external_data" class="nav-link" exact-active-class="active-link">External Data</router-link>

                <hr v-if="!isSmallScreen" class="thin-divider">

                <router-link v-if="!isSmallScreen && userRole !== 'User'" to="/create_flow" class="nav-link" exact-active-class="active-link">Connect New Camera View</router-link>
                <router-link v-if="!isSmallScreen && userRole !== 'User'" to="/connect_api" class="nav-link" exact-active-class="active-link">Connect New External Data Source</router-link>
                <!-- <hr class="divider">
                <router-link to="/data_analytics" :class="{'active-page': page == 'data_analytics'}" @click="page = 'data_analytics'">Quick Data Analytics</router-link> -->
            </div>

            <!-- Footer section with connection status, settings, and user info -->
            <div class="sidebar-footer" v-if="!isRegistrationRoute && isLoggedIn">
                
                <hr class="thin-divider">

                <!-- Connection status -->
                <div class="connection-status" v-if="!isRegistrationRoute && isLoggedIn" style="padding-top: 5px;">
                    <div v-if="serverConnection">
                        <h6 style="font-size: 0.76rem;">EdgAlytic Connected: <span class="status-indicator status-running"></span></h6>
                    </div>
                    <div v-else>
                        <h6 style="font-size: 0.76rem;">EdgAlytic Connected: <span class="status-indicator status-stopped"></span></h6>
                    </div>
                </div>

                <hr class="thin-divider">

                <!-- Settings link and user info -->
                <div class="settings-container">
                    <router-link v-if="userRole !== 'User'" to="/settings" class="nav-link" exact-active-class="active-link" style="font-size: 0.76rem;">
                        <i class="bi bi-gear-fill"></i> Settings
                    </router-link>
                    <div class="user-info">
                        <i class="bi bi-person-fill"></i>
                        {{ userName }} {{ userSurname }} - {{ userRole }} : 
                        <a class="logout-link" @click="logout">Logout</a>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>
  
<script>
    import axios from 'axios'

    import { ref, onMounted, onBeforeUnmount, computed, toRefs, watch } from 'vue'
    import { useRoute, useRouter } from 'vue-router'

    import { useToast } from 'vue-toastification'

    import { useUserStore } from '@/stores/UserStore'
    import { useCompanyStore } from '@/stores/CompanyStore'
    import { useFlowStore } from '@/stores/FlowStore'
    import { useCameraStreamStore } from '@/stores/CameraStreamStore'
    import { useDetectionZoneStore } from '@/stores/DetectionZone'
    
    import { useGlobalState } from '@/composables/useGlobalState'

    import image from "./icons/logo_storalytic.png"

    export default {
        props: {
            userId: Number,
            userName: String,
            userSurname: String,
            userRole: String,
            companyId: Number,
            companyName: String,
            subscriptionType: String,
        },

        setup(props, { emit }) {
            const toast = useToast()

            const route = useRoute()
            const router = useRouter()

            const userStore = useUserStore()
            const flowStore = useFlowStore()
            const companyStore = useCompanyStore()
            const cameraStreamStore = useCameraStreamStore()
            const detectionZoneStore = useDetectionZoneStore()

            const showFlowList = ref(false)
            const toggleFlowList = () => {
                showFlowList.value = !showFlowList.value;

            }

            const { serverConnection } = useGlobalState()
            const checkConnectionInterval = ref(null)
            const showedSuccessToast = ref(false)

            // State for sidebar visibility and screen size
            const sidebarOpen = ref(false)
            const isSmallScreen = ref(window.innerWidth <= 768)
            const toggleSidebar = () => {
                sidebarOpen.value = !sidebarOpen.value
            }

            const { userName, userSurname, userRole, companyName, subscriptionType } = toRefs(props)

            // Computed property to determine if the current route is the registration route
            const isRegistrationRoute = computed(() => route.path === '/registration' || route.path === '/login')

            const isLoggedIn = computed(() => {
                return userStore.isUserLoggedIn
            })

            let previousPath = ref(route.path)

            // Add resize event listener when component is mounted
            onMounted( async () => {
                fetchUserDetailsIfNeeded()
                if (isLoggedIn.value) {
                    userStore.fetchUserDetails()
                    await flowStore.fetchFlows()
                    await checkConnectionStatus()
                    startConnectionCheckInterval()
                }
                window.addEventListener('resize', handleResize)
                handleResize() // Set initial state based on current window size
            })

            
            watch(() => route.path, async (newPath) => {
                //Route changed, fetching user details if needed
                fetchUserDetailsIfNeeded()

                if (previousPath.value === '/login' && isLoggedIn.value) {
                    // If the user navigates from the login page and is logged in, check connection
                    await checkConnectionStatus()
                    startConnectionCheckInterval()
                }
                    // Update the previous path after each route change
                previousPath.value = newPath
            })

            const checkConnectionStatus = async () => {
                try {
                    await companyStore.fetchCompanyDetails(userStore.user.CompanyId)
                    const urlEDGEDEVICE = companyStore.company.SocketioURLPublic

                    const response = await axios.get(`${urlEDGEDEVICE}/check_connection`)
                    if (response.status === 200) {
                        serverConnection.value = true
                        if (!showedSuccessToast.value) {
                            toast.success('Connected with Local Device')
                            showedSuccessToast.value = true
                        }
                    } else {
                        throw new Error('Connection failed')
                    }
                } catch (error) {
                    console.error('Connection check failed:', error)
                    if (serverConnection.value) {
                        toast.error('Connection Lost with Local Device')
                        showedSuccessToast.value = false
                    }
                    serverConnection.value = false
                }
            }

            const startConnectionCheckInterval = () => {
                if (checkConnectionInterval.value) {
                    clearInterval(checkConnectionInterval.value)
                }
                checkConnectionInterval.value = setInterval(checkConnectionStatus, 60000)
            }

            const fetchUserDetailsIfNeeded = async () => {
                const isAuthRoute = route.path === '/registration' || route.path === '/login'
                const isTokenPresent = !!localStorage.getItem('authToken')

                if (!isAuthRoute && isTokenPresent && !userStore.user.UserName) {
                    await userStore.fetchUserDetails()

                    if (!userStore.user.UserName) {
                    // User details fetch failed, redirect to login
                    toast.error('Credentials Invalid or Expired')
                    router.push('/login')
                    }
                }
            }

            const logout = () => {
                localStorage.removeItem('FixedWidgetsData')
                detectionZoneStore.clearDetectionZones()
                cameraStreamStore.clearStreams()
                flowStore.clearFlows()
                userStore.clearUserState()
                companyStore.clearCompanyState()

                localStorage.removeItem('authToken')

                // Check if the token is removed
                if (!localStorage.getItem('authToken')) {
                    console.log("Token successfully removed.")
                } else {
                    console.error("Failed to remove the token.")
                }
                
                router.push('/login').then(() => {
                    toast.info('Successfully logged out.')
                })
            }

            // Handling screen resize
            const handleResize = () => {
                isSmallScreen.value = window.innerWidth <= 768
                if (!isSmallScreen.value) {
                    sidebarOpen.value = true // Keep sidebar open on large screens
                } else {
                    sidebarOpen.value = false // Hide sidebar initially on small screens
                }
            }
  
            onBeforeUnmount(() => {
                if (checkConnectionInterval.value) {
                    clearInterval(checkConnectionInterval.value)
                }
                // Remove the resize event listener when component is unmounted
                window.removeEventListener('resize', handleResize)
            })

            return {
                page: 'home',
                logo_storalytic: image,
                isRegistrationRoute,
                serverConnection,
                isLoggedIn,
                logout,
                userName,
                userSurname,
                userRole,
                companyName,
                subscriptionType,
                flowStore,
                toggleFlowList,
                showFlowList,
                sidebarOpen,
                isSmallScreen,
                toggleSidebar,
            }
        }
    }
</script>
  
<style scoped>
/* Sidebar container */
.sidebar {
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    width: 250px;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1100;
    padding: 10px;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease; /* Smooth slide-in transition */
}

.sidebar.small-screen {
    height: 90vh; /* Only 90% height on small screens */
    transform: translateX(-100%); /* Initially hidden off-screen */
}

.sidebar.open.small-screen {
    transform: translateX(0); /* Slide in on small screens */
}

/* Sidebar content styling */
.sidebar-content {
    padding: 10px;
}

/* Header company info */
.company-info {
    font-size: 0.9rem;
    color: #425461;
    font-weight: bold;
    padding-left: 10px;
}

.main-links {
    padding-left: 10px;
}

.subscription-type {
    color: #9FC8E3;
}

/* Divider lines */
.thin-divider {
    margin: 5px 0;
    border: none;
    border-top: 1px solid #B2C149;
}

/* Sidebar links */
.nav-link {
    color: #425461;
    padding: 8px 0;
    text-decoration: none;
    font-size: 0.85rem;
    display: block;
}

.nav-link:hover {
    color: #b2c149;
}

.active-link {
    font-style: italic;
    color: #b2c149 !important;
    cursor: default;
}

/* Flow list styling */
.flow-list {
    padding-left: 20px;
    font-size: 0.8rem;
}

.flow-link {
    color: #425461;
    padding: 3px 0;
    text-decoration: none;
}

.flow-link:hover {
    color: #b2c149;
}

/* Footer with logo and settings */
.sidebar-footer {
    margin-top: auto;
    padding-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: stretch; /* Ensure children expand to full width */
    padding-left: 10px;
    font-size: 0.72rem;
    color: #425461;
}

.sidebar-logo img {
    width: 100px;
    height: auto;
    margin-bottom: 10px;
}

/* Connection status styling */
.connection-status {
    color: #425461;
    padding-left: 0px; /* Adjust padding for alignment */
}

.status-indicator {
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
}

.status-running {
    background-color: #6C9046;
}

.status-stopped {
    background-color: #C21500;
}

/* Settings and user info */
.settings-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* Align items to the left */
    padding-left: 0px; /* Add padding to match alignment */
}

.user-info {
    margin: 5px 0;
    color: #425461;
    display: inline; /* Ensure inline display */
    font-size: 0.76rem;
}

.logout-link {
    color: #9FC8E3;
    cursor: pointer;
    text-decoration: none;
    display: inline; /* Ensure inline display */
    padding-left: 5px; /* Optional: add space after the colon */
}

.logout-link:hover {
    text-decoration: underline;
}

/* Styles for the expandable header */
.expandable-header {
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 0.85rem;
    color: #425461;
    padding: 8px 10px;
}

/* Triangle icon with rotation */
.triangle-icon {
    display: inline-block;
    width: 0;
    height: 0;
    margin-right: 8px;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #425461;
    transform: rotate(-90deg);
    transition: transform 0.2s ease;
}

.triangle-icon.expanded {
    transform: rotate(0deg); /* Rotates to point down when expanded */
}

/* Flow list */
.flow-list {
    padding-left: 20px;
    font-size: 0.8rem;
}

.flow-link {
    color: #425461;
    padding: 3px 0;
    text-decoration: none;
}

.flow-link:hover {
    color: #b2c149;
}

/* Hamburger menu for small screens */
.hamburger {
    font-size: 24px;
    color: #425461;
    cursor: pointer;
    position: fixed;
    top: 10px;
    left: 10px;
    z-index: 1100; /* Above sidebar */
}

/* Close button for small screens */
.close-btn {
    font-size: 24px;
    color: #425461;
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 20px;
}


/* Adjustments for small screens */
@media (max-width: 768px) {
    /* Sidebar should be hidden initially */
    .sidebar {
        width: 60vw;
    }
}


</style>