<template>
    <div class="chart-container">
        <!-- First chart for total duration per display zone -->
        <div class="chart-wrapper">
            <div v-if="isChartEmpty(chartDataTotalDurationPerDisplayZone) || !displayZonePresent" class="no-data-message">
                No chart data available
            </div>
            <div v-else-if="isLoading" class="loader-overlay">
                <div class="loader"></div>
            </div>
            <BarChart v-else class="chart" :chartData="chartDataTotalDurationPerDisplayZone" :options="chartOptionsTotalDurations" />
        </div>
        
        <!-- Second chart for percentage by duration range in display zone -->
        <div class="chart-wrapper">
            <div v-if="isChartEmpty(chartDataPercentageByDuration) || !displayZonePresent" class="no-data-message">
                No chart data available
            </div>
            <div v-else-if="isLoading" class="loader-overlay">
                <div class="loader"></div>
            </div>
            <BarChart v-else ref="chartRef" class="chart" :chartData="chartDataPercentageByDuration" :options="chartOptionsPercentageByDuration" />
        </div>
    </div>
</template>

<script>
import { ref, onMounted, onUnmounted, watch } from 'vue'

import { BarChart } from 'vue-chart-3'
import { Chart, BarController, BarElement, CategoryScale, LinearScale, Title, Legend, Tooltip } from 'chart.js';

Chart.register(BarController, BarElement, CategoryScale, LinearScale, Title, Legend, Tooltip);

export default {
    name: 'ChartsFixedWidgetsDisplay',

    components: {
        BarChart,
    },
    
    props: {
        totalDurationPerDisplayZone: {
            type: Object,
            required: true,

        },

        percentageByDurationRangeInDisplayZone: {
            type: Object,
            required: true,

        },

        displayZonePresent: {
            type: Boolean,
            required: true,
        }

    },
    setup(props) {
        const chartDataTotalDurationPerDisplayZone = ref({
            labels: [],
            datasets: []
        })

        const chartDataPercentageByDuration = ref({
            labels: [],
            datasets: []
        })

        // Define the color palette
        const colorPalette = [
            '#425461', // Dark Blue
            '#4F7EB3', // Blue
            '#B2C149', // Green
            '#9FC8E3', // Light Blue
            '#6C9046', // Dark Green
            '#6B92C6', // Secondary Blue
        ]

        const isLoading = ref(false)

        const chartRef = ref(null); // Reference to the chart instance

        // `windowWidth` stores the current window width
        const windowWidth = ref(window.innerWidth);

        // Function to update windowWidth on resize
        const handleResize = () => {
            windowWidth.value = window.innerWidth;
        }

        const isChartEmpty = (chartData) => {
            return (
                !chartData || 
                !chartData.labels?.length || 
                !chartData.datasets?.some(dataset => Array.isArray(dataset.data) && dataset.data.some(value => value > 0))
            )
        }

        // Watcher on windowWidth to update legend position based on width
        watch(windowWidth, (newWidth) => {
            const chartInstance = chartRef.value?.chartInstance;
            if (chartInstance) {
                chartInstance.options.plugins.legend.position = newWidth < 1400 ? 'bottom' : 'right';
                chartInstance.update(); // Apply changes to chart
            }
        });


        onMounted(() => {
            isLoading.value = true
            createTotalDurationPerDisplayZone(props.totalDurationPerDisplayZone)
            createPercentageByDurationChart(props.percentageByDurationRangeInDisplayZone)
            handleResize(); // Set initial legend position
            window.addEventListener('resize', handleResize);

        })

        onUnmounted(() => {
            window.removeEventListener('resize', handleResize);
        });

        const createTotalDurationPerDisplayZone = async (totalDurations) => {
            if (!totalDurations || Object.keys(totalDurations).length === 0) {
                // If no data, set an empty dataset or handle accordingly
                chartDataTotalDurationPerDisplayZone.value = {
                //labels: [],
                datasets: [{
                    label: 'Total Duration',
                    data: [0],
                    backgroundColor: ['#ccc']
                }]
                }
                return
            }

            const labels = Object.keys(totalDurations)
            
            // Prepare datasets using the total duration data
            const datasets = [
                {
                    //label: 'Total Duration',
                    data: labels.map(zone => totalDurations[zone] || 0),
                    backgroundColor: labels.map(zone => getColorForZone(zone)) // Consistent color for each zone
                }
            ]

            // Set up chart data
            chartDataTotalDurationPerDisplayZone.value = {
                labels: labels,
                datasets: datasets
            }

            isLoading.value = false
        }

        const createPercentageByDurationChart = (percentagesData) => {
            // Define the fixed order for the duration ranges
            const fixedDurationOrder = [
                "0-5 sec",
                "6-15 sec",
                "16-60 sec",
                "1-2 min",
                "2-5 min",
                "5+ min"
            ]

            const labels = Object.keys(percentagesData || {})

            // Generate datasets in the specified order
            const datasets = fixedDurationOrder.map((range, i) => ({
                label: range,
                data: labels.map(zone => Math.round(percentagesData[zone][range] || 0)),
                backgroundColor: colorPalette[i % colorPalette.length],
            }));

            chartDataPercentageByDuration.value = {
                labels,
                datasets
            };
        };

        // Watch for prop changes to update charts
        watch(() => props.totalDurationPerDisplayZone, newVal => createTotalDurationPerDisplayZone(newVal));
        watch(() => props.percentageByDurationRangeInDisplayZone, newVal => createPercentageByDurationChart(newVal))

        // Function to pick a consistent color based on the zone's name
        const getColorForZone = (zone) => {
            const index = Array.from(zone).reduce((acc, char) => acc + char.charCodeAt(0), 0) % colorPalette.length;
            return colorPalette[index];
        }

        // Formatting function for seconds to "hours, minutes, seconds" format
        const formatDurationForTooltip = (durationInSeconds) => {
            if (durationInSeconds < 60) {
                return `${durationInSeconds.toFixed(0)} sec`
            } else if (durationInSeconds < 3600) {
                const minutes = Math.floor(durationInSeconds / 60)
                const remainingSeconds = durationInSeconds % 60
                return `${minutes} min ${remainingSeconds.toFixed(0)} sec`
            } else {
                const hours = Math.floor(durationInSeconds / 3600)
                const remainingMinutes = Math.floor((durationInSeconds % 3600) / 60)
                const remainingSeconds = durationInSeconds % 60
                return `${hours} h ${remainingMinutes} min ${remainingSeconds.toFixed(0)} sec`
            }
        }

        const formatDurationForAxis = (durationInSeconds) => {
            if (durationInSeconds < 60) {
                return `${durationInSeconds.toFixed(0)} sec`;
            } else if (durationInSeconds < 3600) {
                const minutes = Math.floor(durationInSeconds / 60)
                return `${minutes} min`
            } else {
                const hours = Math.floor(durationInSeconds / 3600)
                return `${hours} h`
            }
        }

        const chartOptionsTotalDurations = {
            responsive: true,  // Makes the chart responsive
            maintainAspectRatio: false,  // Control the aspect ratio
            scales: {
                y: {
                    beginAtZero: true,  // Start the Y axis at 0
                    title: {
                        display: true,
                        text: 'Total Duration'
                    },
                    ticks: {
                        callback: function(value) {
                            return formatDurationForAxis(value) // Format y-axis labels
                        }
                    }
                },
                x: {
                    title: {
                        display: true,
                        text: 'Display Zones'
                    }
                }
            },
            plugins: {
                legend: {
                    display: false,
                    position: 'top',  // Position of the legend
                },
                title: {
                    display: true,
                    text: 'Total Time Spent in Display Zone',
                },
                tooltip: {
                    //enable: true
                    callbacks: {
                        label: function(context) {
                            const value = context.raw; // Access the raw data value
                            return formatDurationForTooltip(value); // Format tooltip values
                        }
                    }
                },
            }
        }

        // Chart options for percentage by duration range
        const chartOptionsPercentageByDuration = {
            responsive: true,
            maintainAspectRatio: false,
            scales: {
                y: {
                    beginAtZero: true,
                    max: 100,
                    stacked: true,
                    title: { display: true, text: 'Percentage (%)' },
                    ticks: {
                        callback: function(value) {
                            return `${value}%`;
                        }
                    }
                },
                x: {
                    stacked: true,
                    title: { display: true, text: 'Display Zones' }
                }
            },
            plugins: {
                legend: { display: true, position: 'right' },
                title: { display: true, text: 'Distribution of Total Time Spent per Display Zone' },
                tooltip: {
                    callbacks: {
                        label: function(context) {
                            const value = context.raw;
                            return `${context.dataset.label}: ${value}%`;
                        }
                    }
                },
            }
        };


        return {
            chartDataTotalDurationPerDisplayZone,
            chartDataPercentageByDuration,
            chartOptionsTotalDurations,
            chartOptionsPercentageByDuration,
            isLoading,
            isChartEmpty,
            chartRef,
        }

    }
};
</script>

<style scoped>
.chart-container {
    display: flex;
    flex-wrap: wrap; /* Allows wrapping when space is limited */
    gap: 1rem;
    width: 100%;
}

.chart {
    height: 300px; /* Adjust as needed */
}

.chart-wrapper {
    position: relative; /* For positioning loader overlay */
    flex: 1 1 48%;
    height: 300px;
}


.loader-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.8); /* Light transparent background */
    z-index: 1;
}

.no-data-message {
    color: gray;
    font-size: 14px; /* Adjust font size to match the design */
    font-style: italic; /* Optional for emphasizing no data */
    text-align: center; /* Center the message */
    margin-top: 20px; /* Add some spacing from the top */
    margin-bottom: 20px; /* Add some spacing from the bottom */
    line-height: 1.5; /* Improve readability with line height */
}

.loader {
  width: 80px;
  padding: 8px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: #B2C149;
  --_m: 
    conic-gradient(#0000 10%,#000),
    linear-gradient(#000 0 0) content-box;
  -webkit-mask: var(--_m);
          mask: var(--_m);
  -webkit-mask-composite: source-out;
          mask-composite: subtract;
  animation: spinner 1s infinite linear;
}
@keyframes spinner {to{transform: rotate(1turn)}}

/* Responsive stacking below 1400px */
@media (max-width: 1400px) {
    .chart {
        flex: 1 1 100%; /* Take full width when wrapping */
        width: 100%;
    }
}
</style>