<template>
  <div>

      <div>
          <label class="form-label card-text" style="margin-top: 20px; margin-bottom: 20px; color: #425461"><b>Detection Zones:</b></label>
      </div>
      
      <p style="font-size: small; color: #C21500;"><u>Important</u><br>
      Make sure to mark the detection zone on the floor of the store.<br>
      Persons are detected from the moment their feet enter the detection zone.<br>
      If the detection zone cannot be added on the floor, check if the field of view of the camera can be changed.</p>

  </div> 

  <div class="frame-container" v-if="capturedFrame">

      <label style="padding-bottom: 10px; color: #425461" class="form-label">Add detection zones by clicking the bounding points in the image which will appear as red dots:</label>

      <div class="frame-content" @click="addZonePoint">
        <div v-if="isLoading">
          <div class="loader"></div>
        </div>
        <img v-else :src="capturedFrame" alt="Captured Frame" ref="imageRef" @load="adjustCanvasSize"/>
        <!-- Canvas that renders the polygons n top of the image -->
        <canvas id="zoneCanvas" width="800" height="600" style="position: absolute; top: 0; left: 0; pointer-events: none;"></canvas>
        <!-- Render dots on the image -->
        <div v-for="(dot, index) in detectionPointsRef" :key="index" class="dot" :id="'dot_' + index" :style="{top: dot.y + 'px', left: dot.x + 'px'}"></div>
      </div>

      <div class="zone-grid">
        <div class="zone-row header">
          <span>Type</span>
          <span>Name</span>
          <span>Actions</span>
        </div>

        <div v-for="(zone, index) in detectionZones" :key="index" class="zone-row">
          <div>
            <template v-if="zone.confirmed">
              <span :style="{ color: detectionZoneColorsSolid[zone.type] || 'black' }"><b>{{ zone.type }}</b></span>
            </template>
            <template v-else>
              <DropdownDetectionZoneType v-model="zone.type" @detectionzonetype="updateZoneType(index, $event)" />
            </template>
          </div>
          <div>
            <template v-if="zone.confirmed">
              <span :style="{ color: detectionZoneColorsSolid[zone.type] || 'black' }">{{ zone.name }}</span>
            </template>
            <template v-else>
              <input type="text" v-model="zone.name" placeholder="Detection Zone Name" />
            </template>
          </div>
          <div class="actions">
            <i class="bi bi-check-lg" @click="confirmPoints(index)" :class="{ disabled: zone.confirmed || !zone.type || !zone.name }"></i>
            <i class="bi bi-eraser-fill" @click="clearPoints(index)" :class="{ disabled: zone.confirmed || !zone.points.length }"></i>
            <i class="bi bi-trash-fill" @click="deleteZone(index)" :class="{ disabled: isCameraRunning}"></i>
          </div>
        </div>

        <div v-if="!zonePointsConfirmed && !isCameraRunning" class="zone-row">
          <DropdownDetectionZoneType :key="newZone.type" @detectionzonetype="on_detectionzonetype" v-model="newZone.type" />
          <input type="text" class="form-control" v-model="newZone.name" placeholder="Zone Name" />
          <div class="actions">
            <i class="bi bi-check-lg" @click="confirmPoints" :class="{ disabled: !newZone.type || !newZone.name || !detectionPointsRef.length }"></i>
            <i class="bi bi-eraser-fill" @click="clearPoints" :class="{ disabled: !detectionPointsRef.length }"></i>
          </div>
        </div>
      </div>

      <button style="margin-top: 30px;" type="button" class="btn btn-primary" @click="newPicture" :disabled="detectionZones.length > 0">Take New Picture</button>
  
  </div>
  
</template>

<script>
import axios from 'axios'

import { ref, watch } from 'vue'

import { useToast } from 'vue-toastification'

import { detection_zone_colors_by_name, detection_zone_colors_by_name_solid } from '@/constants.js'

import { useCompanyStore } from '@/stores/CompanyStore'

import DropdownDetectionZoneType from './DropdownDetectionZoneType.vue'

export default {
    name: 'DetectionZone',

    components: {
      DropdownDetectionZoneType,
    },

    emits: ["detectionzones"],

    props: {
      stream_link: String,
      captured_frame: String,
      detection_zone: Object,
      is_camera_running: Boolean
    },

    setup(props, { emit }) {
      const toast = useToast()

      const companyStore = useCompanyStore()
      
      const capturedFrame = ref(props.captured_frame)
      const isCameraRunning = ref(props.is_camera_running)
      
      const detectionPointsRef = ref([])
      const detectionPoints = ref([])
      const detectionZones = ref([])
      const detectionZoneType = ref('')
      const detectionZoneName = ref('')
      
      const newZone = ref({ type: '', name: '' })
      
      const streamlink = ref(props.stream_link)
      
      const imageRef = ref(null)
      
      const addDetectionZoneClicked = ref(false)
      const zonePointsConfirmed = ref(false)

      const isLoading = ref(false)

      const adjustCanvasSize = () => {
        const canvas = document.getElementById('zoneCanvas')
        const img = imageRef.value
        if (img) {
          canvas.width = img.clientWidth
          canvas.height = img.clientHeight
          redrawAllZones()
        }
      }

      watch(() => props.captured_frame, (newFrame) => {
        capturedFrame.value = newFrame
        adjustCanvasSize()
      })

      watch(() => props.detection_zone, (newZones) => {
        if (newZones) {
          detectionZones.value = Object.entries(newZones).map(([name, { type, points }]) => ({
            name,
            type,
            points: points.map(([x, y]) => ({ x, y })),
            confirmed: true
          }))
          adjustCanvasSize()
        }
      }, { immediate: true })

      watch(() => props.stream_link, (newStreamLink) => {
        streamlink.value = newStreamLink
        console.log('Updated streamlink:', streamlink.value)
      }, { immediate: true })

      watch(() => props.is_camera_running, (newStatus) => {
        isCameraRunning.value = newStatus
        adjustCanvasSize()
      })


      const on_detectionzonetype = (value) => {
        detectionZoneType.value = value
      }


      const emitDetectionPointsData = () => {  
        const formattedZones = detectionZones.value.reduce((acc, zone) => {
          acc[zone.name] = {
            type: zone.type,
            points: zone.points.map(point => [point.x, point.y])
          }
          return acc
        }, {})
        emit('detectionzones', formattedZones)
      }


      const addZonePoint = (event) => {
        if (event.target === imageRef.value) {
        const rect = imageRef.value.getBoundingClientRect()

        //Calculate the clicked pixel position relative to the image
        const x = event.clientX - rect.left
        const y = event.clientY - rect.top

        detectionPointsRef.value.push({x, y})
        detectionPoints.value.push([parseInt(Math.round(x)), parseInt(Math.round(y))])
        }
      }

      const confirmPoints = (index = undefined) => {
        if (detectionPoints.value.length < 3) {
            toast.info("Provide at least 3 points to create a fully enclosed zone")
          }
          else {
            addZone(index)
            zonePointsConfirmed.value = false
            
            // Reset newZone type and name
            newZone.value.type = ''
            newZone.value.name = ''
          }
      }

      const addZone = (index = undefined) => {
        if (index !== undefined && index < detectionZones.value.length) {
          detectionZones.value[index].confirmed = true
          detectionZones.value[index].points = [...detectionPointsRef.value]
        } else {
          detectionZones.value.push({ ...newZone.value, points: [...detectionPointsRef.value], confirmed: true })
          newZone.value = { type: '', name: '' }
          detectionZoneType.value = ''
        }

        zonePointsConfirmed.value = false
        
        const points = detectionPointsRef.value
        const color = index !== undefined && index < detectionZones.value.length
          ? detection_zone_colors_by_name[detectionZones.value[index].type] || 'rgba(0, 0, 0, 0.5)'
          : detection_zone_colors_by_name[newZone.value.type] || 'rgba(0, 0, 0, 0.5)'
        
        clearPoints()
        emitDetectionPointsData()
        
        redrawAllZones()
      }

      const drawPoly = (points, color) => {
        // Get the canvas and its context
        const canvas = document.getElementById('zoneCanvas')
        const ctx = canvas.getContext('2d')

        // Set the polygon color
        ctx.fillStyle = color
        ctx.strokeStyle = color.replace('0.5', '1') // Replace transparency with full opacity for stroke;
        ctx.lineWidth = 2

        // Begin drawing the polygon
        ctx.beginPath()
        ctx.moveTo(points[0].x, points[0].y)
        points.forEach(point => {
          ctx.lineTo(point.x, point.y)
        })
        ctx.closePath()
        ctx.fill()
        ctx.stroke()
      }

      const clearPoints = (index) => {
        if (index !== undefined && index < detectionZones.value.length) {
          detectionZones.value[index].points = []
        } else {
          detectionPointsRef.value = []
          detectionPoints.value = []

          // Remove the red dot elements from the DOM
          const dotElements = document.querySelectorAll('.dot')
          dotElements.forEach((dotElement) => {
            dotElement.remove()
          })
          const canvas = document.getElementById('zoneCanvas');
          const ctx = canvas.getContext('2d');
          ctx.clearRect(0, 0, canvas.width, canvas.height);
          
          redrawAllZones()
        }
      }

      const redrawAllZones = () => {
        const canvas = document.getElementById('zoneCanvas')
        const ctx = canvas.getContext('2d')
        ctx.clearRect(0, 0, canvas.width, canvas.height)
        
        detectionZones.value.forEach(zone => {
          drawPoly(zone.points, detection_zone_colors_by_name[zone.type] || 'rgba(0, 0, 0, 0.5)')
        })
      }

      const deleteZone = (index) => {
        if (confirm("Are you sure you want to delete this detection zone?")) {
          detectionZones.value.splice(index, 1)
          emitDetectionPointsData()
          
          redrawAllZones()
        }
      }

      const newPicture = async () => {
        isLoading.value = true
        try {
          const urlEDGEDEVICE = companyStore.company.SocketioURLPublic

          const response = await axios.get(`${urlEDGEDEVICE}/detection_zone_frame`, {
            params: { cameraStreamLink: streamlink.value },
            responseType: 'blob'
          })
          const blob = new Blob([response.data], { type: 'image/jpeg' })
          const urlCreator = window.URL || window.webkitURL
          const imageUrl = urlCreator.createObjectURL(blob)
          capturedFrame.value = imageUrl
          addDetectionZoneClicked.value = true
          toast.success('New frame captured successfully')
        } catch (error) {
          console.error('Frame capture failed:', error)
          toast.error(`Failed to capture new frame: ${error.message}`)
        } finally {
          isLoading.value = false
        }
      }

      const onCancel = () => {
        console.log('User cancelled the loader.')
      }

      return {
        capturedFrame,
        isCameraRunning,
        on_detectionzonetype,
        detectionZoneType,
        detectionZoneName,
        addDetectionZoneClicked,
        newZone,
        imageRef,
        detectionPointsRef,
        addZonePoint,
        confirmPoints,
        clearPoints,
        newPicture,
        zonePointsConfirmed,
        detectionZones,
        isLoading,
        onCancel,
        deleteZone,
        drawPoly,
        adjustCanvasSize,
        detectionZoneColorsSolid: detection_zone_colors_by_name_solid,
    }
  }
}

</script>

<style scoped>

.actions {
  display: flex;
  gap: 10px;
  align-items: center;
}

.actions i {
  font-size: 1.5em;
  cursor: pointer;
  color: #B2C149;
}

.actions i.disabled {
  color: grey;
  cursor: not-allowed;
}

.zone-grid {
  display: grid;
  grid-template-columns: 2fr 1.5fr 1.5fr;
  gap: 10px;
  margin-top: 20px;
}

.zone-row {
  display: contents;
  align-items: center;
  border-bottom: 1px solid lightgrey;
  padding: 10px 0;
}

.zone-row > div {
  padding-right: 10px; /* Add padding between columns */
}

.zone-row > div > * {
  margin-right: 10px; /* Add margin to child elements */
}

.header {
  font-weight: bold;
  border-bottom: 2px solid #ccc;
  padding-bottom: 10px;
  color: #B2C149;
}

.header span {
  padding-bottom: 10px;
}

.zone-row:last-child {
  border-bottom: none; /* Remove the border for the last row */
}

.btn {
  background-color: #4F7EB3;
  border: none;
}

.btn:hover {
  background-color: #B2C149;
}

.frame-container {
  max-width: 100%;
  overflow: hidden;
  padding-top: 10px;
}

.frame-content {
  max-width: 100%;
  max-height: 1000px;
  overflow: auto; /* Enable both horizontal and vertical scrolling */
  position: relative;
}

.frame-content img {
  width: auto; /* Display the image at its original size */
  height: auto; /* Display the image at its original size */
  max-width: none; /* Allow the image to exceed the container's width */
  max-height: none; /* Allow the image to exceed the container's height */
}

.dot {
  position: absolute;
  width: 5px;
  height: 5px;
  background-color: red;
  border-radius: 50%;
  transform: translate(-50%, -50%); /* Center the dot */
}

.loader {
  width: 80px;
  padding: 8px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: #B2C149;
  --_m: 
    conic-gradient(#0000 10%,#000),
    linear-gradient(#000 0 0) content-box;
  -webkit-mask: var(--_m);
          mask: var(--_m);
  -webkit-mask-composite: source-out;
          mask-composite: subtract;
  animation: spinner 1s infinite linear;
}
@keyframes spinner {to{transform: rotate(1turn)}}

</style>